import React from "react";

import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";
import Person from "../../components/Person/Person";

import team from "../../data/colleaguesData";
import { useParams } from "react-router";

const Colleagues = (props) => {
  const { place } = useParams(); 
  const filteredTeam = team.filter((off)=> off.place === place)
  
  return (
    <div>
      <PageContentHeader>Munkatársak</PageContentHeader>
      {filteredTeam.map(person => <Person key={person.id} person={person}></Person>)}

    </div>
  );
};
export default Colleagues;
