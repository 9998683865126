import React from "react";
import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";
import ContactShop from "../../components/ContactShop/ContactShop";
import classes from "./Contact.module.css";

import offices from '../../data/officeData';

const contact = () => (
  <div>
    <PageContentHeader>Elérhetőségek</PageContentHeader>
    <span>
      Az alábbi elérhetőségeken érsz el bennünket. Fordulj hozzánk bizalommal!
    </span>

    <div className={classes.Contact}>
      {offices.map( office => <ContactShop shop={office} key={office.id}></ContactShop>)}

    </div>
  </div>
);

export default contact;
