import React from "react";
import ImgGallery from "../../components/ImgGallery/ImgGallery";
import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";

const importAll = (context) => {
  return context.keys().reduce((images, item) => {
    const imageName = item.replace('./', '');
    images[imageName] = context(item).default;
    return images;
  }, {});
};

const importAndMapImages = (context) => {
  const images = importAll(context);
  return Object.entries(images).map(([imageName, imageData]) => ({
    original: imageData,
    thumbnail: imageData
  }));
};

const imageContext = require.context('../../assets/images/gallery', false, /\.(png|jpe?g|svg)$/);
const lightsContext = require.context('../../assets/images/gallery/lights', false, /\.(png|jpe?g|svg)$/);
const warehouseContext = require.context('../../assets/images/gallery/warehouse', false, /\.(png|jpe?g|svg)$/);

const imageList = importAndMapImages(imageContext);
const lightList = importAndMapImages(lightsContext);
const warehouseList = importAndMapImages(warehouseContext);



/* const imagesXX = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
]; */



const gallery = () => (
  <div>
    <PageContentHeader>Galéria</PageContentHeader>
    <ImgGallery images={imageList}></ImgGallery>
    <ImgGallery images={lightList}></ImgGallery>
    <ImgGallery images={warehouseList}></ImgGallery>
  </div>
);

export default gallery;
