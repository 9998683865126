import avatar from '../assets/images/colleagues/avatar2.png';

const team = [    
    { 
        id: 1,
        name: 'Biró György',
        role: 'Ügyvezető',
        img: avatar,
        phone: '',
        email: 'birogyorgy@elektroamper.hu',
        place: 'downtown'
    },
    { 
        id: 2,
        name: 'Biró György',
        role: 'Ügyvezető',
        img: avatar,
        phone: '',
        email: 'birogyorgy@elektroamper.hu',
        place: 'uptown'
    },
    { 
        id: 4,
        name: 'Csányi János',
        role: 'Értékesítő',
        img: avatar,
        phone: '+36 68 412 202',
        email: 'info@elektroamper.hu',
        place: 'downtown'
    },
    { 
        id: 5,
        name: 'Borbély Gergely',
        role: 'Értékesítő',
        img: avatar,
        phone: '+36 68 412 202',
        email: 'info@elektroamper.hu',
        place: 'downtown'
    },
    { 
        id: 6,
        name: 'Biróné Váczi Piroska',
        role: 'Pénzügyi vezető',
        img: avatar,
        phone: '+36 20 311 0875',
        email: 'piroska.vaczi@elektroamper.hu',
        place: 'downtown'
    },    
    { 
        id: 7,
        name: 'Szabó Klaudia',
        role: 'Titkárság/Könyvelés',
        img: avatar,
        phone: '+36 30 018 2677',
        email: 'klaudia.szabo@elektroamper.hu',
        place: 'downtown'
    },
    { 
        id: 8,
        name: 'Pető Attila',
        role: 'Műszaki értékesítő',
        img: avatar,
        phone: '+36 30 138 4928',
        email: 'attila.peto@elektroamper.hu',
        place: 'uptown'
    },
    {
        id: 3,
        name: 'Ujházi-Szabó Diána',
        role: 'Műszaki értékesítő',
        img: avatar,
        email: 'diana.szabo@elektroamper.hu',
        place: 'uptown'
    },
    { 
        id: 10,
        name: 'Raktár',
        role: '',
        img: avatar,
        phone: '+36 30 071 0525',
        email: 'raktar@elektroamper.hu',
        place: 'uptown'
    },   
    {
        id: 11, 
        name: 'Biróné Váczi Piroska',
        role: 'Pénzügyi vezető',
        img: avatar,
        phone: '+36 20 311 0875',
        email: 'piroska.vaczi@elektroamper.hu',
        place: 'uptown'
    },    
    { 
        id: 12,
        name: 'Szabó Klaudia',
        role: 'Titkárság/Könyvelés',
        img: avatar,
        phone: '+36 30 018 2677',
        email: 'klaudia.szabo@elektroamper.hu',
        place: 'uptown'
    }
];

export default team;