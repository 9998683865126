import React from "react";
import classes from "./PageFooter.module.css";

import FooterItem from "./FooterItem/FooterItem";

const pageFooter = () => (
  <footer className={classes.PageFooter}>
    <FooterItem link="">©2021 Minden jog fenntartva</FooterItem>
    <FooterItem link="/datausage">Adatkezelési Tájékoztató</FooterItem>
  </footer>
);

export default pageFooter;
