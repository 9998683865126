import React from "react";

import classes from "./AimCards.module.css";
import AimCard from "./AimCard/AimCard";

import AmperIcon1 from '../../assets/images/intro/AimCards/Amper-1.svg';
import AmperIcon2 from '../../assets/images/intro/AimCards/Amper-2.svg';
import AmperIcon3 from '../../assets/images/intro/AimCards/Amper-3.svg';

const aimCards = () => (
    <div className={classes.AimCards}>
        <AimCard icon={AmperIcon1} year="CÉG TEVÉKENYSÉGE">
            <ul>
                <li>Épületvillamossági és ipari elektronikai termékek kereskedelme.</li>
                <li>Napelemes rendszerek forgalmazása, tanácsadás (tervezéstől a kivitelezésig).</li>
                <li>Villamosipari generálkivitelezés - tervezéstől a kivitelezésig.</li>
                <li>Hazai és nemzetközi gyártókkal direkt/indirekt partneri szerződésekkel rendelkezünk.</li>
            </ul>
        </AimCard>
        <AimCard icon={AmperIcon2} year="FORGALMAZOTT TERMÉKEK">
            <ul>
                <li>Az általunk forgalmazott termékek kizárólag minősített, bevizsgált hazai és nemzetközi gyártók termékeiből állnak.</li>
                <li>Üzletünkben közel 150 cég 20 000 termékét találhatod meg.</li>
                <li>Lámpákat, világítótesteket, csillárokat, LED paneleket, LED szalagokat több, mint 30 gyártó kínálatából tudnak elérni vásárlóink.</li>
                <li>Dobozok, védőcsövek, kábelcsatornák, kábelek, vezetékek azonnal elérhető nagy választékától, egészen a kapcsolók, dugaljak, világítótestek széles kínálatáig.</li>
            </ul>
        </AimCard>
        <AimCard icon={AmperIcon3} year="CÉLZOTT PIACOK">
            <ul>
                <li>Ipari és lakossági végfelhasználók, termelő üzemek.</li>
                <li>Villamos kivitelezők, villanyszerelők.</li>
                <li>Beruházók és generál kivitelezők.</li>
            </ul>
        </AimCard>
    </div>
);

export default aimCards;