import React from "react";
import classes from "./PageContent.module.css";
import SideBar from "../../components/SideBar/SideBar";

const pageContent = (props) => (
  <main className={classes.PageContent}>
    <div className={classes.Container}>{props.children}</div>
    <SideBar />
  </main>
);

export default pageContent;
