import React from "react";
import { Link } from "react-router-dom";

import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";

const notFound = () => (
  <div>
    <PageContentHeader>A keresett oldal nem létezik!</PageContentHeader>
    <Link to="/">Vissza a főoldalra!</Link>
  </div>
);

export default notFound;
