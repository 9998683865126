import React from "react";
import arrow from "../../../assets/images/intro/arrow.svg";
import arrowMobil from "../../../assets/images/intro/arrow-horizontal.svg";
import classes from "./IntroCard.module.css"

const introCard = (props) => (
  <div className={classes.IntroCard}>
    <img className={classes.Arrow} src={arrow} alt="arrow"/>
    <div className={classes.Cardcontainer}>
        <img className={classes.ArrowMobile} src={arrowMobil} alt="arrow"/>
        <img className={classes.Icon} src={props.icon} alt="intro-icon"/>
        <div className={classes.Year}>{props.year}</div>
        <div className={classes.Text}>{props.children}</div>
    </div>
  </div>
);

export default introCard;
