import React from "react";

import classes from "./AdvantageItem.module.css";

import { FaTruck } from "react-icons/fa";
import { MdHeadsetMic } from "react-icons/md";
import { MdShoppingCart } from "react-icons/md";
import { GiHumanPyramid } from "react-icons/gi";

const advantageItem = (props) => {
  let icon;

  switch (props.ico) {
    case "GiHumanPyramid":
      icon = <GiHumanPyramid />;
      break;
    case "MdHeadsetMic":
      icon = <MdHeadsetMic />;
      break;
    case "FaTruck":
      icon = <FaTruck />;
      break;
    case "MdShoppingCart":
      icon = <MdShoppingCart />;
      break;
    default:
      icon = <MdShoppingCart />;
      break;
  }

  return (
    <div className={classes.AdvantageItem}>
      {icon}
      <div className={classes.AdvantageItemHeader}>{props.header}</div>
      <div className={classes.AdvantageItemDesc}>{props.desc}</div>
    </div>
  );
};

export default advantageItem;
