import React from "react";

import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";
import ImgGallery from "../../components/ImgGallery/ImgGallery";
import News from "../../components/News/News";
import Advantages from "../../components/Advantages/Advantages";

import legrand from "../../assets/images/Legrand.jpg";

import classes from './Home.module.css';

//import first from '../../assets/images/akciok/sedna.png';
//import second from '../../assets/images/akciok/masodik.jpg';
import actual from '../../assets/images/akciok/amper_solar.jpg';
import schrack from '../../assets/images/akciok/schrack.jpg';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
  return images;
}

const imagess = importAll(require.context('../../assets/images/banner', false, /\.(png|jpe?g|svg)$/));
const imageList = [];

for (const property in imagess) {  
  imageList.push({original: imagess[property].default, thumbnail: imagess[property].default})
}

/* const images = [
  {
    original: "https://picsum.photos/id/1018/1200/400/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1200/400/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1200/400/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
]; */

const home = () => (
  <div>
    <ImgGallery mode="slider" images={imageList}></ImgGallery>

    <PageContentHeader>Aktuális</PageContentHeader>
      <div className={classes.Photos}>
{/*           <img className={classes.Photo} src={first} alt="raba"/>
          <img className={classes.Photo} src={second} alt="rabalux"/> */}
          <img className={classes.Photo} src={schrack} alt="schrack"/>
          <img className={classes.Photo} src={actual} alt="solarpanel"/>
      </div> 
    <PageContentHeader>Újdonságok</PageContentHeader>

    <News />
    <PageContentHeader>Miért minket válassz?</PageContentHeader>
    <Advantages />

    <img style={{ width: "100%" }} alt="" src={legrand}></img>
    {/*<span>
            Ismerd meg a Legrand legújabb okoskapcsolóját és a benne rejlő fantasztikus lehetőségeket!
        </span>
         <h2>A hét modellje</h2>
        <img style={{width: '100%'}} alt='' src='https://instagram.fbud5-1.fna.fbcdn.net/v/t51.2885-15/e35/p1080x1080/139244973_324849788746674_406122773537372516_n.jpg?_nc_ht=instagram.fbud5-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=LZUhHEV3efoAX8bf1pk&tp=1&oh=1c74982d5ced76bed33e5b5d7a1fa4ea&oe=6035B3E0'></img>
        <h2>Napi Suna</h2>
        <img style={{width: '100%'}} alt='' src='https://instagram.fbud5-1.fna.fbcdn.net/v/t51.2885-15/e35/p1080x1080/109038635_290510152056160_1957285937984153623_n.jpg?_nc_ht=instagram.fbud5-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=jXBF3qZ33OwAX8jXAb8&tp=1&oh=4945b204cb6b2cb9561041bb2c9159f1&oe=6034DAB5'></img>
        <h2>Feleségem</h2>
        <img style={{width: '100%'}} alt='' src='https://instagram.fbud5-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/138589887_751055605824916_2047388428334567246_n.jpg?_nc_ht=instagram.fbud5-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=M7ib5xOsFLsAX_OBG_i&tp=1&oh=2d72a0e781f9c71b7804a55643496734&oe=60348AA0'></img>
     */}
  </div>
);

export default home;
