import React from "react";
import classes from "./FooterItem.module.css";

import { Link } from "react-router-dom";

const footerItem = (props) => (
  <Link className={classes.FooterItem} to={props.link}>
    {props.children}
  </Link>
);

export default footerItem;
