import React from "react";

import classes from "./Menu.module.css";
import MenuItem from "./MenuItem/MenuItem";

const menu = () => (
  <ul className={classes.Menu}>
    <MenuItem link="/" exact>
      Főoldal
    </MenuItem>
    <MenuItem link="/intro">Bemutatkozás</MenuItem>
    <MenuItem link="/contact">Elérhetőségek</MenuItem>
    <MenuItem link="/getinquery">Kapcsolatfelvétel</MenuItem>
    <MenuItem link="/providers">Beszállítóink</MenuItem>
    <MenuItem link="/gallery">Galéria</MenuItem>
    <MenuItem link="/downloads">Letöltések</MenuItem>
    {/* <MenuItem href="http://index.hu">Webáruház</MenuItem> */}
    <MenuItem link="/webshop">Webáruház</MenuItem>
  </ul>
);

export default menu;
