import React from "react";
//import React, { useEffect } from "react";

const Webshop = () => {
/*   useEffect(() => {
    return () => {
      window.location.href = "http://www.index.hu";
    };
  }, []);

  return <section>Redirecting...</section>; */
  return <div><strong>A Webáruház fejlesztés alatt van, kérlek látogass vissza később!</strong></div>;
};

export default Webshop;
