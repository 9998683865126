import React from "react";

import classes from "./ProvidersItem.module.css";

const providersItem = (props) => (
  <div className={classes.ProvidersItem}>
    <img src={props.src} alt={props.name} style={{background: props.color}}></img>
  </div>
);

export default providersItem;
