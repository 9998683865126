import React from "react";
import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";
import classes from "./DataUsage.module.css";

const dataUsage = () => (
  <div className={classes.Text}>
    <PageContentHeader>Adatkezelési Tájékoztató</PageContentHeader>
    <p>
      <strong>ADATKEZEL&Eacute;SI T&Aacute;J&Eacute;KOZTAT&Oacute;</strong>
    </p>
    <p>
      <strong>
        AZ &Eacute;RINTETT TERM&Eacute;SZETES SZEM&Eacute;LY JOGAIR&Oacute;L{" "}
      </strong>
    </p>
    <p>
      <strong>
        &nbsp;SZEM&Eacute;LYES ADATAI KEZEL&Eacute;SE
        VONATKOZ&Aacute;S&Aacute;BAN
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong>BEVEZET&Eacute;S</strong>
    </p>
    <p>&nbsp;</p>
    <p>
      <em>
        A term&eacute;szetes szem&eacute;lyeknek a szem&eacute;lyes adatok
        kezel&eacute;se tekintet&eacute;ben t&ouml;rt&eacute;nő
        v&eacute;delm&eacute;ről &eacute;s az ilyen adatok szabad
        &aacute;raml&aacute;s&aacute;r&oacute;l, valamint a 95/46/EK rendelet
        hat&aacute;lyon k&iacute;v&uuml;l helyez&eacute;s&eacute;ről
        sz&oacute;l&oacute;
      </em>
      <em>
        &nbsp; AZ EUR&Oacute;PAI PARLAMENT &Eacute;S A TAN&Aacute;CS (EU)
        2016/679 RENDELETE&nbsp; (a tov&aacute;bbiakban: Rendelet)
        elő&iacute;rja, hogy az Adatkezelő megfelelő
        int&eacute;zked&eacute;seket hoz annak &eacute;rdek&eacute;ben, hogy
        az &eacute;rintett r&eacute;sz&eacute;re a szem&eacute;lyes adatok
        kezel&eacute;s&eacute;re vonatkoz&oacute;,&nbsp; minden egyes
        t&aacute;j&eacute;koztat&aacute;st t&ouml;m&ouml;r,
        &aacute;tl&aacute;that&oacute;, &eacute;rthető &eacute;s k&ouml;nnyen
        hozz&aacute;f&eacute;rhető form&aacute;ban, vil&aacute;gosan &eacute;s
        k&ouml;z&eacute;rthetően megfogalmazva ny&uacute;jtsa,
        tov&aacute;bb&aacute; hogy az Adatkezelő előseg&iacute;ti az
        &eacute;rintett jogainak a gyakorl&aacute;s&aacute;t.{" "}
      </em>
    </p>
    <p>
      <em>&nbsp;</em>
    </p>
    <p>
      <em>
        Az &eacute;rintett előzetes t&aacute;j&eacute;koztat&aacute;si
        k&ouml;telezetts&eacute;g&eacute;t az inform&aacute;ci&oacute;s
        &ouml;nrendelkez&eacute;si jogr&oacute;l &eacute;s az
        inform&aacute;ci&oacute;szabads&aacute;gr&oacute;l 2011. &eacute;vi
        CXII. t&ouml;rv&eacute;ny is elő&iacute;rja. Az al&aacute;bbiakban
        olvashat&oacute; t&aacute;j&eacute;koztat&aacute;ssal e
        jogszab&aacute;lyi k&ouml;telezetts&eacute;g&uuml;nknek tesz&uuml;nk
        eleget.{" "}
      </em>
    </p>
    <p>
      <em>&nbsp;</em>
    </p>
    <p>
      <strong>
        Mi&eacute;rt k&eacute;sz&uuml;lt ez a
        t&aacute;j&eacute;koztat&oacute;?
      </strong>
    </p>
    <p>
      Adatkezelő műk&ouml;d&eacute;se sor&aacute;n t&ouml;bb
      c&eacute;lb&oacute;l kezel szem&eacute;lyes adatokat &eacute;s mindezt
      az &eacute;rintettek jogainak tiszteletben tart&aacute;s&aacute;val,
      valamint a jogszab&aacute;lyi k&ouml;telezetts&eacute;gnek
      teljes&iacute;t&eacute;s&eacute;vel k&iacute;v&aacute;nja tenni.
      Adatkezelő fontosnak tartja azt is, hogy bemutassa az
      &eacute;rintetteknek az adatkezel&eacute;si tev&eacute;kenys&eacute;ge
      sor&aacute;n a tudom&aacute;s&aacute;ra jutott szem&eacute;lyes adatok
      kezel&eacute;s&eacute;t, annak legfontosabb jellemzőt.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>
        Milyen alapon t&ouml;rt&eacute;nik az &eacute;rintettek
        szem&eacute;lyes adatainak kezel&eacute;se?
      </strong>
    </p>
    <p>
      A szem&eacute;lyes adatok csak meghat&aacute;rozott c&eacute;lb&oacute;l
      &eacute;s megfelelő jogalappal ker&uuml;lnek kezel&eacute;sre. Ezek a
      c&eacute;lok &eacute;s jogalapok a konkr&eacute;t adatkezel&eacute;sek
      vonatkoz&aacute;s&aacute;ban egyedileg ker&uuml;lnek bemutat&aacute;sra.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>
        Milyen k&uuml;lső seg&iacute;ts&eacute;g
        ig&eacute;nybev&eacute;tel&eacute;re ker&uuml;l sor a szem&eacute;lyes
        adatai kezel&eacute;se sor&aacute;n?
      </strong>
    </p>
    <p>
      A szem&eacute;lyes adatokat t&ouml;bbnyire a Adatkezelő a saj&aacute;t
      telephely&eacute;n kezeli. Vannak azonban olyan műveletek, amelyhez
      k&uuml;lső seg&iacute;ts&eacute;get, adatfeldolgoz&oacute;t vesz
      ig&eacute;nybe. Az adatfeldolgoz&oacute; szem&eacute;lye az egyes
      adatkezel&eacute;sek jellemzőinek megfelelően v&aacute;ltozhat.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Ki kezeli a szem&eacute;lyes adatait?</strong>
    </p>
    <p>
      Az adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute; k&ouml;vetkező
      II. fejezet&eacute;ben kaphat felvil&aacute;gos&iacute;t&aacute;st az
      &Eacute;rintett az Adatkezelő &aacute;ltal alkalmazott
      adatfeldolgoz&oacute;k szem&eacute;ly&eacute;ről &eacute;s
      el&eacute;rhetős&eacute;g&eacute;ről.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>
        Milyen elveket tart fontosnak Adatkezelő a szem&eacute;lyes adatai
        kezel&eacute;se sor&aacute;n?
      </strong>
    </p>
    <p>
      A szem&eacute;lyes adatok kezel&eacute;s&eacute;re a hat&aacute;lyos
      jogi szab&aacute;lyoz&aacute;s alapj&aacute;n ker&uuml;l sor,
      k&uuml;l&ouml;n&ouml;s tekintettel az Eur&oacute;pai Parlament &eacute;s
      a tan&aacute;cs 2016/679 rendelet&eacute;ben (2016. &aacute;prilis 27.)
      (GDPR rendelet) foglaltakra.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Adatkezelő tev&eacute;kenys&eacute;gei sor&aacute;n csak az egyedi
      adatkezel&eacute;sek k&ouml;r&eacute;ben meghat&aacute;rozott
      szem&eacute;lyes adatok kezel&eacute;s&eacute;re ker&uuml;l sor
      &eacute;s a megadott, szem&eacute;lyes adatok biztons&aacute;ga a
      lehets&eacute;ges &eacute;s sz&uuml;ks&eacute;ges technikai &eacute;s
      szervez&eacute;si int&eacute;zked&eacute;ssel r&eacute;szes&uuml;l
      v&eacute;delemben. Ennek sor&aacute;n k&uuml;l&ouml;n&ouml;sen nagy
      figyelem ford&iacute;t&aacute;s&aacute;ra ker&uuml;l sor a
      szem&eacute;lyes adatok bizalmass&aacute;g&aacute;nak,
      s&eacute;rtetlens&eacute;g&eacute;nek &eacute;s rendelkez&eacute;sre
      &aacute;ll&aacute;s&aacute;nak biztos&iacute;t&aacute;sa
      &eacute;rdek&eacute;ben.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      A szem&eacute;lyes adatok val&oacute;dis&aacute;g&aacute;&eacute;rt
      &eacute;s pontoss&aacute;g&aacute;&eacute;rt az &Eacute;rintett
      &aacute;ltal t&ouml;rt&eacute;nt megad&aacute;s ut&aacute;n a Adatkezelő
      felel. Azok a kifejez&eacute;sek, amelyek jelen
      t&aacute;j&eacute;koztat&oacute;ban alkalmaz&aacute;sra ker&uuml;lnek az
      inform&aacute;ci&oacute;s &ouml;nrendelkez&eacute;si jogr&oacute;l
      &eacute;s a GDPR rendelet &eacute;rtelmező rendelkez&eacute;sei
      k&ouml;z&ouml;tt meghat&aacute;rozott fogalmak szerint ker&uuml;ltek
      &eacute;rtelmez&eacute;sre.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong> FEJEZET</strong>
      </li>
    </ol>
    <p>
      <strong>AZ ADATKEZELŐ MEGNEVEZ&Eacute;SE</strong>
    </p>
    <p>&nbsp;</p>
    <p>
      E t&aacute;j&eacute;koztat&aacute;s kiad&oacute;ja, egyben az
      Adatkezelő:
    </p>
    <p>
      C&eacute;gn&eacute;v:ELEKKTRO-AMPER Korl&aacute;tolt Felelőss&eacute;gű
      T&aacute;rsas&aacute;g
    </p>
    <p>Sz&eacute;khely: 6600 SZENTES, S&Aacute;RGAPARTI&nbsp; U.21.</p>
    <p>K&eacute;pviseli: BIR&Oacute;N&Eacute; V&Aacute;CZI PIROSKA</p>
    <p>
      el&eacute;rhetős&eacute;gek:{" "}
      <a href="https://elektroamper.hu/">https://elektroamper.hu/</a>{" "}
      el&eacute;rhetős&eacute;gek men&uuml;pont alatt
    </p>
    <p>
      &nbsp;
      <a href="https://www.facebook.com/ampervillamossagi/">
        https://www.facebook.com/ampervillamossagi/
      </a>
      , 00 36 (68) 412202
    </p>
    <p>birogyorgy@elektroamper.hu</p>
    <p>
      <strong>&nbsp;(a tov&aacute;bbiakban: T&aacute;rsas&aacute;g) </strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        <strong> FEJEZET</strong>
      </li>
    </ol>
    <p>
      <strong>ADATFELDOLGOZ&Oacute;K MEGNEVEZ&Eacute;SE </strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>Adatfeldolgoz&oacute;</strong>: az a term&eacute;szetes vagy
      jogi szem&eacute;ly, k&ouml;zhatalmi szerv, &uuml;gyn&ouml;ks&eacute;g
      vagy b&aacute;rmely egy&eacute;b szerv, amely az adatkezelő
      nev&eacute;ben szem&eacute;lyes adatokat kezel; (Rendelet 4. cikk 8.)
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Az adatfeldolgoz&oacute; ig&eacute;nybev&eacute;tel&eacute;hez nem kell
      az &eacute;rintett előzetes beleegyez&eacute;se, de
      sz&uuml;ks&eacute;ges a t&aacute;j&eacute;koztat&aacute;sa. Ennek
      megfelelően a k&ouml;vetkező t&aacute;j&eacute;koztat&aacute;st adjuk:
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>IT szolg&aacute;ltat&oacute;: </strong>
    </p>
    <p>C&Eacute;GN&Eacute;V: Google LLC</p>
    <p>
      SZ&Eacute;KHELY:&nbsp;&nbsp; 1600 Amphitheatre PkwyMountain View,
      California 94043
    </p>
    <p>
      (az EU-U.S. PRIVACY SHIELD FRAMEWORK alapj&aacute;n EU-ban honosnak
      tekinthető)
    </p>
    <p>HONLAP: https://mail.google.com/</p>
    <p>&nbsp;</p>
    <p>C&Eacute;GN&Eacute;V: Facebook, Inc.</p>
    <p>SZ&Eacute;KHELY:&nbsp;&nbsp; Menlo Park, California, USA</p>
    <p>
      (az EU-U.S. PRIVACY SHIELD FRAMEWORK alapj&aacute;n EU-ban honosnak
      tekinthető)
    </p>
    <p>HONLAP: https://www.facebook.com/</p>
    <p>&nbsp;</p>
    <p>
      <strong>
        K&ouml;nyvel&eacute;si, b&eacute;rsz&aacute;mfejt&eacute;si
        feladatokat ell&aacute;t&oacute; adatfeldolgoz&oacute;:
      </strong>
    </p>
    <p>
      C&Eacute;GN&Eacute;V: LANTANA-Audit K&ouml;nyvvizsg&aacute;l&oacute;-.
      &eacute;s Ad&oacute;szak&eacute;rtő Kft.
    </p>
    <p>
      SZ&Eacute;KHELY:&nbsp;&nbsp; 1032 Budapest, Solym&aacute;r u. 4. 5. em.
      27.
    </p>
    <p>E-MAIL C&Iacute;M: lantanaaudit@gmail.com</p>
    <p>&nbsp;</p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>
        Postai, fut&aacute;rszolg&aacute;lati, logisztikai feladatokat
        ell&aacute;t&oacute; adatfeldolgoz&oacute;:
      </strong>
    </p>
    <p>C&Eacute;GN&Eacute;V: MAGYAR POSTA ZRT.</p>
    <p>
      SZ&Eacute;KHELY:&nbsp;&nbsp; 1138 BUDAPEST, DUNAVIR&Aacute;G UTCA 2-6.
    </p>
    <p>HONLAP: https://www.posta.hu</p>
    <p>
      E-MAIL C&Iacute;M:
      ugyfelszolgalat@posta.hu&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </p>
    <p>TELEFONSZ&Aacute;M: (1) 767 8282</p>
    <p>&nbsp;</p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>III. FEJEZET</strong>
    </p>
    <p>
      <strong>
        AZ ADATKEZEL&Eacute;S JOGSZERŰS&Eacute;G&Eacute;NEK
        BIZTOS&Iacute;T&Aacute;SA
      </strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        <strong>
          {" "}
          Adatkezel&eacute;s az &eacute;rintett
          hozz&aacute;j&aacute;rul&aacute;sa alapj&aacute;n{" "}
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      1.1. Amennyiben a T&aacute;rsas&aacute;g
      hozz&aacute;j&aacute;rul&aacute;son alapul&oacute; adatkezel&eacute;st
      k&iacute;v&aacute;n v&eacute;gezni, az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;s&aacute;t szem&eacute;lyes adatai
      kezel&eacute;s&eacute;hez az adatkezel&eacute;si szab&aacute;lyzatban
      meghat&aacute;rozott adatk&eacute;rő lap szerinti tartalommal &eacute;s
      t&aacute;j&eacute;koztat&aacute;ssal kell k&eacute;rni.
    </p>
    <p>&nbsp;</p>
    <p>
      1.2. Hozz&aacute;j&aacute;rul&aacute;snak minős&uuml;l az is, ha az
      &eacute;rintett a T&aacute;rsas&aacute;g internetes honlapj&aacute;nak
      megtekint&eacute;se sor&aacute;n bejel&ouml;l egy erre vonatkoz&oacute;
      n&eacute;gyzetet, az inform&aacute;ci&oacute;s t&aacute;rsadalommal
      &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;sok
      ig&eacute;nybev&eacute;tele sor&aacute;n erre vonatkoz&oacute; technikai
      be&aacute;ll&iacute;t&aacute;sokat hajt v&eacute;gre, valamint
      b&aacute;rmely egy&eacute;b olyan nyilatkozat vagy cselekedet is, amely
      az adott &ouml;sszef&uuml;gg&eacute;sben az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;s&aacute;t szem&eacute;lyes adatainak
      tervezett kezel&eacute;s&eacute;hez egy&eacute;rtelműen jelzi. A
      hallgat&aacute;s, az előre bejel&ouml;lt n&eacute;gyzet vagy a nem
      cselekv&eacute;s ez&eacute;rt nem minős&uuml;l
      hozz&aacute;j&aacute;rul&aacute;snak.&nbsp;
      Hozz&aacute;j&aacute;rul&aacute;snak minős&uuml;l megfelelő
      t&aacute;j&eacute;koztat&aacute;s meghallgat&aacute;sa ut&aacute;n a
      telefonh&iacute;v&aacute;s folytat&aacute;sa.
    </p>
    <p>&nbsp;</p>
    <p>
      1.3. A hozz&aacute;j&aacute;rul&aacute;s az ugyanazon c&eacute;l vagy
      c&eacute;lok &eacute;rdek&eacute;ben v&eacute;gzett &ouml;sszes
      adatkezel&eacute;si tev&eacute;kenys&eacute;gre kiterjed. Ha az
      adatkezel&eacute;s egyszerre t&ouml;bb c&eacute;lt is szolg&aacute;l,
      akkor a hozz&aacute;j&aacute;rul&aacute;st az &ouml;sszes
      adatkezel&eacute;si c&eacute;lra vonatkoz&oacute;an meg kell adni.
    </p>
    <p>&nbsp;</p>
    <p>
      1.4. Ha az &eacute;rintett hozz&aacute;j&aacute;rul&aacute;s&aacute;t
      olyan &iacute;r&aacute;sbeli nyilatkozat keret&eacute;ben adja meg,
      amely m&aacute;s &uuml;gyekre is vonatkozik &ndash;
      p&eacute;ld&aacute;ul &eacute;rt&eacute;kes&iacute;t&eacute;si,
      szolg&aacute;ltat&aacute;si szerződ&eacute;s megk&ouml;t&eacute;se - a
      hozz&aacute;j&aacute;rul&aacute;s ir&aacute;nti k&eacute;relmet ezektől
      a m&aacute;s &uuml;gyektől egy&eacute;rtelműen
      megk&uuml;l&ouml;nb&ouml;ztethető m&oacute;don kell előadni,
      &eacute;rthető &eacute;s k&ouml;nnyen hozz&aacute;f&eacute;rhető
      form&aacute;ban, vil&aacute;gos &eacute;s egyszerű nyelvezettel. Az
      &eacute;rintett hozz&aacute;j&aacute;rul&aacute;s&aacute;t
      tartalmaz&oacute; ilyen nyilatkozat b&aacute;rmely olyan r&eacute;sze,
      amely s&eacute;rti a Rendeletet, k&ouml;telező erővel nem b&iacute;r.
    </p>
    <p>&nbsp;</p>
    <p>
      1.5. A T&aacute;rsas&aacute;g nem k&ouml;theti szerződ&eacute;s
      megk&ouml;t&eacute;s&eacute;t, teljes&iacute;t&eacute;s&eacute;t olyan
      szem&eacute;lyes adatok kezel&eacute;s&eacute;hez val&oacute;
      hozz&aacute;j&aacute;rul&aacute;s megad&aacute;s&aacute;hoz, amelyek nem
      sz&uuml;ks&eacute;gesek a szerződ&eacute;s
      teljes&iacute;t&eacute;s&eacute;hez.
    </p>
    <p>&nbsp;</p>
    <p>
      1.6. A hozz&aacute;j&aacute;rul&aacute;s visszavon&aacute;s&aacute;t
      ugyanolyan egyszerű m&oacute;don kell lehetőv&eacute; tenni, mint annak
      megad&aacute;s&aacute;t.
    </p>
    <p>&nbsp;</p>
    <p>
      1.7. Ha a szem&eacute;lyes adat felv&eacute;tel&eacute;re az
      &eacute;rintett hozz&aacute;j&aacute;rul&aacute;s&aacute;val ker&uuml;lt
      sor, az adatkezelő a felvett adatokat t&ouml;rv&eacute;ny elt&eacute;rő
      rendelkez&eacute;s&eacute;nek hi&aacute;ny&aacute;ban a r&aacute;
      vonatkoz&oacute; jogi k&ouml;telezetts&eacute;g
      teljes&iacute;t&eacute;se c&eacute;lj&aacute;b&oacute;l tov&aacute;bbi
      k&uuml;l&ouml;n hozz&aacute;j&aacute;rul&aacute;s n&eacute;lk&uuml;l,
      valamint az &eacute;rintett hozz&aacute;j&aacute;rul&aacute;s&aacute;nak
      visszavon&aacute;s&aacute;t k&ouml;vetően is kezelheti.
    </p>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        <strong>
          {" "}
          Jogi k&ouml;telezetts&eacute;g teljes&iacute;t&eacute;s&eacute;n
          alapul&oacute; adatkezel&eacute;s{" "}
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      2.1. A jogi k&ouml;telezetts&eacute;gen alapul&oacute;
      adatkezel&eacute;s eset&eacute;n a kezelhető adatok k&ouml;r&eacute;re,
      az adatkezel&eacute;s c&eacute;lj&aacute;ra, az adatok
      t&aacute;rol&aacute;s&aacute;nak időtartam&aacute;ra, a
      c&iacute;mzettekre az alapul szolg&aacute;l&oacute; jogszab&aacute;ly
      rendelkez&eacute;sei ir&aacute;nyad&oacute;k.
    </p>
    <p>&nbsp;</p>
    <p>
      2.2 A jogi k&ouml;telezetts&eacute;g teljes&iacute;t&eacute;se
      jogc&iacute;m&eacute;n alapul&oacute; adatkezel&eacute;s az
      &eacute;rintett hozz&aacute;j&aacute;rul&aacute;s&aacute;t&oacute;l
      f&uuml;ggetlen, mivel az adatkezel&eacute;st jogszab&aacute;ly
      hat&aacute;rozza meg. Az &eacute;rintettel az adatkezel&eacute;s
      megkezd&eacute;se előtt ezesetben k&ouml;z&ouml;lni kell, hogy az
      adatkezel&eacute;s k&ouml;telező, tov&aacute;bb&aacute; az
      &eacute;rintettet az adatkezel&eacute;s megkezd&eacute;se előtt
      egy&eacute;rtelműen &eacute;s r&eacute;szletesen
      t&aacute;j&eacute;koztatni kell az adatai kezel&eacute;s&eacute;vel
      kapcsolatos minden t&eacute;nyről, &iacute;gy k&uuml;l&ouml;n&ouml;sen
      az adatkezel&eacute;s c&eacute;lj&aacute;r&oacute;l &eacute;s
      jogalapj&aacute;r&oacute;l, az adatkezel&eacute;sre &eacute;s az
      adatfeldolgoz&aacute;sra jogosult szem&eacute;ly&eacute;ről, az
      adatkezel&eacute;s időtartam&aacute;r&oacute;l, arr&oacute;l, ha az
      &eacute;rintett szem&eacute;lyes adatait az adatkezelő a r&aacute;
      vonatkoz&oacute; jogi k&ouml;telezetts&eacute;g alapj&aacute;n kezeli,
      illetve arr&oacute;l, hogy kik ismerhetik meg az adatokat. A
      t&aacute;j&eacute;koztat&aacute;snak ki kell terjednie az
      &eacute;rintett adatkezel&eacute;ssel kapcsolatos jogaira &eacute;s
      jogorvoslati lehetős&eacute;geire is. K&ouml;telező adatkezel&eacute;s
      eset&eacute;n a t&aacute;j&eacute;koztat&aacute;s
      megt&ouml;rt&eacute;nhet az előbbi inform&aacute;ci&oacute;kat
      tartalmaz&oacute; jogszab&aacute;lyi rendelkez&eacute;sekre val&oacute;
      utal&aacute;s nyilv&aacute;noss&aacute;gra hozatal&aacute;val is.
    </p>
    <p>&nbsp;</p>
    <ol start="3">
      <li>
        <strong>
          {" "}
          Jogos &eacute;rdeken alapul&oacute; adatkezel&eacute;s
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      3.1. A T&aacute;rsas&aacute;g vagy valamely harmadik f&eacute;l jogos
      &eacute;rdeke jogalapot teremthet az adatkezel&eacute;sre,
      felt&eacute;ve, hogy az &eacute;rintett &eacute;rdekei, alapvető jogai
      &eacute;s szabads&aacute;gai nem &eacute;lveznek elsőbbs&eacute;get.
      Figyelembe kell venni az &eacute;rintett adatkezelővel val&oacute;
      kapcsolata alapj&aacute;n &eacute;szszerű elv&aacute;r&aacute;sait,
      &iacute;gy a szem&eacute;lyes adatok kapcsolattart&aacute;si,
      ak&aacute;r k&ouml;zvetlen &uuml;zletszerz&eacute;si c&eacute;l&uacute;
      kezel&eacute;se is jogos &eacute;rdeken alapul&oacute;nak tekinthető.
    </p>
    <p>&nbsp;</p>
    <p>
      3.2. A jogos &eacute;rdeken alapul&oacute; adatkezel&eacute;shez
      &eacute;rdekm&eacute;rlegel&eacute;si teszt sz&uuml;ks&eacute;ges,
      melynek sor&aacute;n a T&aacute;rsas&aacute;g mindig figyelembe veszi az
      aktu&aacute;lis k&ouml;r&uuml;lm&eacute;nyeket, valamint az adatkezelő
      &eacute;s &eacute;rintettek helyzet&eacute;t. A T&aacute;rsas&aacute;g
      &eacute;rdek&eacute;ben t&ouml;rt&eacute;nő adatkezel&eacute;sek
      vonatkoz&aacute;s&aacute;ban a k&uuml;l&ouml;n-k&uuml;l&ouml;n
      elv&eacute;gzett &eacute;rdekm&eacute;rlegel&eacute;si tesztek az
      al&aacute;bbi eredm&eacute;nnyel z&aacute;rultak: Az
      &eacute;rdekm&eacute;rlegel&eacute;si teszt sor&aacute;n a
      T&aacute;rsas&aacute;g az adott adatkezel&eacute;sn&eacute;l
      le&iacute;rt felt&eacute;teleket figyelembev&eacute;ve arra jutott, hogy
      az adatkezel&eacute;s indokolt a megfelelő &ndash; jelen
      szab&aacute;lyzatba foglalt &ndash; biztos&iacute;t&eacute;kok mellett,
      hiszen an&eacute;lk&uuml;l nem tudna a T&aacute;rsas&aacute;g
      versenyk&eacute;pesen műk&ouml;dni. Ennek t&uuml;kr&eacute;ben az
      &eacute;rintettekre vonatkoz&oacute; &eacute;rzelmi hat&aacute;s,
      valamint a mag&aacute;nszf&eacute;r&aacute;hoz val&oacute; jog
      s&eacute;relme ar&aacute;nyosnak tekinthető.
    </p>
    <ol start="4">
      <li>
        <strong>
          {" "}
          Az &eacute;rintett vagy m&aacute;s term&eacute;szetes szem&eacute;ly
          l&eacute;tfontoss&aacute;g&uacute; &eacute;rdekeinek v&eacute;delme
          miatti
        </strong>{" "}
        <strong>adatkezel&eacute;s</strong>
      </li>
    </ol>
    <p>
      4.1. Az &eacute;rintett &eacute;let&eacute;nek vagy m&aacute;s
      l&eacute;tfontoss&aacute;g&uacute; &eacute;rdek&eacute;nek vagy
      m&aacute;s term&eacute;szetes szem&eacute;ly &eacute;rdekeinek
      v&eacute;delme szint&eacute;n jogalapot teremthet az
      adatkezel&eacute;sre. Ilyen eset &aacute;ll fenn term&eacute;szetes
      szem&eacute;ly eset&eacute;n, ha eg&eacute;szs&eacute;g&uuml;gyi
      szolg&aacute;ltat&aacute;st vesz ig&eacute;nybe vagy
      j&aacute;rv&aacute;nyok terjed&eacute;s&eacute;nek
      meg&aacute;ll&iacute;t&aacute;sa miatt ker&uuml;l sor az
      adatkezel&eacute;sre.
    </p>
    <ol start="5">
      <li>
        <strong>
          {" "}
          Szerződ&eacute;ses &eacute;rdeken alapul&oacute; adatkezel&eacute;s
        </strong>
      </li>
    </ol>
    <p>
      5.1. Szerződ&eacute;ses &eacute;rdeken is alapulhat adatkezel&eacute;s
      amennyiben az olyan szerződ&eacute;s teljes&iacute;t&eacute;s&eacute;hez
      sz&uuml;ks&eacute;ges, amelyben az &eacute;rintett az egyik f&eacute;l,
      vagy az a szerződ&eacute;s megk&ouml;t&eacute;s&eacute;t megelőzően az
      &eacute;rintett k&eacute;r&eacute;s&eacute;re t&ouml;rt&eacute;nő
      l&eacute;p&eacute;sek megt&eacute;tel&eacute;hez sz&uuml;ks&eacute;ges.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="6">
      <li>
        <strong>
          {" "}
          Az &eacute;rintett jogainak előseg&iacute;t&eacute;se
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      6.1. A T&aacute;rsas&aacute;g valamennyi adatkezel&eacute;se
      sor&aacute;n k&ouml;teles biztos&iacute;tani az &eacute;rintett jogainak
      gyakorl&aacute;s&aacute;t.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong> FEJEZET</strong>
      </li>
    </ol>
    <p>
      <strong>
        T&Aacute;J&Eacute;KOZTAT&Aacute;S A T&Aacute;RSAS&Aacute;G
        &Aacute;LTAL V&Eacute;GZETT ADATKEZEL&Eacute;SRŐL
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        &Uuml;gyf&eacute;ladatok: szerződő partnerek, kapcsolattart&oacute;k
        adatainak kezel&eacute;se &ndash; vevők,
        sz&aacute;ll&iacute;t&oacute;k nyilv&aacute;ntart&aacute;sa
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) A T&aacute;rsas&aacute;g szerződ&eacute;s teljes&iacute;t&eacute;se
      jogc&iacute;m&eacute;n a szerződ&eacute;s
      elők&eacute;sz&iacute;t&eacute;se, megk&ouml;t&eacute;se,
      teljes&iacute;t&eacute;se, megszűn&eacute;se, szerződ&eacute;si
      kedvezm&eacute;ny ny&uacute;jt&aacute;sa &ndash; &ouml;sszefoglalva a
      k&ouml;z&ouml;s &eacute;rdekk&ouml;rben felmer&uuml;lő gazdas&aacute;gi
      folyamatok t&aacute;mogat&aacute;sa &ndash;
      c&eacute;lj&aacute;b&oacute;l kezelheti a vele szerződ&eacute;ses
      viszonyban &aacute;ll&oacute; term&eacute;szetes szem&eacute;ly
      nev&eacute;t, sz&uuml;let&eacute;si nev&eacute;t, sz&uuml;let&eacute;si
      idej&eacute;t, anyja nev&eacute;t, lakc&iacute;m&eacute;t,
      ad&oacute;azonos&iacute;t&oacute; jel&eacute;t,
      ad&oacute;sz&aacute;m&aacute;t, v&aacute;llalkoz&oacute;i, őstermelői
      igazolv&aacute;ny sz&aacute;m&aacute;t, szem&eacute;lyi
      igazolv&aacute;ny sz&aacute;m&aacute;t, lakc&iacute;m&eacute;t,
      sz&eacute;khely, telephely c&iacute;m&eacute;t,
      telefonsz&aacute;m&aacute;t, e-mail c&iacute;m&eacute;t,
      honlap-c&iacute;m&eacute;t, banksz&aacute;mlasz&aacute;m&aacute;t,
      vevősz&aacute;m&aacute;t (&uuml;gyf&eacute;lsz&aacute;m&aacute;t,
      rendel&eacute;ssz&aacute;m&aacute;t), online
      azonos&iacute;t&oacute;j&aacute;t (vevők, sz&aacute;ll&iacute;t&oacute;k
      list&aacute;ja, t&ouml;rzsv&aacute;s&aacute;rl&aacute;si list&aacute;k),
      eg&eacute;szs&eacute;g&uuml;gyi alkalmass&aacute;gi iratait,
      bizony&iacute;tv&aacute;ny&aacute;t. Ezen adatkezel&eacute;s jogszerűnek
      minős&uuml;l akkor is, ha az adatkezel&eacute;s a szerződ&eacute;s
      megk&ouml;t&eacute;s&eacute;t megelőzően az &eacute;rintett
      k&eacute;r&eacute;s&eacute;re t&ouml;rt&eacute;nő l&eacute;p&eacute;sek
      megt&eacute;tel&eacute;hez sz&uuml;ks&eacute;ges. A szem&eacute;lyes
      adatok c&iacute;mzettjei: a T&aacute;rsas&aacute;g
      &uuml;gyf&eacute;lkiszolg&aacute;l&aacute;ssal kapcsolatos feladatokat
      ell&aacute;t&oacute; munkav&aacute;llal&oacute;i,
      k&ouml;nyvel&eacute;si, ad&oacute;z&aacute;si,
      &uuml;zletk&ouml;t&eacute;si feladatokat ell&aacute;t&oacute;
      munkav&aacute;llal&oacute;i, &eacute;s adatfeldolgoz&oacute;i. A
      szem&eacute;lyes adatok t&aacute;rol&aacute;s&aacute;nak időtartama a
      T&aacute;rsas&aacute;g hossz&uacute; t&aacute;v&uacute; &uuml;zleti
      kapcsolataira tekintettel a szerződ&eacute;s megszűn&eacute;s&eacute;t
      k&ouml;vető 5 &eacute;v.
    </p>
    <p>&nbsp;</p>
    <p>
      (2) A term&eacute;szetes szem&eacute;ly szerződő f&eacute;l
      szerződ&eacute;sben megadott adatai sz&aacute;mviteli,
      ad&oacute;z&aacute;si c&eacute;l&uacute; kezel&eacute;s&eacute;nek
      jogalapja jogi k&ouml;telezetts&eacute;g teljes&iacute;t&eacute;se,
      ebben a k&ouml;rben az adatt&aacute;rol&aacute;s időtartama 8 &eacute;v.
    </p>
    <p>&nbsp;</p>
    <p>
      (3) A T&aacute;rsas&aacute;g a vele szerződő jogi szem&eacute;ly
      k&eacute;pviselet&eacute;ben elj&aacute;r&oacute; &ndash; a
      szerződ&eacute;st al&aacute;&iacute;r&oacute;&ndash; term&eacute;szetes
      szem&eacute;ly szerződ&eacute;sben megadott szem&eacute;lyes adatait,
      tov&aacute;bb&aacute; lakc&iacute;m&eacute;t, e-mail c&iacute;m&eacute;t
      &eacute;s telefonsz&aacute;m&aacute;t, online
      azonos&iacute;t&oacute;j&aacute;t szerződ&eacute;s
      elők&eacute;sz&iacute;t&eacute;se, kapcsolattart&aacute;s, a
      szerződ&eacute;sből eredő jogok &eacute;s k&ouml;telezetts&eacute;gek
      gyakorl&aacute;sa &ndash; &ouml;sszefoglalva a k&ouml;z&ouml;s
      &eacute;rdekk&ouml;rben felmer&uuml;lő gazdas&aacute;gi folyamatok
      t&aacute;mogat&aacute;sa &ndash; c&eacute;lj&aacute;b&oacute;l
      szerződ&eacute;s teljes&iacute;t&eacute;se jogc&iacute;m&eacute;n
      kezeli. Ezen adatok t&aacute;rol&aacute;s&aacute;nak időtartama a
      szerződ&eacute;s megszűn&eacute;s&eacute;t k&ouml;vető 5 &eacute;v. A
      jogos &eacute;rdeken alapul&oacute; adatkezel&eacute;s eset&eacute;n az
      &eacute;rintett kiemelt joga, hogy tiltakozzon az adatkezel&eacute;s
      ellen.
    </p>
    <p>&nbsp;</p>
    <p>
      (4) A T&aacute;rsas&aacute;g a vele k&ouml;t&ouml;tt szerződ&eacute;sben
      kapcsolatart&oacute;k&eacute;nt megjel&ouml;lt &ndash; nem
      al&aacute;&iacute;r&oacute; - term&eacute;szetes szem&eacute;ly
      nev&eacute;t, c&iacute;m&eacute;t, telefonsz&aacute;m&aacute;t, e-mail
      c&iacute;m&eacute;t, online azonos&iacute;t&oacute;j&aacute;t
      kapcsolattart&aacute;s, szerződ&eacute;sből eredő jogok &eacute;s
      k&ouml;telezetts&eacute;gek gyakorl&aacute;sa &ndash; &ouml;sszefoglalva
      a k&ouml;z&ouml;s &eacute;rdekk&ouml;rben felmer&uuml;lő
      gazdas&aacute;gi folyamatok t&aacute;mogat&aacute;sa &ndash;
      c&eacute;lj&aacute;b&oacute;l szerződ&eacute;s teljes&iacute;t&eacute;se
      jogc&iacute;m&eacute;n kezeli, figyelemmel arra, hogy a
      kapcsolattart&oacute; a szerződő f&eacute;llel foglalkoztat&aacute;sra
      ir&aacute;nyul&oacute; jogviszonyban &aacute;ll, &iacute;gy ezen
      adatkezel&eacute;s az &eacute;rinett jogait nem &eacute;rinti
      h&aacute;tr&aacute;nyosan. A szerződő f&eacute;l kijelenti, hogy a
      kapcsolattart&oacute;i minős&eacute;ghez kapcsol&oacute;d&oacute;
      adatkezel&eacute;sről az &eacute;rintett kapcsolatart&oacute;t
      t&aacute;j&eacute;koztatta. Ezen adatok t&aacute;rol&aacute;s&aacute;nak
      időtartama a kapcsolattart&oacute;i minős&eacute;g
      fenn&aacute;ll&aacute;s&aacute;t k&ouml;vető 5 &eacute;v.
    </p>
    <p>&nbsp;</p>
    <p>
      (5) Valamennyi &eacute;rintett vonatkoz&aacute;s&aacute;ban a
      szem&eacute;lyes adatok c&iacute;mzettjei: a T&aacute;rsas&aacute;g
      vezető tiszts&eacute;gviselője,
      &uuml;gyf&eacute;lkiszolg&aacute;l&aacute;ssal kapcsolatos feladatokat
      ell&aacute;t&oacute; munkav&aacute;llal&oacute;i,
      kapcsolattart&oacute;i, k&ouml;nyvel&eacute;si, ad&oacute;z&aacute;si,
      &uuml;zletk&ouml;t&eacute;si feladatokat ell&aacute;t&oacute;
      munkav&aacute;llal&oacute;i, &eacute;s adatfeldolgoz&oacute;i.
    </p>
    <p>&nbsp;</p>
    <p>
      (6) A szem&eacute;lyes adatok adatfeldolgoz&aacute;sra
      &aacute;tad&aacute;sra ker&uuml;lhetnek ad&oacute;z&aacute;s,
      k&ouml;nyvel&eacute;s c&eacute;lj&aacute;b&oacute;l a
      t&aacute;rsas&aacute;g &aacute;ltal megb&iacute;zott k&ouml;nyvelő
      irod&aacute;nak, post&aacute;z&aacute;s sz&aacute;ll&iacute;t&aacute;s
      c&eacute;lj&aacute;b&oacute;l a Magyar Post&aacute;nak, illetve a
      megb&iacute;zott fut&aacute;rszolg&aacute;latnak, vagyonv&eacute;delem
      c&eacute;lj&aacute;b&oacute;l a t&aacute;rsas&aacute;g
      vagyonv&eacute;delmi megb&iacute;zottj&aacute;nak.
    </p>
    <p>&nbsp;</p>
    <p>
      (7) Az adatkezel&eacute;s jogszerűnek minős&uuml;l, ha arra valamely
      szerződ&eacute;s vagy szerződ&eacute;sk&ouml;t&eacute;si
      sz&aacute;nd&eacute;k keret&eacute;ben van sz&uuml;ks&eacute;g
      (Preambulum 44.) ha az a szerződ&eacute;s megk&ouml;t&eacute;s&eacute;t
      megelőzően az &eacute;rintett k&eacute;r&eacute;s&eacute;re
      t&ouml;rt&eacute;nő l&eacute;p&eacute;sek megt&eacute;tel&eacute;hez
      sz&uuml;ks&eacute;ges (6 cikk (1) b./). &Iacute;gy szerződ&eacute;s
      teljes&iacute;t&eacute;se jogc&iacute;m&eacute;n az e pontban
      &iacute;rtak szerint kezelhetők a szerződ&eacute;si aj&aacute;nlatok
      keret&eacute;ben gyűjt&ouml;tt szem&eacute;lyes adatok is.
      Aj&aacute;nlatt&eacute;telkor, illetve fogad&aacute;skor erről a
      T&aacute;rsas&aacute;g k&ouml;teles az aj&aacute;nlattevőt, illetve az
      aj&aacute;nlat c&iacute;mzettj&eacute;t t&aacute;j&eacute;koztatni kell.
    </p>
    <p>&nbsp;</p>
    <p>
      (8) A T&aacute;rsas&aacute;g &aacute;ltal k&ouml;tendő
      szerződ&eacute;sekben alkalmazand&oacute; adatkezel&eacute;si
      kik&ouml;t&eacute;seket &eacute;s t&aacute;j&eacute;koztat&aacute;sokat
      jelen Szab&aacute;lyzat 5. sz&aacute;m&uacute; mell&eacute;klete
      tartalmazza. A T&aacute;rsas&aacute;g
      munkav&aacute;llal&oacute;j&aacute;nak feladata &eacute;s
      k&ouml;telezetts&eacute;ge, hogy ezen adatkezel&eacute;si
      kik&ouml;t&eacute;sek a szerződ&eacute;s sz&ouml;veg&eacute;be
      belefoglal&aacute;sra ker&uuml;ljenek.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        &Uuml;zenetk&uuml;ld&eacute;s a T&aacute;rsas&aacute;g
        honlapj&aacute;n
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) A honlapon &uuml;zenetet k&uuml;ldő term&eacute;szetes
      szem&eacute;ly az erre vonatkoz&oacute; n&eacute;gyzet
      bejel&ouml;l&eacute;s&eacute;vel adhatja meg
      hozz&aacute;j&aacute;rul&aacute;s&aacute;t szem&eacute;lyes adatai
      kezel&eacute;s&eacute;hez. Tilos a n&eacute;gyzet előre
      bejel&ouml;l&eacute;se.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      (2) A kezelhető szem&eacute;lyes adatok k&ouml;re: a term&eacute;szetes
      szem&eacute;ly neve (vezet&eacute;kn&eacute;v, keresztn&eacute;v),
      c&iacute;me, telefonsz&aacute;ma, e-mail c&iacute;me, online
      azonos&iacute;t&oacute;, sz&aacute;ml&aacute;z&aacute;si,
      post&aacute;z&aacute;si n&eacute;v &eacute;s c&iacute;m.
    </p>
    <p>&nbsp;</p>
    <p>
      (3) A szem&eacute;lyes adatok kezel&eacute;s&eacute;nek c&eacute;lja:
    </p>
    <p>
      term&eacute;k keres&eacute;s, inform&aacute;ci&oacute;
      k&eacute;r&eacute;s, illetve aj&aacute;nlat k&eacute;r&eacute;s
    </p>
    <p>&nbsp;</p>
    <p>
      (4) Az adatkezel&eacute;s jogalapja az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;sa.
    </p>
    <p>&nbsp;</p>
    <p>
      (5) A szem&eacute;lyes adatok c&iacute;mzettjei, illetve a
      c&iacute;mzettek kateg&oacute;ri&aacute;i: a T&aacute;rsas&aacute;g
      &uuml;gyf&eacute;lszolg&aacute;lattal, marketing
      tev&eacute;kenys&eacute;g&eacute;vel kapcsolatos feladatokat
      ell&aacute;t&oacute; munkav&aacute;llal&oacute;i,
      adatfeldolgoz&oacute;k&eacute;nt a T&aacute;rsas&aacute;g IT
      szolg&aacute;ltat&oacute;ja t&aacute;rhelyszolg&aacute;ltat&aacute;st
      v&eacute;gző munkav&aacute;llal&oacute;i.
    </p>
    <p>&nbsp;</p>
    <p>
      (6) A szem&eacute;lyes adatok t&aacute;rol&aacute;s&aacute;nak
      időtartama: 5 &eacute;vig vagy az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;sa visszavon&aacute;s&aacute;ig
      (t&ouml;rl&eacute;si k&eacute;relm&eacute;ig).
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Adatkezel&eacute;s a T&aacute;rsas&aacute;g
        web&aacute;ruh&aacute;z&aacute;ban
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) A T&aacute;rsas&aacute;g &aacute;ltal műk&ouml;dtetett
      web&aacute;ruh&aacute;zban t&ouml;rt&eacute;nő
      v&aacute;s&aacute;rl&aacute;s szerződ&eacute;snek minős&uuml;l,
      figyelemmel az elektronikus kereskedelmi szolg&aacute;ltat&aacute;sok,
      valamint az inform&aacute;ci&oacute;s t&aacute;rsadalommal
      &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;sok egyes
      k&eacute;rd&eacute;seiről sz&oacute;l&oacute; 2001. &eacute;vi CVIII.
      t&ouml;rv&eacute;ny 13/A.&sect;-&aacute;ra, &eacute;s a fogyaszt&oacute;
      &eacute;s a v&aacute;llalkoz&aacute;s k&ouml;z&ouml;tti
      szerződ&eacute;sek r&eacute;szletes szab&aacute;lyair&oacute;l
      sz&oacute;l&oacute; 45/2014. (II. 26.) Korm. rendeletre is.
      Web&aacute;ruh&aacute;zban t&ouml;rt&eacute;nő
      v&aacute;s&aacute;rl&aacute;s eset&eacute;n az adatkezel&eacute;s
      jogc&iacute;me a szerződ&eacute;s.
    </p>
    <p>&nbsp;</p>
    <p>
      (2) A T&aacute;rsas&aacute;g a szolg&aacute;ltat&oacute; az
      inform&aacute;ci&oacute;s t&aacute;rsadalommal &ouml;sszef&uuml;ggő
      szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;s&aacute;ra
      ir&aacute;nyul&oacute; szerződ&eacute;s l&eacute;trehoz&aacute;sa,
      tartalm&aacute;nak meghat&aacute;roz&aacute;sa,
      m&oacute;dos&iacute;t&aacute;sa, teljes&iacute;t&eacute;s&eacute;nek
      figyelemmel k&iacute;s&eacute;r&eacute;se, az abb&oacute;l
      sz&aacute;rmaz&oacute; d&iacute;jak sz&aacute;ml&aacute;z&aacute;sa,
      valamint az azzal kapcsolatos k&ouml;vetel&eacute;sek
      &eacute;rv&eacute;nyes&iacute;t&eacute;se c&eacute;lj&aacute;b&oacute;l
      kezelheti a web&aacute;ruh&aacute;zban regisztr&aacute;l&oacute;,
      v&aacute;s&aacute;rl&oacute; azonos&iacute;t&aacute;s&aacute;hoz
      sz&uuml;ks&eacute;ges term&eacute;szetes
      szem&eacute;lyazonos&iacute;t&oacute; adatokat &eacute;s lakc&iacute;met
      a 2001. &eacute;vi CVIII. t&ouml;rv&eacute;ny 13/A. &sect; (1)
      bekezd&eacute;s jogc&iacute;m&eacute;n, tov&aacute;bb&aacute;
      hozz&aacute;j&aacute;rul&aacute;s jogc&iacute;m&eacute;n a
      telefonsz&aacute;m&aacute;t, e-mail c&iacute;m&eacute;t,
      banksz&aacute;mlasz&aacute;m&aacute;t, online
      azonos&iacute;t&oacute;j&aacute;t.
    </p>
    <p>&nbsp;</p>
    <p>
      (3) A T&aacute;rsas&aacute;g sz&aacute;ml&aacute;z&aacute;s
      c&eacute;lj&aacute;b&oacute;l kezelheti az inform&aacute;ci&oacute;s
      t&aacute;rsadalommal &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;s
      ig&eacute;nybev&eacute;tel&eacute;vel kapcsolatos term&eacute;szetes
      szem&eacute;lyazonos&iacute;t&oacute; adatokat, lakc&iacute;met,
      sz&aacute;ll&iacute;t&aacute;si c&iacute;met, valamint a
      szolg&aacute;ltat&aacute;s ig&eacute;nybev&eacute;tel&eacute;nek
      időpontj&aacute;ra, időtartam&aacute;ra &eacute;s hely&eacute;re
      vonatkoz&oacute; adatokat, a 2001. &eacute;vi CVIII. t&ouml;rv&eacute;ny
      13/A.&sect; (2) bekezd&eacute;s jogc&iacute;m&eacute;n.
    </p>
    <p>&nbsp;</p>
    <p>
      (4) A szem&eacute;lyes adatok c&iacute;mzettjei, illetve a
      c&iacute;mzettek kateg&oacute;ri&aacute;i: a T&aacute;rsas&aacute;g
      &uuml;gyf&eacute;lszolg&aacute;lattal, p&eacute;nz&uuml;ggyel,
      sz&aacute;ll&iacute;t&aacute;ssal,&nbsp; marketing
      tev&eacute;kenys&eacute;g&eacute;vel&nbsp; kapcsolatos feladatokat
      ell&aacute;t&oacute; munkav&aacute;llal&oacute;i,
      adatfeldolgoz&oacute;k&eacute;nt, a T&aacute;rsas&aacute;g
      ad&oacute;z&aacute;si, k&ouml;nyvviteli feladatait ell&aacute;t&oacute;
      v&aacute;llalkoz&aacute;s munkav&aacute;llal&oacute;i, az ad&oacute;
      &eacute;s sz&aacute;mviteli k&ouml;telezetts&eacute;gek
      teljes&iacute;t&eacute;se c&eacute;lj&aacute;b&oacute;l, a
      T&aacute;rsas&aacute;g IT szolg&aacute;ltat&oacute;ja
      munkav&aacute;llal&oacute;i a t&aacute;rhelyszolg&aacute;ltat&aacute;s
      teljes&iacute;t&eacute;se c&eacute;lj&aacute;b&oacute;l, a
      fut&aacute;rszolg&aacute;lat munkav&aacute;llal&oacute;i a
      sz&aacute;ll&iacute;t&aacute;si adatok (n&eacute;v, c&iacute;m,
      telefonsz&aacute;m) vonatkoz&aacute;s&aacute;ban.
    </p>
    <p>&nbsp;</p>
    <p>
      (5) A szem&eacute;lyes adatok kezel&eacute;s&eacute;nek időtartama: a
      regisztr&aacute;ci&oacute; / szolg&aacute;ltat&aacute;s
      fenn&aacute;ll&aacute;s&aacute;ig, vagy az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;sa visszavon&aacute;s&aacute;ig
      (t&ouml;rl&eacute;si k&eacute;relm&eacute;ig),
      v&aacute;s&aacute;rl&aacute;s eset&eacute;n a
      v&aacute;s&aacute;rl&aacute;s &eacute;v&eacute;t k&ouml;vető &eacute;v
      8. &eacute;v v&eacute;g&eacute;ig.
    </p>
    <p>&nbsp;</p>
    <p>
      (6) A web&aacute;ruh&aacute;zban t&ouml;rt&eacute;nő
      v&aacute;s&aacute;rl&aacute;s sor&aacute;n az Adatkezel&eacute;si
      t&aacute;j&eacute;koztat&oacute;t (<strong>2. mell&eacute;klet</strong>)
      egy linkkel el&eacute;rhetőv&eacute; kell tenni, a
      v&aacute;s&aacute;rl&oacute;nak azt el kell fogadnia.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        H&iacute;rlev&eacute;l szolg&aacute;ltat&aacute;shoz
        kapcsol&oacute;d&oacute; adatkezel&eacute;s{" "}
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) A honlapon a h&iacute;rlev&eacute;l szolg&aacute;ltat&aacute;sra
      regisztr&aacute;l&oacute;, jogi szem&eacute;ly
      k&eacute;pviselet&eacute;ben elj&aacute;r&oacute; term&eacute;szetes
      szem&eacute;ly az erre vonatkoz&oacute; n&eacute;gyzet
      bejel&ouml;l&eacute;s&eacute;vel tudom&aacute;sul veszi, hogy
      h&iacute;rlev&eacute;lk&uuml;ld&eacute;s, marketing c&eacute;l&uacute;
      megkeres&eacute;s, t&aacute;j&eacute;koztat&oacute; anyagok
      k&uuml;ld&eacute;se c&eacute;lj&aacute;b&oacute;l a
      T&aacute;rsas&aacute;g az adatkezelő jogos (egy&eacute;b
      &uuml;zletszerz&eacute;si) &eacute;rdeke, valamint &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;sa alapj&aacute;n a
      szolg&aacute;ltat&aacute;s akt&iacute;v
      fenn&aacute;ll&aacute;s&aacute;ig, vagy az arra vonatkoz&oacute;
      t&ouml;rl&eacute;si k&eacute;relem (emailben megk&uuml;ld&ouml;tt
      leiratkoz&aacute;si k&eacute;relem) be&eacute;rkez&eacute;s&eacute;ig
      kezeli adatait. Tilos a n&eacute;gyzet előre bejel&ouml;l&eacute;se. A
      feliratkoz&aacute;s sor&aacute;n az Adatkezel&eacute;si
      t&aacute;j&eacute;koztat&oacute;t (2. mell&eacute;klet) egy linkkel
      el&eacute;rhetőv&eacute; kell tenni. A h&iacute;rlev&eacute;ről az
      &eacute;rintett &iacute;r&aacute;sban vagy e-mailben tett nyilatkozattal
      b&aacute;rmikor leiratkozhat. Ilyen esetben a tiltakoz&oacute; minden
      adat&aacute;t halad&eacute;ktalanul t&ouml;r&ouml;lni kell.
    </p>
    <p>&nbsp;</p>
    <p>
      (2) A kezelhető szem&eacute;lyes adatok k&ouml;re: a term&eacute;szetes
      szem&eacute;ly neve (vezet&eacute;kn&eacute;v, keresztn&eacute;v),
      e-mail c&iacute;me
    </p>
    <p>&nbsp;</p>
    <p>
      (3) A szem&eacute;lyes adatok kezel&eacute;s&eacute;nek c&eacute;lja:
    </p>
    <ol>
      <li>
        H&iacute;rlev&eacute;l k&uuml;ld&eacute;se a T&aacute;rsas&aacute;g
        term&eacute;kei, szolg&aacute;ltat&aacute;sai t&aacute;rgy&aacute;ban
      </li>
      <li>
        Rekl&aacute;manyag, t&aacute;j&eacute;koztat&oacute; anyag
        k&uuml;ld&eacute;se
      </li>
      <li>Marketing c&eacute;l&uacute; megkeres&eacute;s</li>
    </ol>
    <p>&nbsp;</p>
    <p>
      (4) Az adatkezel&eacute;s jogalapja: &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;sa, adatkezelő jogos &eacute;rdeke. Az
      ar&aacute;nyoss&aacute;gi teszt alapj&aacute;n a T&aacute;rsas&aacute;g
      k&ouml;zvetlen &uuml;zletszerz&eacute;si &eacute;rdeke (GDPR Preambulum
      47.) tekintettel arra, hogy gazdas&aacute;gi
      t&aacute;rsas&aacute;gr&oacute;l van sz&oacute; nagyobb
      m&eacute;rt&eacute;kű, mint a kock&aacute;zat melyet a
      h&iacute;rlev&eacute;l szolg&aacute;ltat&aacute;shoz
      kapcsol&oacute;d&oacute; szem&eacute;lyes adatok kezel&eacute;se jelent.
    </p>
    <p>&nbsp;</p>
    <p>
      (5) A szem&eacute;lyes adatok c&iacute;mzettjei, illetve a
      c&iacute;mzettek kateg&oacute;ri&aacute;i: a T&aacute;rsas&aacute;g
      &uuml;gyf&eacute;lszolg&aacute;lattal, marketing
      tev&eacute;kenys&eacute;g&eacute;vel kapcsolatos feladatokat
      ell&aacute;t&oacute; munkav&aacute;llal&oacute;i,
      adatfeldolgoz&oacute;k&eacute;nt a T&aacute;rsas&aacute;g
      h&iacute;rlev&eacute;lk&uuml;ldő &eacute;s IT
      szolg&aacute;ltat&oacute;ja munkav&aacute;llal&oacute;i a
      t&aacute;rhelyszolg&aacute;ltat&aacute;s teljes&iacute;t&eacute;se
      c&eacute;lj&aacute;b&oacute;l,
    </p>
    <p>&nbsp;</p>
    <p>
      (6) A szem&eacute;lyes adatok t&aacute;rol&aacute;s&aacute;nak
      időtartama: a h&iacute;rlev&eacute;l-szolg&aacute;ltat&aacute;s
      fenn&aacute;ll&aacute;s&aacute;ig, vagy az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;sa visszavon&aacute;s&aacute;ig
      (t&ouml;rl&eacute;si k&eacute;relm&eacute;ig).
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Adatkezel&eacute;s szoci&aacute;lis m&eacute;dia (Facebook)
        vonatkoz&aacute;s&aacute;ban
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) Az Adatkezelő saj&aacute;t oldalt&aacute;t kezeli a facebookon
      &eacute;s Instagramon. A facebook oldal &uuml;zenőfal&aacute;n
      k&ouml;zz&eacute;tett h&iacute;rfolyamokra az oldalakon
      tal&aacute;lhat&oacute; &bdquo;like&rdquo; vagy &bdquo;tetszik&rdquo;
      linkre kattintva iratkozhat fel az &eacute;rintett.
    </p>
    <p>&nbsp;</p>
    <p>
      (2) Adatkezel&eacute;s c&eacute;lja: t&aacute;j&eacute;koztat&aacute;s
      az aktu&aacute;lis inform&aacute;ci&oacute;kr&oacute;l, az Adatkezelőt
      &eacute;rintő h&iacute;rekről
    </p>
    <p>&nbsp;</p>
    <p>
      (3) Adatkezel&eacute;s jogalapja: az &eacute;rintett
      &ouml;nk&eacute;ntes hozz&aacute;j&aacute;rul&aacute;sa (a Facebook
      adatkezel&eacute;si szab&aacute;lyzat&aacute;val &ouml;sszhangban)
    </p>
    <p>&nbsp;</p>
    <p>
      (4) Adatkezel&eacute;ssel &eacute;rintett adatok k&ouml;re: az
      &eacute;rintett neve; &eacute;rintettek: a szoci&aacute;lis m&eacute;dia
      platform felhaszn&aacute;l&oacute;i
    </p>
    <p>&nbsp;</p>
    <p>
      (5) Adatkezel&eacute;s időtartama: Az &eacute;rintett a
      &bdquo;dislike&rdquo; vagy &rdquo;nem tetszik&rdquo; gombra kattintva
      iratkozhat le az Adatkezelő facebook oldal&aacute;nak a
      k&ouml;vet&eacute;s&eacute;ről, vagy az &uuml;zenőfal
      be&aacute;ll&iacute;t&aacute;sai seg&iacute;ts&eacute;g&eacute;vel
      t&ouml;r&ouml;lheti a nem k&iacute;v&aacute;nt, &uuml;zenő falon
      megjelenő h&iacute;reket.
    </p>
    <p>&nbsp;</p>
    <p>
      (6) C&iacute;mzettek: Az adatkezelő
      &uuml;gyf&eacute;lszolg&aacute;lattal kapcsolatos feladatokat
      ell&aacute;t&oacute; munkav&aacute;llal&oacute;i,
      adatfeldolgoz&oacute;k&eacute;nt a T&aacute;rsas&aacute;g IT
      szolg&aacute;ltat&oacute;ja szerverszolg&aacute;ltat&aacute;st
      v&eacute;gző munkav&aacute;llal&oacute;i
    </p>
    <p>&nbsp;</p>
    <p>
      (7) adatkezel&eacute;s időtartama: a szolg&aacute;ltat&aacute;s
      akt&iacute;v fenn&aacute;ll&aacute;sa
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Aj&aacute;nd&eacute;ksorsol&aacute;s szervez&eacute;s&eacute;vel
        kapcsolatos adatkezel&eacute;s{" "}
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) Ha a t&aacute;rsas&aacute;g aj&aacute;nd&eacute;ksorsol&aacute;st
      (1991. &eacute;vi XXXIV. t&ouml;rv&eacute;ny 23.&sect;) szervez,
      hozz&aacute;j&aacute;rul&aacute;sa alapj&aacute;n kezelheti az
      &eacute;rintett term&eacute;szetes szem&eacute;ly nev&eacute;t,
      c&iacute;m&eacute;t, telefonsz&aacute;m&aacute;t, e-mail
      c&iacute;m&eacute;t, online azonos&iacute;t&oacute;j&aacute;t,
      ad&oacute;azonos&iacute;t&oacute; jel&eacute;t. A j&aacute;t&eacute;kban
      val&oacute; r&eacute;szv&eacute;tel &ouml;nk&eacute;ntes.&nbsp; Az
      adatkezel&eacute;si hozz&aacute;j&aacute;rul&aacute;s a jelen
      szab&aacute;lyzat 1. sz&aacute;m&uacute; mell&eacute;klete szerinti
      adatk&eacute;rő lap szerinti tartalommal k&eacute;rhető.
    </p>
    <p>&nbsp;</p>
    <p>
      (2) A szem&eacute;lyes adatok kezel&eacute;s&eacute;nek c&eacute;lja:
      nyerem&eacute;nyj&aacute;t&eacute;k nyertes&eacute;nek
      meg&aacute;llap&iacute;t&aacute;sa, &eacute;rtes&iacute;t&eacute;se, a
      nyerem&eacute;ny megk&uuml;ld&eacute;se, marketing c&eacute;l&uacute;
      megkeres&eacute;s, t&aacute;j&eacute;koztat&oacute; anyagok
      k&uuml;ld&eacute;se. Az adatkezel&eacute;s jogalapja: az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;sa.
    </p>
    <p>&nbsp;</p>
    <p>
      (3) A szem&eacute;lyes adatok c&iacute;mzettjei, illetve a
      c&iacute;mzettek kateg&oacute;ri&aacute;i: a v&aacute;llalkoz&aacute;s
      marketinggel, &uuml;gyf&eacute;lszolg&aacute;lattal kapcsolatos
      feladatokat ell&aacute;t&oacute; munkav&aacute;llal&oacute;i,
      adatfeldolgoz&oacute;k&eacute;nt a T&aacute;rsas&aacute;g IT
      szolg&aacute;ltat&oacute;ja, k&ouml;nyvvitelt v&eacute;gző
      munkav&aacute;llal&oacute;i, fut&aacute;rszolg&aacute;lat
      munkav&aacute;llal&oacute;i tov&aacute;bb&aacute; a
      t&aacute;rsas&aacute;g t&ouml;bbi adatkezel&eacute;si
      szab&aacute;lyzatban megnevezett adatfeldolgoz&oacute;ja. Kifejezett
      hozz&aacute;j&aacute;rul&aacute;s mellett a nyertesek nev&eacute;t a
      t&aacute;rsas&aacute;g a Facebook, Instagram oldal&aacute;n
      felt&uuml;ntetheti felh&iacute;vva az &eacute;rintettek figyelm&eacute;t
      arra az adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute;ban, hogy a
      h&iacute;rt m&aacute;sok megoszthatj&aacute;k. A
      k&ouml;z&ouml;ss&eacute;gi oldalakon a r&aacute;utal&oacute;
      magatart&aacute;s hozz&aacute;j&aacute;rul&aacute;snak tekinthető.
    </p>
    <p>&nbsp;</p>
    <p>
      (4) A szem&eacute;lyes adatok t&aacute;rol&aacute;s&aacute;nak
      időtartama: 5 &eacute;v, nyertes adatai eset&eacute;n 8 &eacute;v a
      sz&aacute;mviteli iratok megőrz&eacute;se c&eacute;lj&aacute;b&oacute;l.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong>
        Adatkezel&eacute;s ad&oacute;- &eacute;s sz&aacute;mviteli
        k&ouml;telezetts&eacute;gek teljes&iacute;t&eacute;se
        c&eacute;lj&aacute;b&oacute;l{" "}
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) A T&aacute;rsas&aacute;g jogi k&ouml;telezetts&eacute;g
      teljes&iacute;t&eacute;se jogc&iacute;m&eacute;n, t&ouml;rv&eacute;nyben
      elő&iacute;rt ad&oacute; &eacute;s sz&aacute;mviteli
      k&ouml;telezetts&eacute;gek teljes&iacute;t&eacute;se
      (k&ouml;nyvel&eacute;s, ad&oacute;z&aacute;s)
      c&eacute;lj&aacute;b&oacute;l kezeli a vele kapcsolatba l&eacute;pő
      term&eacute;szetes szem&eacute;lyek t&ouml;rv&eacute;nyben
      meghat&aacute;rozott adatait. A kezelt adatok az &aacute;ltal&aacute;nos
      forgalmi ad&oacute;r&oacute;l sz&oacute;l&oacute; 2017. &eacute;vi
      CXXVII. tv. 169.&sect;, &eacute;s 202.&sect;-a alapj&aacute;n
      k&uuml;l&ouml;n&ouml;sen: ad&oacute;sz&aacute;m, n&eacute;v, c&iacute;m,
      ad&oacute;z&aacute;si st&aacute;tusz, a sz&aacute;mvitelről
      sz&oacute;l&oacute; 2000. &eacute;vi C. t&ouml;rv&eacute;ny 167.&sect;-a
      alapj&aacute;n: n&eacute;v, c&iacute;m, a gazdas&aacute;gi műveletet
      elrendelő szem&eacute;ly vagy szervezet megjel&ouml;l&eacute;se, az
      utalv&aacute;nyoz&oacute; &eacute;s a rendelkez&eacute;s
      v&eacute;grehajt&aacute;s&aacute;t igazol&oacute; szem&eacute;ly,
      valamint a szervezettől f&uuml;ggően az ellenőr
      al&aacute;&iacute;r&aacute;sa; a k&eacute;szletmozg&aacute;sok
      bizonylatain &eacute;s a p&eacute;nzkezel&eacute;si bizonylatokon az
      &aacute;tvevő, az ellennyugt&aacute;kon a befizető
      al&aacute;&iacute;r&aacute;sa, a szem&eacute;lyi
      j&ouml;vedelemad&oacute;r&oacute;l sz&oacute;l&oacute; 1995. &eacute;vi
      CXVII. t&ouml;rv&eacute;ny alapj&aacute;n:
      ad&oacute;azonos&iacute;t&oacute; jel.
    </p>
    <p>&nbsp;</p>
    <p>
      (2) &Uacute;tnyilv&aacute;ntart&aacute;s, menetlev&eacute;l
      vezet&eacute;s&eacute;hez kapcsol&oacute;d&oacute; adatkezel&eacute;s: a
      T&aacute;rsas&aacute;g jogi k&ouml;telezetts&eacute;g
      jogc&iacute;m&eacute;n, k&ouml;lts&eacute;gelsz&aacute;mol&aacute;s,
      bizonylatol&aacute;s, ad&oacute;alapok
      meg&aacute;llap&iacute;t&aacute;sa,
      &uuml;zemanyagmegtakar&iacute;t&aacute;s elsz&aacute;mol&aacute;sa
      c&eacute;lj&aacute;b&oacute;l kezeli a c&eacute;ges &eacute;s a
      foglalkoztatott hivatali, &uuml;zleti c&eacute;lra haszn&aacute;lt
      saj&aacute;t g&eacute;pj&aacute;rműhaszn&aacute;lat&aacute;nak
      t&ouml;rv&eacute;nyben meghat&aacute;rozott adatait
      (g&eacute;pkocsivezető neve, a g&eacute;pj&aacute;rmű t&iacute;pusa,
      rendsz&aacute;ma, utaz&aacute;s időpontja, c&eacute;lja, megtett
      &uacute;tvonal, felkeresett &uuml;zleti partner neve). A
      vonatkoz&oacute; jogszab&aacute;ly 1995:CXVII. tv. (Szja tv.)
      27.&sect;/2/, 3. Mell&eacute;klet 6. &eacute;s 5. Mell&eacute;klet 7.
      pont.
    </p>
    <p>&nbsp;</p>
    <p>
      (3) A szem&eacute;lyes adatok t&aacute;rol&aacute;s&aacute;nak
      időtartama a jogalapot ad&oacute; jogviszony megszűn&eacute;s&eacute;t
      k&ouml;vető 8 &eacute;v.
    </p>
    <p>&nbsp;</p>
    <p>
      (4) A szem&eacute;lyes adatok c&iacute;mzettjei: a
      T&aacute;rsas&aacute;g ad&oacute;z&aacute;si, k&ouml;nyvviteli,
      b&eacute;rsz&aacute;mfejt&eacute;si,
      t&aacute;rsadalombiztos&iacute;t&aacute;si feladatait
      ell&aacute;t&oacute; munkav&aacute;llal&oacute;i &eacute;s
      adatfeldolgoz&oacute;i.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Kifizetői adatkezel&eacute;s </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) A T&aacute;rsas&aacute;g jogi k&ouml;telezetts&eacute;g
      teljes&iacute;t&eacute;se jogc&iacute;m&eacute;n, t&ouml;rv&eacute;nyben
      elő&iacute;rt ad&oacute; &eacute;s
      j&aacute;rul&eacute;kk&ouml;telezetts&eacute;gek
      teljes&iacute;t&eacute;se (ad&oacute;-, ad&oacute;előleg,
      j&aacute;rul&eacute;kok meg&aacute;llap&iacute;t&aacute;sa,
      b&eacute;rsz&aacute;mfejt&eacute;s,
      t&aacute;rsadalombiztos&iacute;t&aacute;si, nyugd&iacute;j
      &uuml;gyint&eacute;z&eacute;s) c&eacute;lj&aacute;b&oacute;l kezeli azon
      &eacute;rintettek &ndash; munkav&aacute;llal&oacute;k,
      csal&aacute;dtagjaik, foglalkoztatottak, egy&eacute;b juttat&aacute;sban
      r&eacute;szes&uuml;lők &ndash; ad&oacute;t&ouml;rv&eacute;nyekben
      elő&iacute;rt szem&eacute;lyes adatait, akikkel kifizetői (2017:CL.
      t&ouml;rv&eacute;ny az ad&oacute;z&aacute;s rendj&eacute;ről (Art.)
      7.&sect; 31.) kapcsolatban &aacute;ll. A kezelt adatok k&ouml;r&eacute;t
      az Art. 50.&sect;-a hat&aacute;rozza meg, k&uuml;l&ouml;n is kiemelve
      ebből:&nbsp;a term&eacute;szetes szem&eacute;ly term&eacute;szetes
      szem&eacute;lyazonos&iacute;t&oacute; adatait (ide&eacute;rtve az előző
      nevet &eacute;s a titulust is), nem&eacute;t,
      &aacute;llampolg&aacute;rs&aacute;g&aacute;t, a term&eacute;szetes
      szem&eacute;ly ad&oacute;azonos&iacute;t&oacute; jel&eacute;t,
      t&aacute;rsadalombiztos&iacute;t&aacute;si azonos&iacute;t&oacute;
      jel&eacute;t (TAJ sz&aacute;m). Amennyiben az
      ad&oacute;t&ouml;rv&eacute;nyek ehhez jogk&ouml;vetkezm&eacute;nyt
      fűznek, a T&aacute;rsas&aacute;g kezelheti a munkav&aacute;llal&oacute;k
      eg&eacute;szs&eacute;g&uuml;gyi (Szja tv. 40.&sect;) &eacute;s
      szakszervezeti (Szja 47.&sect;(2) b./) tags&aacute;gra vonatkoz&oacute;
      adatokat ad&oacute; &eacute;s
      j&aacute;rul&eacute;kk&ouml;telezetts&eacute;gek
      teljes&iacute;t&eacute;se (b&eacute;rsz&aacute;mfejt&eacute;s,
      t&aacute;rsadalombiztos&iacute;t&aacute;si
      &uuml;gyint&eacute;z&eacute;s) c&eacute;lj&aacute;b&oacute;l.
    </p>
    <p>&nbsp;</p>
    <p>
      (2) A szem&eacute;lyes adatok t&aacute;rol&aacute;s&aacute;nak
      időtartama a jogalapot ad&oacute; jogviszony megszűn&eacute;s&eacute;t
      k&ouml;vető 8 &eacute;v.
    </p>
    <p>&nbsp;</p>
    <p>
      (3) A szem&eacute;lyes adatok c&iacute;mzettjei: a
      T&aacute;rsas&aacute;g ad&oacute;z&aacute;si,
      b&eacute;rsz&aacute;mfejt&eacute;si,
      t&aacute;rsadalombiztos&iacute;t&aacute;si (kifizetői) feladatait
      ell&aacute;t&oacute; munkav&aacute;llal&oacute;i &eacute;s
      adatfeldolgoz&oacute;i.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        A Lev&eacute;lt&aacute;ri t&ouml;rv&eacute;ny szerint maradand&oacute;
        &eacute;rt&eacute;kű iratokra vonatkoz&oacute; adatkezel&eacute;s{" "}
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      (1) A T&aacute;rsas&aacute;g jogi k&ouml;telezetts&eacute;ge
      teljes&iacute;t&eacute;se jogc&iacute;m&eacute;n kezeli a
      k&ouml;ziratokr&oacute;l, a k&ouml;zlev&eacute;lt&aacute;rakr&oacute;l
      &eacute;s a mag&aacute;nlev&eacute;lt&aacute;ri anyag
      v&eacute;delm&eacute;ről sz&oacute;l&oacute; 1995. &eacute;vi LXVI.
      t&ouml;rv&eacute;ny (Lev&eacute;lt&aacute;ri t&ouml;rv&eacute;ny)
      szerint maradand&oacute; &eacute;rt&eacute;kűnek minős&uuml;lő iratait
      abb&oacute;l a c&eacute;lb&oacute;l, hogy a T&aacute;rsas&aacute;g
      iratt&aacute;ri anyag&aacute;nak maradand&oacute; &eacute;rt&eacute;kű
      r&eacute;sze &eacute;ps&eacute;gben &eacute;s haszn&aacute;lhat&oacute;
      &aacute;llapotban a j&ouml;vő nemzed&eacute;kei sz&aacute;m&aacute;ra is
      fennmaradjon. Az adatt&aacute;rol&aacute;s ideje: a
      k&ouml;zlev&eacute;lt&aacute;r r&eacute;sz&eacute;re t&ouml;rt&eacute;nő
      &aacute;tad&aacute;sig.
    </p>
    <p>&nbsp;</p>
    <p>
      (2) A szem&eacute;lyes adatok c&iacute;mzettjei: a
      T&aacute;rsas&aacute;g vezetője, iratkezel&eacute;st,
      iratt&aacute;roz&aacute;st v&eacute;gző munkav&aacute;llal&oacute;ja, a
      k&ouml;zlev&eacute;lt&aacute;r munkat&aacute;rsa.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong> FEJEZET</strong>
      </li>
    </ol>
    <p>
      <strong>
        L&Aacute;TOGAT&Oacute;I ADATKEZEL&Eacute;S A T&Aacute;RSAS&Aacute;G
        HONLAPJ&Aacute;N -{" "}
      </strong>
      <strong>
        T&Aacute;J&Eacute;KOZTAT&Aacute;S S&Uuml;TIK (COOKIE)
        ALKALMAZ&Aacute;S&Aacute;R&Oacute;L
      </strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        A honlapra l&aacute;togat&oacute;t a honlapon
        t&aacute;j&eacute;koztatni kell a s&uuml;tik
        alkalmaz&aacute;s&aacute;r&oacute;l, &eacute;s ehhez &ndash; a
        technikailag elengedhetetlen&uuml;l sz&uuml;ks&eacute;ges{" "}
        <strong>
          munkamenet (session) s&uuml;tik kiv&eacute;tel&eacute;vel &ndash;{" "}
        </strong>
        a hozz&aacute;j&aacute;rul&aacute;s&aacute;t kell k&eacute;rni.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      A cookie (s&uuml;ti) egy olyan kism&eacute;retű sz&ouml;veges
      f&aacute;jl, amely az &eacute;rintett
      sz&aacute;m&iacute;t&oacute;g&eacute;pe vagy a mobil eszk&ouml;ze
      hossz&uacute; t&aacute;v&uacute; adatt&aacute;rol&oacute;j&aacute;ban
      (HDD, SSD) r&ouml;gz&uuml;l a cookie-ban be&aacute;ll&iacute;tott
      lej&aacute;rati ideig, &eacute;s a k&eacute;sőbbi
      l&aacute;togat&aacute;sokkor &uacute;jra aktiv&aacute;l&oacute;dik.
      C&eacute;lja, hogy r&ouml;gz&iacute;tse a l&aacute;togat&aacute;ssal
      kapcsolatos adatokat, illetve a szem&eacute;lyes
      be&aacute;ll&iacute;t&aacute;sokat, ezek azonban a
      l&aacute;togat&oacute; szem&eacute;ly&eacute;vel kapcsolatba nem
      hozhat&oacute; adatok. Seg&iacute;t a
      felhaszn&aacute;l&oacute;bar&aacute;t weboldal
      kialak&iacute;t&aacute;s&aacute;ban, valamint az &Eacute;rintett online
      &eacute;lm&eacute;ny&eacute;nek fokoz&aacute;sa &eacute;rdek&eacute;ben.
      Ha az &Eacute;rintett nem egyezik bele, hogy az Adatfeldolgoz&oacute;
      cookie-kat haszn&aacute;ljon, akkor a weboldal haszn&aacute;lat&aacute;t
      meg kell szak&iacute;tania.
    </p>
    <p>&nbsp;</p>
    <p>
      Adatkezel&eacute;s c&eacute;lja: felhaszn&aacute;l&oacute;i
      &eacute;lm&eacute;ny jav&iacute;t&aacute;sa, az &eacute;rintett
      szem&eacute;lyes be&aacute;ll&iacute;t&aacute;sainak a
      t&aacute;rol&aacute;sa
    </p>
    <p>&nbsp;</p>
    <p>
      Adatkezel&eacute;s jogalapja: az &eacute;rintett &ouml;nk&eacute;ntes
      hozz&aacute;j&aacute;rul&aacute;sa
    </p>
    <p>&nbsp;</p>
    <p>
      Adatkezel&eacute;ssel &eacute;rintett adatok k&ouml;re: Adatkezelő
      minden elemz&eacute;si inform&aacute;ci&oacute;t n&eacute;v, vagy
      m&aacute;s szem&eacute;lyes adat n&eacute;lk&uuml;l t&aacute;rol
    </p>
    <p>&nbsp;</p>
    <p>
      Adatkezel&eacute;s időtartama: az &eacute;rintett a
      b&ouml;ng&eacute;szője be&aacute;ll&iacute;t&aacute;sain kereszt&uuml;l
      b&aacute;rmikor t&ouml;r&ouml;lheti a
      sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;n vagy
      mobiltelefonj&aacute;n elt&aacute;rolt s&uuml;tiket
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="2">
      <li>
        <strong>
          {" "}
          R&eacute;szletes t&aacute;j&eacute;koztat&aacute;s a s&uuml;tikről{" "}
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      2.1. A s&uuml;ti (angolul &bdquo;cookie&rdquo;) egy olyan adat, amit a
      megl&aacute;togatott weboldal k&uuml;ld a l&aacute;togat&oacute;
      b&ouml;ng&eacute;szőj&eacute;nek
      (v&aacute;ltoz&oacute;n&eacute;v-&eacute;rt&eacute;k form&aacute;ban),
      hogy az elt&aacute;rolja &eacute;s k&eacute;sőbb ugyanaz a weboldal be
      is tudja t&ouml;lteni a tartalm&aacute;t. A s&uuml;tinek lehet
      &eacute;rv&eacute;nyess&eacute;ge, &eacute;rv&eacute;nyes lehet a
      b&ouml;ng&eacute;sző bez&aacute;r&aacute;s&aacute;ig, de
      korl&aacute;tlan ideig is.&nbsp; A k&eacute;sőbbiekben minden HTTP(S)
      k&eacute;r&eacute;sn&eacute;l ezeket az adatokat is elk&uuml;ldi a
      b&ouml;ng&eacute;sző a szervernek. Ez&aacute;ltal a
      felhaszn&aacute;l&oacute; g&eacute;p&eacute;n l&eacute;vő adatokat
      m&oacute;dos&iacute;tja.
    </p>
    <p>&nbsp;</p>
    <p>
      2.2. A modern weboldalszolg&aacute;ltat&aacute;sok
      term&eacute;szet&eacute;n&eacute;l fogva sz&uuml;ks&eacute;g van
      s&uuml;tikre, melynek funkci&oacute;ja, hogy egy
      felhaszn&aacute;l&oacute;t megjel&ouml;lj&ouml;n (p&eacute;ld&aacute;ul,
      hogy bel&eacute;pett az oldalra) &eacute;s annak megfelelően tudja a
      k&ouml;vetkezőkben kezelni, ak&aacute;r k&eacute;sőbbi
      visszat&eacute;r&eacute;sekor azonos&iacute;tani. A vesz&eacute;lye
      abban rejlik, hogy erről a felhaszn&aacute;l&oacute;nak nem minden
      esetben van tudom&aacute;sa &eacute;s alkalmas lehet arra, hogy
      felhaszn&aacute;l&oacute;t k&ouml;vesse a weboldal &uuml;zemeltetője
      vagy m&aacute;s szolg&aacute;ltat&oacute;, akinek a tartalma be van
      &eacute;p&iacute;tve az oldalban (pl. Facebook, Google Analytics),
      ez&aacute;ltal profil j&ouml;n l&eacute;tre r&oacute;la, ebben az
      esetben pedig a s&uuml;ti tartalma szem&eacute;lyes adatnak tekinthető.
    </p>
    <p>&nbsp;</p>
    <p>2.3. A s&uuml;tik fajt&aacute;i:</p>
    <p>
      2.3.1. Technikailag elengedhetetlen&uuml;l sz&uuml;ks&eacute;ges
      munkamenet (session) s&uuml;tik: amelyek n&eacute;lk&uuml;l az oldal
      egyszerűen nem műk&ouml;dne funkcion&aacute;lisan, ezek a
      felhaszn&aacute;l&oacute; azonos&iacute;t&aacute;s&aacute;hoz, pl. annak
      kezel&eacute;s&eacute;hez sz&uuml;ks&eacute;ges, hogy bel&eacute;pett-e,
      mit tett a kos&aacute;rba stb. Ez jellemzően egy session-id
      let&aacute;rol&aacute;sa, a t&ouml;bbi adat a szerveren ker&uuml;l
      t&aacute;rol&aacute;sra, ami &iacute;gy biztons&aacute;gosabb. Van
      biztons&aacute;gi vonatkoz&aacute;sa, ha a session s&uuml;ti
      &eacute;rt&eacute;ke nem j&oacute;l van gener&aacute;lva, akkor
      session-hijacking t&aacute;mad&aacute;s vesz&eacute;lye fenn&aacute;ll,
      ez&eacute;rt felt&eacute;tlen&uuml;l sz&uuml;ks&eacute;ges, hogy
      megfelelően legyenek ezek az &eacute;rt&eacute;kek gener&aacute;lva.
      M&aacute;s terminol&oacute;gi&aacute;k session cookie-nak h&iacute;vnak
      minden s&uuml;tit, amik a b&ouml;ng&eacute;szőből val&oacute;
      kil&eacute;p&eacute;skor t&ouml;rlődnek (egy session egy
      b&ouml;ng&eacute;szőhaszn&aacute;lat az
      elind&iacute;t&aacute;st&oacute;l a kil&eacute;p&eacute;sig).
    </p>
    <p>
      2.3.2. Haszn&aacute;latot előseg&iacute;tő s&uuml;tik: &iacute;gy azokat
      a s&uuml;tiket szokt&aacute;l nevezni, amelyek megjegyzik a
      felhaszn&aacute;l&oacute; v&aacute;laszt&aacute;sait,
      p&eacute;ld&aacute;ul milyen form&aacute;ban szeretn&eacute; a
      felhaszn&aacute;l&oacute; az oldalt l&aacute;tni. Ezek a fajta
      s&uuml;tik l&eacute;nyeg&eacute;ben a s&uuml;tiben t&aacute;rolt
      be&aacute;ll&iacute;t&aacute;si adatokat jelentik.
    </p>
    <p>
      2.3.3. Teljes&iacute;tm&eacute;nyt biztos&iacute;t&oacute; s&uuml;tik:
      b&aacute;r nem sok k&ouml;z&uuml;k van a
      "teljes&iacute;tm&eacute;ny"-hez, &aacute;ltal&aacute;ban &iacute;gy
      nevezik azokat a s&uuml;tiket, amelyek inform&aacute;ci&oacute;kat
      gyűjtenek a felhaszn&aacute;l&oacute;nak a megl&aacute;togatott
      weboldalon bel&uuml;li viselked&eacute;s&eacute;ről, elt&ouml;lt&ouml;tt
      idej&eacute;ről, kattint&aacute;sair&oacute;l. Ezek jellemzően harmadik
      f&eacute;l alkalmaz&aacute;sai (pl. Google Analytics, AdWords, vagy
      Yandex.ru s&uuml;tik). Ezek a l&aacute;togat&oacute;r&oacute;l
      profilalkot&aacute;s k&eacute;sz&iacute;t&eacute;s&eacute;re alkalmasak.
    </p>
    <p>
      A Google Analytics s&uuml;tikről itt t&aacute;j&eacute;koz&oacute;dhat:
    </p>
    <p>
      <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
        https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
      </a>
    </p>
    <p>
      A Google AdWords s&uuml;tikről itt t&aacute;j&eacute;koz&oacute;dhat:
    </p>
    <p>
      <a href="https://support.google.com/adwords/answer/2407785?hl=hu">
        https://support.google.com/adwords/answer/2407785?hl=hu
      </a>
    </p>
    <p>&nbsp;</p>
    <p>
      2.4. A s&uuml;tik haszn&aacute;lat&aacute;t elfogadni, azokat
      enged&eacute;lyezni nem k&ouml;telező.&nbsp;
      Vissza&aacute;ll&iacute;thatja b&ouml;ng&eacute;szője
      be&aacute;ll&iacute;t&aacute;sait, hogy az utas&iacute;tsa el az
      &ouml;sszes cookie-t, vagy hogy jelezze, ha a rendszer &eacute;ppen egy
      cookie-t k&uuml;ld. A legt&ouml;bb b&ouml;ng&eacute;sző ugyan
      alap&eacute;rtelmezettk&eacute;nt automatikusan elfogadja a
      s&uuml;tiket, de ezek &aacute;ltal&aacute;ban
      megv&aacute;ltoztathat&oacute;ak annak &eacute;rdek&eacute;ben, hogy
      megakad&aacute;lyozhat&oacute; legyen az automatikus elfogad&aacute;s.
    </p>
    <p>
      <strong>
        A legn&eacute;pszerűbb b&ouml;ng&eacute;szők s&uuml;ti
        be&aacute;ll&iacute;t&aacute;sair&oacute;l az al&aacute;bbi linkeken
        t&aacute;j&eacute;koz&oacute;dhat
      </strong>
      <br /> &bull; Google Chrome:&nbsp;
      <a href="https://support.google.com/accounts/answer/61416?hl=hu">
        https://support.google.com/accounts/answer/61416?hl=hu
      </a>
      <br /> &bull; Firefox:&nbsp;
      <a href="https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn">
        https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn
      </a>
      <br /> &bull; Microsoft Internet Explorer 11:&nbsp;
      <a href="http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-11">
        http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-11
      </a>
      <br /> &bull; Microsoft Internet Explorer 10:&nbsp;
      <a href="http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-10-win-7">
        http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-10-win-7
      </a>
      <br /> &bull; Microsoft Internet Explorer 9:&nbsp;
      <a href="http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-9">
        http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-9
      </a>
      <br /> &bull; Microsoft Internet Explorer 8:&nbsp;
      <a href="http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-8">
        http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-8
      </a>
      <br /> &bull; Microsoft Edge:&nbsp;
      <a href="http://windows.microsoft.com/hu-hu/windows-10/edge-privacy-faq">
        http://windows.microsoft.com/hu-hu/windows-10/edge-privacy-faq
      </a>
      <br /> &bull; Safari:&nbsp;
      <a href="https://support.apple.com/hu-hu/HT201265">
        https://support.apple.com/hu-hu/HT201265
      </a>
    </p>
    <p>
      Mindezek mellett azonban felh&iacute;vjuk a figyelmet arra, hogy
      előfordulhat, hogy bizonyos webhelyfunkci&oacute;k vagy
      -szolg&aacute;ltat&aacute;sok nem fognak megfelelően műk&ouml;dni
      cookie-k n&eacute;lk&uuml;l.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="3">
      <li>
        T&aacute;j&eacute;koztat&aacute;s a T&aacute;rsas&aacute;g
        honlapj&aacute;n alkalmazott s&uuml;tikről, illetve{" "}
        <em>
          a l&aacute;togat&aacute;s sor&aacute;n l&eacute;trej&ouml;vő
          adatokr&oacute;l
        </em>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      3.1. A l&aacute;togat&aacute;s sor&aacute;n kezelt adatk&ouml;r:
      T&aacute;rsas&aacute;gunk honlapja a weboldal haszn&aacute;lata
      sor&aacute;n a l&aacute;togat&oacute;r&oacute;l, illetve az
      &aacute;ltala b&ouml;ng&eacute;sz&eacute;sre haszn&aacute;lt
      eszk&ouml;zről az al&aacute;bbi adatokat r&ouml;gz&iacute;theti
      &eacute;s kezelheti:
      <br /> &bull; az l&aacute;togat&oacute; &aacute;ltal haszn&aacute;lt IP
      c&iacute;m,
      <br /> &bull; a b&ouml;ng&eacute;sző t&iacute;pusa,
      <br /> &bull; a b&ouml;ng&eacute;sz&eacute;sre haszn&aacute;lt
      eszk&ouml;z oper&aacute;ci&oacute;s rendszer&eacute;nek jellemzői
      (be&aacute;ll&iacute;tott nyelv),
      <br /> &bull; l&aacute;togat&aacute;s időpontja,
      <br /> &bull; a megl&aacute;togatott (al)oldal, funkci&oacute; vagy
      szolg&aacute;ltat&aacute;s.
    </p>
    <ul>
      <li>kattint&aacute;s.</li>
    </ul>
    <p>&nbsp;</p>
    <p>
      <em>
        Ezeket az adatokat maximum 90 napig őrizz&uuml;k meg &eacute;s
        elsősorban biztons&aacute;gi incidensek vizsg&aacute;lat&aacute;hoz
        haszn&aacute;lhatjuk.
      </em>
    </p>
    <p>&nbsp;</p>
    <p>3.2. A honlapon alkalmazott s&uuml;tik</p>
    <p>&nbsp;</p>
    <p>
      <strong>
        3.2.1. Technikailag elengedhetetlen&uuml;l sz&uuml;ks&eacute;ges{" "}
      </strong>
      <strong>munkamenet (session) s&uuml;tik</strong>
    </p>
    <p>
      Az adatkezel&eacute;s c&eacute;lja: a honlap megfelelő
      műk&ouml;d&eacute;s&eacute;nek biztos&iacute;t&aacute;sa. Ezek a
      s&uuml;tik ahhoz sz&uuml;ks&eacute;gesek, hogy a l&aacute;togat&oacute;k
      b&ouml;ng&eacute;szhess&eacute;k a weboldalt, z&ouml;kkenőmentesen
      &eacute;s teljes k&ouml;rűen haszn&aacute;lhass&aacute;k annak
      funkci&oacute;it, a weboldalon kereszt&uuml;l el&eacute;rhető
      szolg&aacute;ltat&aacute;sokat, &iacute;gy - t&ouml;bbek
      k&ouml;z&ouml;tt- k&uuml;l&ouml;n&ouml;sen a l&aacute;togat&oacute;
      &aacute;ltal az adott oldalakon v&eacute;gzett műveletek
      megjegyz&eacute;s&eacute;t{" "}
      <em>
        vagy a bejelentkezett felhaszn&aacute;l&oacute;
        azonos&iacute;t&aacute;s&aacute;t
      </em>{" "}
      egy l&aacute;togat&aacute;s sor&aacute;n. Ezen s&uuml;tik
      adatkezel&eacute;s&eacute;nek időtartama kiz&aacute;r&oacute;lag a
      l&aacute;togat&oacute; aktu&aacute;lis l&aacute;togat&aacute;s&aacute;ra
      vonatkozik, a munkamenet v&eacute;gezt&eacute;vel, illetve a
      b&ouml;ng&eacute;sző bez&aacute;r&aacute;s&aacute;val a s&uuml;tik e
      fajt&aacute;ja automatikusan t&ouml;rlődik a
      sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;ről.
    </p>
    <p>&nbsp;</p>
    <p>
      Ezen adatkezel&eacute;s jogalapja az elektronikus kereskedelmi
      szolg&aacute;ltat&aacute;sok, valamint az inform&aacute;ci&oacute;s
      t&aacute;rsadalmi szolg&aacute;ltat&aacute;sok egyes
      k&eacute;rd&eacute;seiről sz&oacute;l&oacute; 2001. CVIII.
      t&ouml;rv&eacute;ny (Elkertv.) 13/A. &sect; (3) bekezd&eacute;se, amely
      szerint a szolg&aacute;ltat&oacute; a szolg&aacute;ltat&aacute;s
      ny&uacute;jt&aacute;sa c&eacute;lj&aacute;b&oacute;l kezelheti azon
      szem&eacute;lyes adatokat, amelyek a szolg&aacute;ltat&aacute;s
      ny&uacute;jt&aacute;s&aacute;hoz technikailag elengedhetetlen&uuml;l
      sz&uuml;ks&eacute;gesek. A szolg&aacute;ltat&oacute;nak az egy&eacute;b
      felt&eacute;telek azonoss&aacute;ga eset&eacute;n &uacute;gy kell
      megv&aacute;lasztania &eacute;s minden esetben oly m&oacute;don kell
      &uuml;zemeltetnie az inform&aacute;ci&oacute;s t&aacute;rsadalommal
      &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;sa
      sor&aacute;n alkalmazott eszk&ouml;z&ouml;ket, hogy szem&eacute;lyes
      adatok kezel&eacute;s&eacute;re csak akkor ker&uuml;lj&ouml;n sor, ha ez
      a szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;s&aacute;hoz &eacute;s
      az e t&ouml;rv&eacute;nyben meghat&aacute;rozott egy&eacute;b
      c&eacute;lok teljes&uuml;l&eacute;s&eacute;hez felt&eacute;tlen&uuml;l
      sz&uuml;ks&eacute;ges, azonban ebben az esetben is csak a
      sz&uuml;ks&eacute;ges m&eacute;rt&eacute;kben &eacute;s ideig.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>3.2.1. Haszn&aacute;latot előseg&iacute;tő s&uuml;tik:</strong>
    </p>
    <p>&nbsp;</p>
    <p>
      Ezek megjegyzik a felhaszn&aacute;l&oacute; v&aacute;laszt&aacute;sait,
      p&eacute;ld&aacute;ul milyen form&aacute;ban szeretn&eacute; a
      felhaszn&aacute;l&oacute; az oldalt l&aacute;tni. Ezek a fajta
      s&uuml;tik l&eacute;nyeg&eacute;ben a s&uuml;tiben t&aacute;rolt
      be&aacute;ll&iacute;t&aacute;si adatokat jelentik.
    </p>
    <p>
      Az adatkezel&eacute;s jogalapja a l&aacute;togat&oacute;
      hozz&aacute;j&aacute;rul&aacute;sa.
    </p>
    <p>
      <strong>Az adatkezel&eacute;s c&eacute;lja: </strong>A
      szolg&aacute;ltat&aacute;s hat&eacute;konys&aacute;g&aacute;nak
      n&ouml;vel&eacute;se, felhaszn&aacute;l&oacute;i &eacute;lm&eacute;ny
      n&ouml;vel&eacute;se, a honlap haszn&aacute;lat&aacute;nak
      k&eacute;nyelmesebb&eacute; t&eacute;tele.
    </p>
    <p>
      <em>
        Ez az adat jellemzően a felhaszn&aacute;l&oacute; g&eacute;p&eacute;n
        van, a weboldal csak hozz&aacute;f&eacute;r &eacute;s felismer(het)i
        &aacute;ltala a l&aacute;togat&oacute;t.&nbsp;{" "}
      </em>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>
        3.2.2. Teljes&iacute;tm&eacute;nyt biztos&iacute;t&oacute; s&uuml;tik:{" "}
      </strong>
    </p>
    <p>
      Inform&aacute;ci&oacute;kat gyűjtenek a felhaszn&aacute;l&oacute;nak a
      megl&aacute;togatott weboldalon bel&uuml;li
      viselked&eacute;s&eacute;ről, elt&ouml;lt&ouml;tt idej&eacute;ről,
      kattint&aacute;sair&oacute;l.
    </p>
    <p>
      Az adatkezel&eacute;s jogalapja: az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;sa.
    </p>
    <p>
      Az adatkezel&eacute;s c&eacute;lja: a honlap elemz&eacute;se,
      rekl&aacute;maj&aacute;nlatok k&uuml;ld&eacute;se.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong> FEJEZET</strong>
      </li>
    </ol>
    <p>
      <strong>
        T&Aacute;J&Eacute;KOZTAT&Aacute;S AZ &Eacute;RINTETT SZEM&Eacute;LY
        JOGAIR&Oacute;L
      </strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        <strong>
          {" "}
          Az &eacute;rintett jogai r&ouml;viden &ouml;sszefoglalva:
        </strong>
      </li>
      <li>
        &Aacute;tl&aacute;that&oacute; t&aacute;j&eacute;koztat&aacute;s,
        kommunik&aacute;ci&oacute; &eacute;s az &eacute;rintett
        joggyakorl&aacute;s&aacute;nak előseg&iacute;t&eacute;se
      </li>
      <li>
        Előzetes t&aacute;j&eacute;koz&oacute;d&aacute;shoz val&oacute; jog
        &ndash; ha a szem&eacute;lyes adatokat az &eacute;rintettől gyűjtik
      </li>
      <li>
        Az &eacute;rintett t&aacute;j&eacute;koztat&aacute;sa &eacute;s a
        rendelkez&eacute;s&eacute;re bocs&aacute;tand&oacute;
        inform&aacute;ci&oacute;k, ha a szem&eacute;lyes adatokat az
        adatkezelő nem tőle szerezte meg
      </li>
      <li>Az &eacute;rintett hozz&aacute;f&eacute;r&eacute;si joga</li>
      <li>A helyesb&iacute;t&eacute;shez val&oacute; jog</li>
      <li>
        A t&ouml;rl&eacute;shez val&oacute; jog (&bdquo;az
        elfeledtet&eacute;shez val&oacute; jog&rdquo;)
      </li>
      <li>
        Az adatkezel&eacute;s korl&aacute;toz&aacute;s&aacute;hoz val&oacute;
        jog
      </li>
      <li>
        A szem&eacute;lyes adatok helyesb&iacute;t&eacute;s&eacute;hez vagy
        t&ouml;rl&eacute;s&eacute;hez, illetve az adatkezel&eacute;s
        korl&aacute;toz&aacute;s&aacute;hoz kapcsol&oacute;d&oacute;
        &eacute;rtes&iacute;t&eacute;si k&ouml;telezetts&eacute;g
      </li>
      <li>Az adathordozhat&oacute;s&aacute;ghoz val&oacute; jog</li>
      <li>A tiltakoz&aacute;shoz val&oacute; jog</li>
      <li>
        Automatiz&aacute;lt d&ouml;nt&eacute;shozatal egyedi &uuml;gyekben,
        bele&eacute;rtve a profilalkot&aacute;st
      </li>
      <li>Korl&aacute;toz&aacute;sok</li>
      <li>
        Az &eacute;rintett t&aacute;j&eacute;koztat&aacute;sa az
        adatv&eacute;delmi incidensről
      </li>
      <li>
        A fel&uuml;gyeleti hat&oacute;s&aacute;gn&aacute;l t&ouml;rt&eacute;nő
        panaszt&eacute;telhez val&oacute; jog (hat&oacute;s&aacute;gi
        jogorvoslathoz val&oacute; jog)
      </li>
      <li>
        A fel&uuml;gyeleti hat&oacute;s&aacute;ggal szembeni hat&eacute;kony
        b&iacute;r&oacute;s&aacute;gi jogorvoslathoz val&oacute; jog
      </li>
      <li>
        Az adatkezelővel vagy az adatfeldolgoz&oacute;val szembeni
        hat&eacute;kony b&iacute;r&oacute;s&aacute;gi jogorvoslathoz
        val&oacute; jog
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong> Az &eacute;rintett jogai r&eacute;szletesen: </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>
          {" "}
          &Aacute;tl&aacute;that&oacute; t&aacute;j&eacute;koztat&aacute;s,
          kommunik&aacute;ci&oacute; &eacute;s az &eacute;rintett
          joggyakorl&aacute;s&aacute;nak előseg&iacute;t&eacute;se{" "}
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>1.1.</strong> Az adatkezelőnek az &eacute;rintett
      r&eacute;sz&eacute;re a szem&eacute;lyes adatok kezel&eacute;s&eacute;re
      vonatkoz&oacute; valamennyi inform&aacute;ci&oacute;t &eacute;s minden
      egyes t&aacute;j&eacute;koztat&aacute;st t&ouml;m&ouml;r,
      &aacute;tl&aacute;that&oacute;, &eacute;rthető &eacute;s k&ouml;nnyen
      hozz&aacute;f&eacute;rhető form&aacute;ban, vil&aacute;gosan &eacute;s
      k&ouml;z&eacute;rthetően megfogalmazva kell ny&uacute;jtania,
      k&uuml;l&ouml;n&ouml;sen a gyermekeknek c&iacute;mzett b&aacute;rmely
      inform&aacute;ci&oacute; eset&eacute;ben. Az inform&aacute;ci&oacute;kat
      &iacute;r&aacute;sban vagy m&aacute;s m&oacute;don &ndash;
      ide&eacute;rtve adott esetben az elektronikus utat is &ndash; kell
      megadni. Az &eacute;rintett k&eacute;r&eacute;s&eacute;re sz&oacute;beli
      t&aacute;j&eacute;koztat&aacute;s is adhat&oacute;, felt&eacute;ve, hogy
      m&aacute;s m&oacute;don igazolt&aacute;k az &eacute;rintett
      szem&eacute;lyazonoss&aacute;g&aacute;t.
    </p>
    <p>
      <strong>1.2.</strong> Az adatkezelőnek elő kell seg&iacute;tenie az
      &eacute;rintett jogainak a gyakorl&aacute;s&aacute;t.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>1.3.</strong> Az adatkezelő indokolatlan k&eacute;sedelem
      n&eacute;lk&uuml;l, de mindenf&eacute;lek&eacute;ppen a k&eacute;relem
      be&eacute;rkez&eacute;s&eacute;től sz&aacute;m&iacute;tott egy
      h&oacute;napon bel&uuml;l t&aacute;j&eacute;koztatja az
      &eacute;rintettet a jogai gyakorl&aacute;s&aacute;ra
      ir&aacute;nyul&oacute; k&eacute;relme nyom&aacute;n hozott
      int&eacute;zked&eacute;sekről. E hat&aacute;ridő a Rendeletben
      &iacute;rt felt&eacute;telekkel tov&aacute;bbi k&eacute;t
      h&oacute;nappal meghosszabb&iacute;that&oacute;. amelyről az
      &eacute;rintettet t&aacute;j&eacute;koztatni kell.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>1.4.</strong> Ha az adatkezelő nem tesz
      int&eacute;zked&eacute;seket az &eacute;rintett k&eacute;relme
      nyom&aacute;n, k&eacute;sedelem n&eacute;lk&uuml;l, de legk&eacute;sőbb
      a k&eacute;relem be&eacute;rkez&eacute;s&eacute;től
      sz&aacute;m&iacute;tott egy h&oacute;napon bel&uuml;l
      t&aacute;j&eacute;koztatja az &eacute;rintettet az
      int&eacute;zked&eacute;s elmarad&aacute;s&aacute;nak okair&oacute;l,
      valamint arr&oacute;l, hogy az &eacute;rintett panaszt ny&uacute;jthat
      be valamely fel&uuml;gyeleti hat&oacute;s&aacute;gn&aacute;l, &eacute;s
      &eacute;lhet b&iacute;r&oacute;s&aacute;gi jogorvoslati jog&aacute;val.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>1.5.</strong> Az adatkezelő az inform&aacute;ci&oacute;kat
      &eacute;s az &eacute;rintett jogair&oacute;l sz&oacute;l&oacute;
      t&aacute;j&eacute;koztat&aacute;st &eacute;s int&eacute;zked&eacute;st
      d&iacute;jmentesen biztos&iacute;tja, azonban a Rendeletben &iacute;rt
      esetekben d&iacute;j sz&aacute;m&iacute;that&oacute; fel.
    </p>
    <p>&nbsp;</p>
    <p>
      A r&eacute;szletes szab&aacute;lyok a Rendelet 12 cikke alatt
      tal&aacute;lhat&oacute;k.
    </p>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        <strong>
          {" "}
          Előzetes t&aacute;j&eacute;koz&oacute;d&aacute;shoz val&oacute; jog
          &ndash; ha{" "}
        </strong>
        <strong>
          a szem&eacute;lyes adatokat az &eacute;rintettől gyűjtik
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>2.1.</strong> Az &eacute;rintett jogosult arra, hogy az
      adatkezel&eacute;ssel &ouml;sszef&uuml;ggő t&eacute;nyekről &eacute;s
      inform&aacute;ci&oacute;kr&oacute;l az adatkezel&eacute;s
      megkezd&eacute;s&eacute;t megelőzően t&aacute;j&eacute;koztat&aacute;st
      kapjon.&nbsp; Ennek keret&eacute;ben az &eacute;rintettet
      t&aacute;j&eacute;koztatni kell:
    </p>
    <ol>
      <li>
        a) az adatkezelő &eacute;s k&eacute;pviselője kil&eacute;t&eacute;ről
        &eacute;s el&eacute;rhetős&eacute;geiről,
      </li>
      <li>
        b) az adatv&eacute;delmi tisztviselő el&eacute;rhetős&eacute;geiről
        (ha van ilyen),
      </li>
      <li>
        c) a szem&eacute;lyes adatok tervezett kezel&eacute;s&eacute;nek
        c&eacute;lj&aacute;r&oacute;l, valamint az adatkezel&eacute;s
        jogalapj&aacute;r&oacute;l,
      </li>
      <li>
        d) jogos &eacute;rdek
        &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;n alapul&oacute;
        adatkezel&eacute;s eset&eacute;n, az adatkezelő vagy harmadik
        f&eacute;l jogos &eacute;rdekeiről,
      </li>
      <li>
        e) a szem&eacute;lyes adatok c&iacute;mzettjeiről &ndash; akikkel a
        szem&eacute;lyes adatot k&ouml;zlik -, illetve a c&iacute;mzettek
        kateg&oacute;ri&aacute;ir&oacute;l, ha van ilyen;
      </li>
      <li>
        e) adott esetben annak t&eacute;ny&eacute;ről, hogy az adatkezelő
        harmadik orsz&aacute;gba vagy nemzetk&ouml;zi szervezet
        r&eacute;sz&eacute;re k&iacute;v&aacute;nja tov&aacute;bb&iacute;tani
        a szem&eacute;lyes adatokat.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>2.2.</strong> A tisztess&eacute;ges &eacute;s
      &aacute;tl&aacute;that&oacute; adatkezel&eacute;s biztos&iacute;tsa
      &eacute;rdek&eacute;ben az adatkezelőnek az &eacute;rintettet a
      k&ouml;vetkező kieg&eacute;sz&iacute;tő
      inform&aacute;ci&oacute;kr&oacute;l kell t&aacute;j&eacute;koztatnia:
    </p>
    <ol>
      <li>
        a) a szem&eacute;lyes adatok t&aacute;rol&aacute;s&aacute;nak
        időtartam&aacute;r&oacute;l, vagy ha ez nem lehets&eacute;ges, ezen
        időtartam meghat&aacute;roz&aacute;s&aacute;nak szempontjair&oacute;l;
      </li>
      <li>
        b) az &eacute;rintett azon jog&aacute;r&oacute;l, hogy
        k&eacute;relmezheti az adatkezelőtől a r&aacute; vonatkoz&oacute;
        szem&eacute;lyes adatokhoz val&oacute;
        hozz&aacute;f&eacute;r&eacute;st, azok
        helyesb&iacute;t&eacute;s&eacute;t, t&ouml;rl&eacute;s&eacute;t vagy
        kezel&eacute;s&eacute;nek korl&aacute;toz&aacute;s&aacute;t, &eacute;s
        tiltakozhat az ilyen szem&eacute;lyes adatok kezel&eacute;se ellen,
        valamint az &eacute;rintett adathordozhat&oacute;s&aacute;ghoz
        val&oacute; jog&aacute;r&oacute;l;
      </li>
      <li>
        c) az &eacute;rintett hozz&aacute;j&aacute;rul&aacute;s&aacute;n
        alapul&oacute; adatkezel&eacute;s eset&eacute;n arr&oacute;l, hogy a
        hozz&aacute;j&aacute;rul&aacute;s b&aacute;rmely időpontban
        t&ouml;rt&eacute;nő visszavon&aacute;s&aacute;hoz val&oacute; jog,
        amely nem &eacute;rinti a visszavon&aacute;s előtt a
        hozz&aacute;j&aacute;rul&aacute;s alapj&aacute;n v&eacute;grehajtott
        adatkezel&eacute;s jogszerűs&eacute;g&eacute;t;
      </li>
      <li>
        d) a fel&uuml;gyeleti hat&oacute;s&aacute;ghoz c&iacute;mzett panasz
        beny&uacute;jt&aacute;s&aacute;nak jog&aacute;r&oacute;l;
      </li>
      <li>
        e) arr&oacute;l, hogy a szem&eacute;lyes adat
        szolg&aacute;ltat&aacute;sa jogszab&aacute;lyon vagy
        szerződ&eacute;ses k&ouml;telezetts&eacute;gen alapul vagy
        szerződ&eacute;s k&ouml;t&eacute;s&eacute;nek előfelt&eacute;tele-e,
        valamint, hogy az &eacute;rintett k&ouml;teles-e a szem&eacute;lyes
        adatokat megadni, tov&aacute;bb&aacute;, hogy milyen lehets&eacute;ges
        k&ouml;vetkezm&eacute;nyeikkel j&aacute;rhat az
        adatszolg&aacute;ltat&aacute;s elmarad&aacute;sa;
      </li>
      <li>
        f) az automatiz&aacute;lt d&ouml;nt&eacute;shozatal
        t&eacute;ny&eacute;ről, ide&eacute;rtve a profilalkot&aacute;st is,
        valamint legal&aacute;bb ezekben az esetekben az alkalmazott
        logik&aacute;r&oacute;l, &eacute;s arra vonatkoz&oacute;an
        &eacute;rthető inform&aacute;ci&oacute;kr&oacute;l, hogy az ilyen
        adatkezel&eacute;s milyen jelentős&eacute;ggel, &eacute;s az
        &eacute;rintettre n&eacute;zve milyen v&aacute;rhat&oacute;
        k&ouml;vetkezm&eacute;nyekkel b&iacute;r.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>2.3.</strong> Ha az adatkezelő a szem&eacute;lyes adatokon a
      gyűjt&eacute;s&uuml;k c&eacute;lj&aacute;t&oacute;l elt&eacute;rő
      c&eacute;lb&oacute;l tov&aacute;bbi adatkezel&eacute;st
      k&iacute;v&aacute;n v&eacute;gezni, a tov&aacute;bbi adatkezel&eacute;st
      megelőzően t&aacute;j&eacute;koztatnia kell az &eacute;rintettet erről
      az elt&eacute;rő c&eacute;lr&oacute;l &eacute;s minden relev&aacute;ns
      kieg&eacute;sz&iacute;tő inform&aacute;ci&oacute;r&oacute;l.
    </p>
    <p>&nbsp;</p>
    <p>
      Az előzetes t&aacute;j&eacute;koz&oacute;d&aacute;shoz val&oacute; jog
      r&eacute;szletes szab&aacute;lyait a Rendelet 13. cikke tartalmazza.
    </p>
    <p>&nbsp;</p>
    <ol start="3">
      <li>
        <strong>
          {" "}
          Az &eacute;rintett t&aacute;j&eacute;koztat&aacute;sa &eacute;s a
          rendelkez&eacute;s&eacute;re bocs&aacute;tand&oacute;
          inform&aacute;ci&oacute;k, ha a szem&eacute;lyes adatokat az
          adatkezelő nem tőle szerezte meg
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>3.1.</strong> Ha az adatkezelő a szem&eacute;lyes adatokat nem
      az &eacute;rintettől szerezte meg, az &eacute;rintettet&nbsp; az
      adatkezelőnek a szem&eacute;lyes adatok megszerz&eacute;s&eacute;től
      sz&aacute;m&iacute;tott&nbsp; legk&eacute;sőbb egy h&oacute;napon
      bel&uuml;l; ha a szem&eacute;lyes adatokat az &eacute;rintettel
      val&oacute; kapcsolattart&aacute;s c&eacute;lj&aacute;ra
      haszn&aacute;lj&aacute;k, legal&aacute;bb az &eacute;rintettel
      val&oacute; első kapcsolatfelv&eacute;tel alkalm&aacute;val; vagy ha
      v&aacute;rhat&oacute;an m&aacute;s c&iacute;mzettel is k&ouml;zlik az
      adatokat, legk&eacute;sőbb a szem&eacute;lyes adatok első alkalommal
      val&oacute; k&ouml;zl&eacute;sekor t&aacute;j&eacute;koztatnia kell az
      előbbi 2. pontban &iacute;rt t&eacute;nyekről &eacute;s
      inform&aacute;ci&oacute;kr&oacute;l, tov&aacute;bb&aacute; az
      &eacute;rintett szem&eacute;lyes adatok
      kateg&oacute;ri&aacute;ir&oacute;l, valamint&nbsp; a szem&eacute;lyes
      adatok forr&aacute;s&aacute;r&oacute;l&nbsp; &eacute;s adott esetben
      arr&oacute;l, hogy az adatok nyilv&aacute;nosan
      hozz&aacute;f&eacute;rhető forr&aacute;sokb&oacute;l
      sz&aacute;rmaznak-e.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>3.2.</strong> A tov&aacute;bbi szab&aacute;lyokra az előbbi 2.
      pontban (Előzetes t&aacute;j&eacute;koz&oacute;d&aacute;shoz val&oacute;
      jog) &iacute;rtak ir&aacute;nyad&oacute;k.
    </p>
    <p>&nbsp;</p>
    <p>
      E t&aacute;j&eacute;koztat&aacute;s r&eacute;szletes szab&aacute;lyait a
      Rendelet 14. cikke tartalmazza.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="4">
      <li>
        <strong>
          {" "}
          Az &eacute;rintett hozz&aacute;f&eacute;r&eacute;si joga
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>4.1.</strong> Az &eacute;rintett jogosult arra, hogy az
      adatkezelőtől visszajelz&eacute;st kapjon arra vonatkoz&oacute;an, hogy
      szem&eacute;lyes adatainak kezel&eacute;se folyamatban van-e, &eacute;s
      ha ilyen adatkezel&eacute;s folyamatban van, jogosult arra, hogy a
      szem&eacute;lyes adatokhoz &eacute;s az előbbi 2-3. pontban &iacute;rt
      kapcsol&oacute;d&oacute; inform&aacute;ci&oacute;khoz
      hozz&aacute;f&eacute;r&eacute;st kapjon. (Rendelet 15. cikk).
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>4.2.</strong> Ha szem&eacute;lyes adatoknak harmadik
      orsz&aacute;gba vagy nemzetk&ouml;zi szervezet r&eacute;sz&eacute;re
      t&ouml;rt&eacute;nő tov&aacute;bb&iacute;t&aacute;s&aacute;ra ker&uuml;l
      sor, az &eacute;rintett jogosult arra, hogy
      t&aacute;j&eacute;koztat&aacute;st kapjon a
      tov&aacute;bb&iacute;t&aacute;sra vonatkoz&oacute;an a Rendelet 46. cikk
      szerinti megfelelő garanci&aacute;kr&oacute;l.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>4.3.</strong> Az adatkezelőnek az adatkezel&eacute;s
      t&aacute;rgy&aacute;t k&eacute;pező szem&eacute;lyes adatok
      m&aacute;solat&aacute;t az &eacute;rintett rendelkez&eacute;s&eacute;re
      kell bocs&aacute;tania. Az &eacute;rintett &aacute;ltal k&eacute;rt
      tov&aacute;bbi m&aacute;solatok&eacute;rt az adatkezelő az
      adminisztrat&iacute;v k&ouml;lts&eacute;geken alapul&oacute;,
      &eacute;szszerű m&eacute;rt&eacute;kű d&iacute;jat
      sz&aacute;m&iacute;that fel.
    </p>
    <p>&nbsp;</p>
    <p>
      Az &eacute;rintett hozz&aacute;f&eacute;r&eacute;si jog&aacute;ra
      vonatkoz&oacute; r&eacute;szletes szab&aacute;lyokat a Rendelt 15. cikke
      tartalmazza.
    </p>
    <p>&nbsp;</p>
    <ol start="5">
      <li>
        <strong> A</strong>
        <strong> helyesb&iacute;t&eacute;shez val&oacute; jog</strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>5.1.</strong> Az &eacute;rintett jogosult arra, hogy
      k&eacute;r&eacute;s&eacute;re az Adatkezelő indokolatlan
      k&eacute;sedelem n&eacute;lk&uuml;l helyesb&iacute;tse a r&aacute;
      vonatkoz&oacute; pontatlan szem&eacute;lyes adatokat.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>5.2.</strong> Figyelembe v&eacute;ve az adatkezel&eacute;s
      c&eacute;lj&aacute;t, az &eacute;rintett jogosult arra, hogy
      k&eacute;rje a hi&aacute;nyos szem&eacute;lyes adatok &ndash; egyebek
      mellett kieg&eacute;sz&iacute;tő nyilatkozat &uacute;tj&aacute;n
      t&ouml;rt&eacute;nő &ndash; kieg&eacute;sz&iacute;t&eacute;s&eacute;t
      is.
    </p>
    <p>&nbsp;</p>
    <p>Ezen szab&aacute;lyokat a Rendelet 16. cikke tartalmazza.</p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="6">
      <li>
        <strong> A</strong>
        <strong>
          {" "}
          t&ouml;rl&eacute;shez val&oacute; jog (&bdquo;az
          elfeledtet&eacute;shez val&oacute; jog&rdquo;)
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>6.1.</strong> Az &eacute;rintett jogosult arra, hogy
      k&eacute;r&eacute;s&eacute;re az adatkezelő indokolatlan
      k&eacute;sedelem n&eacute;lk&uuml;l t&ouml;r&ouml;lje a r&aacute;
      vonatkoz&oacute; szem&eacute;lyes adatokat, az adatkezelő pedig
      k&ouml;teles arra, hogy az &eacute;rintettre vonatkoz&oacute;
      szem&eacute;lyes adatokat indokolatlan k&eacute;sedelem
      n&eacute;lk&uuml;l t&ouml;r&ouml;lje ha
    </p>
    <ol>
      <li>
        a) a szem&eacute;lyes adatokra m&aacute;r nincs sz&uuml;ks&eacute;g
        abb&oacute;l a c&eacute;lb&oacute;l, amelyből azokat
        gyűjt&ouml;tt&eacute;k vagy m&aacute;s m&oacute;don kezelt&eacute;k;
      </li>
      <li>
        b) az &eacute;rintett visszavonja az adatkezel&eacute;s alapj&aacute;t
        k&eacute;pező hozz&aacute;j&aacute;rul&aacute;s&aacute;t, &eacute;s az
        adatkezel&eacute;snek nincs m&aacute;s jogalapja;
      </li>
      <li>
        c) az &eacute;rintett tiltakozik az adatkezel&eacute;se ellen,
        &eacute;s nincs elsőbbs&eacute;get &eacute;lvező jogszerű ok az
        adatkezel&eacute;sre,
      </li>
      <li>d) a szem&eacute;lyes adatokat jogellenesen kezelt&eacute;k;</li>
      <li>
        e) a szem&eacute;lyes adatokat az adatkezelőre alkalmazand&oacute;
        uni&oacute;s vagy tag&aacute;llami jogban elő&iacute;rt jogi
        k&ouml;telezetts&eacute;g teljes&iacute;t&eacute;s&eacute;hez
        t&ouml;r&ouml;lni kell;
      </li>
      <li>
        f) a szem&eacute;lyes adatok gyűjt&eacute;s&eacute;re
        k&ouml;zvetlen&uuml;l gyermeknek k&iacute;n&aacute;lt,
        inform&aacute;ci&oacute;s t&aacute;rsadalommal &ouml;sszef&uuml;ggő
        szolg&aacute;ltat&aacute;sok k&iacute;n&aacute;l&aacute;s&aacute;val
        kapcsolatosan ker&uuml;lt sor.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>6.2. </strong>A t&ouml;rl&eacute;shez val&oacute; jog nem
      &eacute;rv&eacute;nyes&iacute;thető, ha az adatkezel&eacute;s
      sz&uuml;ks&eacute;ges
    </p>
    <ol>
      <li>
        a) a v&eacute;lem&eacute;nynyilv&aacute;n&iacute;t&aacute;s
        szabads&aacute;g&aacute;hoz &eacute;s a
        t&aacute;j&eacute;koz&oacute;d&aacute;shoz val&oacute; jog
        gyakorl&aacute;sa c&eacute;lj&aacute;b&oacute;l;
      </li>
      <li>
        b) az adatkezelőre alkalmazand&oacute; uni&oacute;s vagy
        tag&aacute;llami jog szerinti k&ouml;telezetts&eacute;g
        teljes&iacute;t&eacute;se, illetve k&ouml;z&eacute;rdekből vagy az
        adatkezelőre ruh&aacute;zott k&ouml;zhatalmi jogos&iacute;tv&aacute;ny
        gyakorl&aacute;sa keret&eacute;ben v&eacute;gzett feladat
        v&eacute;grehajt&aacute;sa c&eacute;lj&aacute;b&oacute;l;
      </li>
      <li>
        c) a n&eacute;peg&eacute;szs&eacute;g&uuml;gy ter&uuml;let&eacute;t
        &eacute;rintő k&ouml;z&eacute;rdek alapj&aacute;n;
      </li>
      <li>
        d) a k&ouml;z&eacute;rdekű archiv&aacute;l&aacute;s
        c&eacute;lj&aacute;b&oacute;l, tudom&aacute;nyos &eacute;s
        t&ouml;rt&eacute;nelmi kutat&aacute;si c&eacute;lb&oacute;l vagy
        statisztikai c&eacute;lb&oacute;l, amennyiben a t&ouml;rl&eacute;shez
        val&oacute; jog val&oacute;sz&iacute;nűs&iacute;thetően
        lehetetlenn&eacute; tenn&eacute; vagy komolyan
        vesz&eacute;lyeztetn&eacute; ezt az adatkezel&eacute;st; vagy
      </li>
      <li>
        e) jogi ig&eacute;nyek előterjeszt&eacute;s&eacute;hez,
        &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez, illetve
        v&eacute;delm&eacute;hez.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      A t&ouml;rl&eacute;shez val&oacute; jogra vonatkoz&oacute;
      r&eacute;szletes szab&aacute;lyokat a Rendelet 17. cikke tartalmazza.
    </p>
    <p>&nbsp;</p>
    <ol start="7">
      <li>
        <strong>
          Az adatkezel&eacute;s korl&aacute;toz&aacute;s&aacute;hoz
          val&oacute; jog
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>7.1.</strong> Az adatkezel&eacute;s korl&aacute;toz&aacute;sa
      eset&eacute;n az ilyen szem&eacute;lyes adatokat a t&aacute;rol&aacute;s
      kiv&eacute;tel&eacute;vel csak az &eacute;rintett
      hozz&aacute;j&aacute;rul&aacute;s&aacute;val, vagy jogi ig&eacute;nyek
      előterjeszt&eacute;s&eacute;hez,
      &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy
      v&eacute;delm&eacute;hez, vagy m&aacute;s term&eacute;szetes vagy jogi
      szem&eacute;ly jogainak v&eacute;delme &eacute;rdek&eacute;ben, vagy az
      Uni&oacute;, illetve valamely tag&aacute;llam fontos
      k&ouml;z&eacute;rdek&eacute;ből lehet kezelni.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>7.2.</strong> Az &eacute;rintett jogosult arra, hogy
      k&eacute;r&eacute;s&eacute;re az Adatkezelő korl&aacute;tozza az
      adatkezel&eacute;st, ha az al&aacute;bbiak valamelyike teljes&uuml;l:
    </p>
    <ol>
      <li>
        a) az &eacute;rintett vitatja a szem&eacute;lyes adatok
        pontoss&aacute;g&aacute;t, ez esetben a korl&aacute;toz&aacute;s arra
        az időtartamra vonatkozik, amely lehetőv&eacute; teszi, hogy az
        Adatkezelő ellenőrizze a szem&eacute;lyes adatok
        pontoss&aacute;g&aacute;t;
      </li>
      <li>
        b) az adatkezel&eacute;s jogellenes, &eacute;s az &eacute;rintett
        ellenzi az adatok t&ouml;rl&eacute;s&eacute;t, &eacute;s ehelyett
        k&eacute;ri azok felhaszn&aacute;l&aacute;s&aacute;nak
        korl&aacute;toz&aacute;s&aacute;t;
      </li>
      <li>
        c) az Adatkezelőnek m&aacute;r nincs sz&uuml;ks&eacute;ge a
        szem&eacute;lyes adatokra adatkezel&eacute;s
        c&eacute;lj&aacute;b&oacute;l, de az &eacute;rintett ig&eacute;nyli
        azokat jogi ig&eacute;nyek előterjeszt&eacute;s&eacute;hez,
        &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy
        v&eacute;delm&eacute;hez; vagy
      </li>
      <li>
        d) az &eacute;rintett tiltakozott az adatkezel&eacute;s ellen; ez
        esetben a korl&aacute;toz&aacute;s arra az időtartamra vonatkozik,
        am&iacute;g meg&aacute;llap&iacute;t&aacute;sra nem ker&uuml;l, hogy
        az adatkezelő jogos indokai elsőbbs&eacute;get &eacute;lveznek-e az
        &eacute;rintett jogos indokaival szemben.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>7.3. </strong>Az adatkezel&eacute;s
      korl&aacute;toz&aacute;s&aacute;nak felold&aacute;s&aacute;r&oacute;l az
      &eacute;rintettet előzetesen t&aacute;j&eacute;koztatni kell.
    </p>
    <p>&nbsp;</p>
    <p>
      A vonatkoz&oacute; szab&aacute;lyokat a Rendelet 18. cikke tartalmazza.
    </p>
    <ol start="8">
      <li>
        <strong> A</strong>
        <strong>
          {" "}
          szem&eacute;lyes adatok helyesb&iacute;t&eacute;s&eacute;hez vagy
          t&ouml;rl&eacute;s&eacute;hez, illetve az adatkezel&eacute;s
          korl&aacute;toz&aacute;s&aacute;hoz kapcsol&oacute;d&oacute;
          &eacute;rtes&iacute;t&eacute;si k&ouml;telezetts&eacute;g
        </strong>
      </li>
    </ol>
    <p>
      Az adatkezelő minden olyan c&iacute;mzettet t&aacute;j&eacute;koztat
      valamennyi helyesb&iacute;t&eacute;sről, t&ouml;rl&eacute;sről vagy
      adatkezel&eacute;s-korl&aacute;toz&aacute;sr&oacute;l, akivel, illetve
      amellyel a szem&eacute;lyes adatot k&ouml;z&ouml;lt&eacute;k,
      kiv&eacute;ve, ha ez lehetetlennek bizonyul, vagy ar&aacute;nytalanul
      nagy erőfesz&iacute;t&eacute;st ig&eacute;nyel. Az &eacute;rintettet
      k&eacute;r&eacute;s&eacute;re az adatkezelő t&aacute;j&eacute;koztatja e
      c&iacute;mzettekről.
    </p>
    <p>&nbsp;</p>
    <p>
      E szab&aacute;lyok a Rendelet 19. cikke alatt tal&aacute;lhat&oacute;k.
    </p>
    <p>&nbsp;</p>
    <ol start="9">
      <li>
        <strong>
          {" "}
          Az adathordozhat&oacute;s&aacute;ghoz val&oacute; jog
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>9.1.</strong> A Rendeletben &iacute;rt felt&eacute;telekkel az
      &eacute;rintett jogosult arra, hogy a r&aacute; vonatkoz&oacute;,
      &aacute;ltala egy adatkezelő rendelkez&eacute;s&eacute;re
      bocs&aacute;tott szem&eacute;lyes adatokat tagolt, sz&eacute;les
      k&ouml;rben haszn&aacute;lt, g&eacute;ppel olvashat&oacute;
      form&aacute;tumban megkapja, tov&aacute;bb&aacute; jogosult arra, hogy
      ezeket az adatokat egy m&aacute;sik adatkezelőnek
      tov&aacute;bb&iacute;tsa an&eacute;lk&uuml;l, hogy ezt
      akad&aacute;lyozn&aacute; az az adatkezelő, amelynek a szem&eacute;lyes
      adatokat a rendelkez&eacute;s&eacute;re bocs&aacute;totta, ha
    </p>
    <ol>
      <li>
        a) az adatkezel&eacute;s hozz&aacute;j&aacute;rul&aacute;son vagy
        szerződ&eacute;sen alapul; &eacute;s
      </li>
      <li>
        b) az adatkezel&eacute;s automatiz&aacute;lt m&oacute;don
        t&ouml;rt&eacute;nik.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>9.2.</strong> Az &eacute;rintett k&eacute;rheti a
      szem&eacute;lyes adatok adatkezelők k&ouml;z&ouml;tti k&ouml;zvetlen
      tov&aacute;bb&iacute;t&aacute;s&aacute;t is.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>9.3</strong>. Az adathordozhat&oacute;s&aacute;ghoz val&oacute;
      jog gyakorl&aacute;sa nem s&eacute;rtheti a Rendelet 17. cikk&eacute;t
      (A t&ouml;rl&eacute;shez val&oacute; jog (&bdquo;az
      elfeledtet&eacute;shez val&oacute; jog&rdquo;). Az
      adtahordozhat&oacute;s&aacute;ghoz val&oacute; jog nem
      alkalmazand&oacute; abban az esetben, ha az adatkezel&eacute;s
      k&ouml;z&eacute;rdekű vagy az adatkezelőre ruh&aacute;zott
      k&ouml;zhatalmi jogos&iacute;tv&aacute;nyai gyakorl&aacute;s&aacute;nak
      keret&eacute;ben v&eacute;gzett feladat
      v&eacute;grehajt&aacute;s&aacute;hoz sz&uuml;ks&eacute;ges. E jog nem
      &eacute;rintheti h&aacute;tr&aacute;nyosan m&aacute;sok jogait &eacute;s
      szabads&aacute;gait.
    </p>
    <p>&nbsp;</p>
    <p>
      A r&eacute;szletes szab&aacute;lyokat a Rendelet 20. cikke tartalmazza.
    </p>
    <p>&nbsp;</p>
    <ol start="10">
      <li>
        <strong> A</strong>
        <strong> tiltakoz&aacute;shoz val&oacute; jog</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>10.1.</strong> Az &eacute;rintett jogosult arra, hogy a
      saj&aacute;t helyzet&eacute;vel kapcsolatos okokb&oacute;l
      b&aacute;rmikor tiltakozzon szem&eacute;lyes adatainak
      k&ouml;z&eacute;rdeken, k&ouml;zfeladat
      v&eacute;grehajt&aacute;s&aacute;n (6. cikk (1) e)), vagy jogos
      &eacute;rdeken (6. cikk f)) alapul&oacute; kezel&eacute;se ellen,
      ide&eacute;rtve az eml&iacute;tett rendelkez&eacute;seken alapul&oacute;
      profilalkot&aacute;st is. Ebben az esetben az adatkezelő a
      szem&eacute;lyes adatokat nem kezelheti tov&aacute;bb, kiv&eacute;ve, ha
      az adatkezelő bizony&iacute;tja, hogy az adatkezel&eacute;st olyan
      k&eacute;nyszer&iacute;tő erejű jogos okok indokolj&aacute;k, amelyek
      elsőbbs&eacute;get &eacute;lveznek az &eacute;rintett &eacute;rdekeivel,
      jogaival &eacute;s szabads&aacute;gaival szemben, vagy amelyek jogi
      ig&eacute;nyek előterjeszt&eacute;s&eacute;hez,
      &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy
      v&eacute;delm&eacute;hez kapcsol&oacute;dnak.
    </p>
    <p>
      <strong>10.2.</strong> Ha a szem&eacute;lyes adatok kezel&eacute;se
      k&ouml;zvetlen &uuml;zletszerz&eacute;s &eacute;rdek&eacute;ben
      t&ouml;rt&eacute;nik, az &eacute;rintett jogosult arra, hogy
      b&aacute;rmikor tiltakozzon a r&aacute; vonatkoz&oacute;
      szem&eacute;lyes adatok e c&eacute;lb&oacute;l t&ouml;rt&eacute;nő
      kezel&eacute;se ellen, ide&eacute;rtve a profilalkot&aacute;st is,
      amennyiben az a k&ouml;zvetlen &uuml;zletszerz&eacute;shez
      kapcsol&oacute;dik. Ha az &eacute;rintett tiltakozik a szem&eacute;lyes
      adatok k&ouml;zvetlen &uuml;zletszerz&eacute;s &eacute;rdek&eacute;ben
      t&ouml;rt&eacute;nő kezel&eacute;se ellen, akkor a szem&eacute;lyes
      adatok a tov&aacute;bbiakban e c&eacute;lb&oacute;l nem kezelhetők.
    </p>
    <p>
      <strong>10.3. </strong>Ezen jogokra legk&eacute;sőbb az
      &eacute;rintettel val&oacute; első kapcsolatfelv&eacute;tel sor&aacute;n
      kifejezetten fel kell h&iacute;vni annak figyelm&eacute;t, &eacute;s az
      erre vonatkoz&oacute; t&aacute;j&eacute;koztat&aacute;st
      egy&eacute;rtelműen &eacute;s minden m&aacute;s
      inform&aacute;ci&oacute;t&oacute;l elk&uuml;l&ouml;n&iacute;tve kell
      megjelen&iacute;teni.
    </p>
    <p>
      <strong>10.4.</strong> Az &eacute;rintett a tiltakoz&aacute;shoz
      val&oacute; jogot műszaki elő&iacute;r&aacute;sokon alapul&oacute;
      automatiz&aacute;lt eszk&ouml;z&ouml;kkel is gyakorolhatja.
    </p>
    <p>
      <strong>10.5.</strong> Ha a szem&eacute;lyes adatok
      kezel&eacute;s&eacute;re tudom&aacute;nyos &eacute;s
      t&ouml;rt&eacute;nelmi kutat&aacute;si c&eacute;lb&oacute;l vagy
      statisztikai c&eacute;lb&oacute;l ker&uuml;l sor, az &eacute;rintett
      jogosult arra, hogy a saj&aacute;t helyzet&eacute;vel kapcsolatos
      okokb&oacute;l tiltakozhasson a r&aacute; vonatkoz&oacute;
      szem&eacute;lyes adatok kezel&eacute;se ellen, kiv&eacute;ve, ha az
      adatkezel&eacute;sre k&ouml;z&eacute;rdekű okb&oacute;l v&eacute;gzett
      feladat v&eacute;grehajt&aacute;sa &eacute;rdek&eacute;ben van
      sz&uuml;ks&eacute;g.
    </p>
    <p>&nbsp;</p>
    <p>A vonatkoz&oacute; szab&aacute;lyokat a Rendelet cikke tartalmazza.</p>
    <p>&nbsp;</p>
    <ol start="11">
      <li>
        <strong>
          {" "}
          Automatiz&aacute;lt d&ouml;nt&eacute;shozatal egyedi &uuml;gyekben,
          bele&eacute;rtve a profilalkot&aacute;st
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>11.1.</strong> Az &eacute;rintett jogosult arra, hogy ne
      terjedjen ki r&aacute; az olyan, kiz&aacute;r&oacute;lag
      automatiz&aacute;lt adatkezel&eacute;sen &ndash; ide&eacute;rtve a
      profilalkot&aacute;st is &ndash; alapul&oacute; d&ouml;nt&eacute;s
      hat&aacute;lya, amely r&aacute; n&eacute;zve joghat&aacute;ssal
      j&aacute;rna vagy őt hasonl&oacute;k&eacute;ppen jelentős
      m&eacute;rt&eacute;kben &eacute;rinten&eacute;.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>11.2.</strong> Ez a jogosults&aacute;g nem alkalmazand&oacute;
      abban az esetben, ha a d&ouml;nt&eacute;s:
    </p>
    <ol>
      <li>
        a) az &eacute;rintett &eacute;s az adatkezelő k&ouml;z&ouml;tti
        szerződ&eacute;s megk&ouml;t&eacute;se vagy teljes&iacute;t&eacute;se
        &eacute;rdek&eacute;ben sz&uuml;ks&eacute;ges;
      </li>
      <li>
        b) meghozatal&aacute;t az adatkezelőre alkalmazand&oacute; olyan
        uni&oacute;s vagy tag&aacute;llami jog teszi lehetőv&eacute;, amely az
        &eacute;rintett jogainak &eacute;s szabads&aacute;gainak, valamint
        jogos &eacute;rdekeinek v&eacute;delm&eacute;t szolg&aacute;l&oacute;
        megfelelő int&eacute;zked&eacute;seket is meg&aacute;llap&iacute;t;
        vagy
      </li>
      <li>
        c) az &eacute;rintett kifejezett
        hozz&aacute;j&aacute;rul&aacute;s&aacute;n alapul.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>11.3. </strong>Az előbbi a) &eacute;s c) pontj&aacute;ban
      eml&iacute;tett esetekben az adatkezelő k&ouml;teles megfelelő
      int&eacute;zked&eacute;seket tenni az &eacute;rintett jogainak,
      szabads&aacute;gainak &eacute;s jogos &eacute;rdekeinek v&eacute;delme
      &eacute;rdek&eacute;ben, ide&eacute;rtve az &eacute;rintettnek
      legal&aacute;bb azt a jog&aacute;t, hogy az adatkezelő
      r&eacute;sz&eacute;ről emberi beavatkoz&aacute;st k&eacute;rjen,
      &aacute;ll&aacute;spontj&aacute;t kifejezze, &eacute;s a
      d&ouml;nt&eacute;ssel szemben kifog&aacute;st ny&uacute;jtson be.
    </p>
    <p>&nbsp;</p>
    <p>
      A tov&aacute;bbi szab&aacute;lyokat a Rendelet 22. cikke tartalmazza.
    </p>
    <p>&nbsp;</p>
    <ol start="12">
      <li>
        <strong> Korl&aacute;toz&aacute;sok</strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Az adatkezelőre vagy adatfeldolgoz&oacute;ra alkalmazand&oacute;
      uni&oacute;s vagy tag&aacute;llami jog jogalkot&aacute;si
      int&eacute;zked&eacute;sekkel korl&aacute;tozhatja jogok &eacute;s
      k&ouml;telezetts&eacute;gek (Rendelet 12-22. cikk, 34. cikk, 5. cikk)
      hat&aacute;ly&aacute;t, ha a korl&aacute;toz&aacute;s tiszteletben
      tartja az alapvető jogok &eacute;s szabads&aacute;gok l&eacute;nyeges
      tartalm&aacute;t.
    </p>
    <p>&nbsp;</p>
    <p>
      E korl&aacute;toz&aacute;s felt&eacute;teleit a Rendelet 23. cikke
      tartalmazza.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="13">
      <li>
        <strong>
          {" "}
          Az &eacute;rintett t&aacute;j&eacute;koztat&aacute;sa az
          adatv&eacute;delmi incidensről
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>13.1.</strong> Ha az adatv&eacute;delmi incidens
      val&oacute;sz&iacute;nűs&iacute;thetően magas kock&aacute;zattal
      j&aacute;r a term&eacute;szetes szem&eacute;lyek jogaira &eacute;s
      szabads&aacute;gaira n&eacute;zve, az adatkezelőnek indokolatlan
      k&eacute;sedelem n&eacute;lk&uuml;l t&aacute;j&eacute;koztatnia kell az
      &eacute;rintettet az adatv&eacute;delmi incidensről. E
      t&aacute;j&eacute;koztat&aacute;sban vil&aacute;gosan &eacute;s
      k&ouml;z&eacute;rthetően ismertetni kell az adatv&eacute;delmi incidens
      jelleg&eacute;t, &eacute;s k&ouml;z&ouml;lni kell legal&aacute;bb a
      k&ouml;vetkezőket:
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        a) az adatv&eacute;delmi tisztviselő vagy a tov&aacute;bbi
        t&aacute;j&eacute;koztat&aacute;st ny&uacute;jt&oacute; egy&eacute;b
        kapcsolattart&oacute; nev&eacute;t &eacute;s
        el&eacute;rhetős&eacute;geit;
      </li>
      <li>
        c) ismertetni kell az adatv&eacute;delmi incidensből eredő,
        val&oacute;sz&iacute;nűs&iacute;thető k&ouml;vetkezm&eacute;nyeket;
      </li>
      <li>
        d) ismertetni kell az adatkezelő &aacute;ltal az adatv&eacute;delmi
        incidens orvosl&aacute;s&aacute;ra tett vagy tervezett
        int&eacute;zked&eacute;seket, bele&eacute;rtve adott esetben az
        adatv&eacute;delmi incidensből eredő esetleges h&aacute;tr&aacute;nyos
        k&ouml;vetkezm&eacute;nyek enyh&iacute;t&eacute;s&eacute;t
        c&eacute;lz&oacute; int&eacute;zked&eacute;seket.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>13.2.</strong> Az &eacute;rintettet nem kell az
      t&aacute;j&eacute;koztatni, ha a k&ouml;vetkező felt&eacute;telek
      b&aacute;rmelyike teljes&uuml;l:
    </p>
    <ol>
      <li>
        a) az adatkezelő megfelelő technikai &eacute;s szervez&eacute;si
        v&eacute;delmi int&eacute;zked&eacute;seket hajtott v&eacute;gre,
        &eacute;s ezeket az int&eacute;zked&eacute;seket az adatv&eacute;delmi
        incidens &aacute;ltal &eacute;rintett adatok tekintet&eacute;ben
        alkalmazt&aacute;k, k&uuml;l&ouml;n&ouml;sen azokat az
        int&eacute;zked&eacute;seket &ndash; mint p&eacute;ld&aacute;ul a
        titkos&iacute;t&aacute;s alkalmaz&aacute;sa &ndash;, amelyek a
        szem&eacute;lyes adatokhoz val&oacute;
        hozz&aacute;f&eacute;r&eacute;sre fel nem jogos&iacute;tott
        szem&eacute;lyek sz&aacute;m&aacute;ra
        &eacute;rtelmezhetetlenn&eacute; teszik az adatokat;
      </li>
      <li>
        b) az adatkezelő az adatv&eacute;delmi incidenst k&ouml;vetően olyan
        tov&aacute;bbi int&eacute;zked&eacute;seket tett, amelyek
        biztos&iacute;tj&aacute;k, hogy az &eacute;rintett jogaira &eacute;s
        szabads&aacute;gaira jelentett, magas kock&aacute;zat a
        tov&aacute;bbiakban val&oacute;sz&iacute;nűs&iacute;thetően nem
        val&oacute;sul meg;
      </li>
      <li>
        c) a t&aacute;j&eacute;koztat&aacute;s ar&aacute;nytalan
        erőfesz&iacute;t&eacute;st tenne sz&uuml;ks&eacute;gess&eacute;. Ilyen
        esetekben az &eacute;rintetteket nyilv&aacute;nosan
        k&ouml;zz&eacute;tett inform&aacute;ci&oacute;k &uacute;tj&aacute;n
        kell t&aacute;j&eacute;koztatni, vagy olyan hasonl&oacute;
        int&eacute;zked&eacute;st kell hozni, amely biztos&iacute;tja az
        &eacute;rintettek hasonl&oacute;an hat&eacute;kony
        t&aacute;j&eacute;koztat&aacute;s&aacute;t.
      </li>
    </ol>
    <p>
      A tov&aacute;bbi szab&aacute;lyokat a Rendelet&nbsp;&nbsp; 34. cikke
      tartalmazza.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="14">
      <li>
        <strong> A</strong>
        <strong>
          {" "}
          fel&uuml;gyeleti hat&oacute;s&aacute;gn&aacute;l t&ouml;rt&eacute;nő
          panaszt&eacute;telhez val&oacute; jog (hat&oacute;s&aacute;gi
          jogorvoslathoz val&oacute; jog)
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Az &eacute;rintett jogosult arra, hogy panaszt tegyen egy
      fel&uuml;gyeleti hat&oacute;s&aacute;gn&aacute;l &ndash;
      k&uuml;l&ouml;n&ouml;sen a szok&aacute;sos tart&oacute;zkod&aacute;si
      helye, a munkahelye vagy a felt&eacute;telezett jogs&eacute;rt&eacute;s
      helye szerinti tag&aacute;llamban &ndash;, ha az &eacute;rintett
      meg&iacute;t&eacute;l&eacute;se szerint a r&aacute; vonatkoz&oacute;
      szem&eacute;lyes adatok kezel&eacute;se megs&eacute;rti a Rendeletet.
      &nbsp;Az a fel&uuml;gyeleti hat&oacute;s&aacute;g, amelyhez a panaszt
      beny&uacute;jtott&aacute;k, k&ouml;teles t&aacute;j&eacute;koztatni az
      &uuml;gyfelet a panasszal kapcsolatos elj&aacute;r&aacute;si
      fejlem&eacute;nyekről &eacute;s annak eredm&eacute;ny&eacute;ről,
      ide&eacute;rtve azt is, hogy az &uuml;gyf&eacute;l jogosult
      b&iacute;r&oacute;s&aacute;gi jogorvoslattal &eacute;lni.
    </p>
    <p>&nbsp;</p>
    <p>E szab&aacute;lyokat a Rendelet 77. cikke tartalmazza.</p>
    <p>&nbsp;</p>
    <p>
      <strong>
        A fel&uuml;gyeleti hat&oacute;s&aacute;g el&eacute;rhetős&eacute;ge:{" "}
      </strong>
    </p>
    <p>
      <strong>
        Nemzeti Adatv&eacute;delmi &eacute;s
        Inform&aacute;ci&oacute;szabads&aacute;g Hat&oacute;s&aacute;g{" "}
      </strong>
    </p>
    <p>
      <strong>Sz&eacute;khely: 1055 Budapest, Falk Miksa utca 9-11</strong>
    </p>
    <p>
      <strong>Postac&iacute;m:1363 Budapest, Pf.: 9.</strong>
    </p>
    <p>
      <strong>Telefonsz&aacute;m: +36 (1) 391-1400</strong>
    </p>
    <p>
      <strong>
        K&ouml;zponti elektronikus lev&eacute;lc&iacute;m:
        ugyfelszolgalat@naih.hu
      </strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="15">
      <li>
        <strong> A</strong>
        <strong>
          {" "}
          fel&uuml;gyeleti hat&oacute;s&aacute;ggal szembeni hat&eacute;kony
          b&iacute;r&oacute;s&aacute;gi jogorvoslathoz val&oacute; jog
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>15.1.</strong> Az egy&eacute;b k&ouml;zigazgat&aacute;si vagy
      nem b&iacute;r&oacute;s&aacute;gi &uacute;tra tartoz&oacute;
      jogorvoslatok s&eacute;relme n&eacute;lk&uuml;l, minden
      term&eacute;szetes &eacute;s jogi szem&eacute;ly jogosult a
      hat&eacute;kony b&iacute;r&oacute;s&aacute;gi jogorvoslatra a
      fel&uuml;gyeleti hat&oacute;s&aacute;g r&aacute; vonatkoz&oacute;,
      jogilag k&ouml;telező erejű d&ouml;nt&eacute;s&eacute;vel szemben.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>15.2.</strong> Az egy&eacute;b k&ouml;zigazgat&aacute;si vagy
      nem b&iacute;r&oacute;s&aacute;gi &uacute;tra tartoz&oacute;
      jogorvoslatok s&eacute;relme n&eacute;lk&uuml;l, minden &eacute;rintett
      jogosult a hat&eacute;kony b&iacute;r&oacute;s&aacute;gi jogorvoslatra,
      ha az illet&eacute;kes fel&uuml;gyeleti hat&oacute;s&aacute;g nem
      foglalkozik a panasszal, vagy h&aacute;rom h&oacute;napon bel&uuml;l nem
      t&aacute;j&eacute;koztatja az &eacute;rintettet a beny&uacute;jtott
      panasszal kapcsolatos elj&aacute;r&aacute;si fejlem&eacute;nyekről vagy
      annak eredm&eacute;ny&eacute;ről.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>15.3.</strong> A fel&uuml;gyeleti hat&oacute;s&aacute;ggal
      szembeni elj&aacute;r&aacute;st a fel&uuml;gyeleti hat&oacute;s&aacute;g
      sz&eacute;khelye szerinti tag&aacute;llam b&iacute;r&oacute;s&aacute;ga
      előtt kell megind&iacute;tani.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>15.4.</strong> Ha a fel&uuml;gyeleti hat&oacute;s&aacute;g olyan
      d&ouml;nt&eacute;se ellen ind&iacute;tanak elj&aacute;r&aacute;st,
      amellyel kapcsolatban az egys&eacute;gess&eacute;gi mechanizmus
      keret&eacute;ben a Test&uuml;let előzőleg v&eacute;lem&eacute;nyt
      bocs&aacute;tott ki vagy d&ouml;nt&eacute;st hozott, a fel&uuml;gyeleti
      hat&oacute;s&aacute;g k&ouml;teles ezt a v&eacute;lem&eacute;nyt vagy
      d&ouml;nt&eacute;st a b&iacute;r&oacute;s&aacute;gnak megk&uuml;ldeni.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>E szab&aacute;lyokat a Rendelet 78. cikke tartalmazza.</p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="16">
      <li>
        <strong>
          {" "}
          Az adatkezelővel vagy az adatfeldolgoz&oacute;val szembeni
          hat&eacute;kony b&iacute;r&oacute;s&aacute;gi jogorvoslathoz
          val&oacute; jog
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>16.1.</strong> A rendelkez&eacute;sre &aacute;ll&oacute;
      k&ouml;zigazgat&aacute;si vagy nem b&iacute;r&oacute;s&aacute;gi
      &uacute;tra tartoz&oacute; jogorvoslatok &ndash; k&ouml;zt&uuml;k a
      fel&uuml;gyeleti hat&oacute;s&aacute;gn&aacute;l t&ouml;rt&eacute;nő
      panaszt&eacute;telhez val&oacute; jog &ndash; s&eacute;relme
      n&eacute;lk&uuml;l, minden &eacute;rintett hat&eacute;kony
      b&iacute;r&oacute;s&aacute;gi jogorvoslatra jogosult, ha
      meg&iacute;t&eacute;l&eacute;se szerint a szem&eacute;lyes adatainak e
      rendeletnek nem megfelelő kezel&eacute;se k&ouml;vetkezt&eacute;ben
      megs&eacute;rtett&eacute;k az e rendelet szerinti jogait.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>16.2.</strong> Az adatkezelővel vagy az adatfeldolgoz&oacute;val
      szembeni elj&aacute;r&aacute;st az adatkezelő vagy az
      adatfeldolgoz&oacute; tev&eacute;kenys&eacute;gi helye szerinti
      tag&aacute;llam b&iacute;r&oacute;s&aacute;ga előtt kell
      megind&iacute;tani. Az ilyen elj&aacute;r&aacute;s
      megind&iacute;that&oacute; az &eacute;rintett szok&aacute;sos
      tart&oacute;zkod&aacute;si helye szerinti tag&aacute;llam
      b&iacute;r&oacute;s&aacute;ga előtt is, kiv&eacute;ve, ha az adatkezelő
      vagy az adatfeldolgoz&oacute; valamely tag&aacute;llamnak a
      k&ouml;zhatalmi jogk&ouml;r&eacute;ben elj&aacute;r&oacute;
      k&ouml;zhatalmi szerve.
    </p>
    <p>&nbsp;</p>
    <p>E szab&aacute;lyokat a Rendelet 79. cikke tartalmazza.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>Kelt, Szentesen, 2021. febru&aacute;r 16. napj&aacute;n.</p>
    <p>&nbsp;</p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>
        ELEKTRO-AMPER Korl&aacute;tolt Felelőss&eacute;gű
        T&aacute;rsas&aacute;g
      </strong>
    </p>
    <p>&nbsp;</p>
  </div>
);

export default dataUsage;