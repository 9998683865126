import React from "react";

//import logoImg from '../../assets/images/logo.jpg';
//import logoImg from '../../assets/images/wLogo.svg'

//import logoImg from '../../assets/images/newLogo.png'
import logoImg from "../../assets/images/web-logo-teszt-5.svg";

import classes from "./Logo.module.css";

const logo = (props) => (
  <div className={classes.Logo}>
    <img src={logoImg} alt="Amper" />
  </div>
);

export default logo;
