import React from "react";
import PageContentHeader from "../../../components/PageContentHeader/PageContentHeader";
import classes from "../Articles.module.css";

import adax from '../../../assets/images/news/Adax.jpg';
import cloudyblue from '../../../assets/images/news/norel-hoaramlas.jpg';
import beigered from '../../../assets/images/news/beigered.jpg';
import neo101 from '../../../assets/images/news/neo101.jpg';
import neo201 from '../../../assets/images/news/neo201.jpg';
import neo211 from '../../../assets/images/news/neo211.jpg';
import neo202 from '../../../assets/images/news/neo202.jpg';


const heatingPanel = () => (
    <div>
        <PageContentHeader>Miért válasszuk a norvég fűtőtesteket?</PageContentHeader>
        <div className={classes.Articles}>
            <p>
                Sok ország foglalkozik elektromos fűtőpanelek gyártásával, azonban mind közül kiemelkednek a norvég fűtőtestek. Vizsgáljuk meg mi az oka a norvég fűtés térnyerésének. Ma már bele sem gondolunk, hogy az 1960-as években még elképzelhetetlen volt egy 7-8 centis, a szoba képéhez illeszkedő, elegáns panel a falon, hiszen a kályhák és öntöttvas tűzhelyek aligha voltak kaphatók sokféle változatban akkortájt. Úttörő megoldásként robbant be a köztudatba az Adax Sa hordozható elektromos fűtőteste, valamint a Glen Electric kis csapata, akik olajtöltésű radiátorokat fejlesztettek már egészen a 70-es évek elejétől kedve.
            </p>
            <p>
                Nem tudható pontosan, hogy a letisztult formák, vagy az innovatív műszaki tartalom hozták meg a sikert, de egy biztos, a norvég designerek termékei 1988-ban elkezdték bezsebelni az elismeréseket, elektromos radiátoruk megkapta az „Award for Design Excellence" és a "Design-Auswahl 88" díjat Stuttgarban. 
            </p>
            <div className={classes.Photos}>
                <img className={classes.Photo} src={neo211} alt="heatingpanel"/>
            </div>
            <p>
                Ma az Adax Sa az Otterlei Group tagjaként egy teljes mértékben norvég tulajdonban lévő cég, mely egyeduralkodó a piacon. Hasonló szakmai elismerésnek örvend a Glen Dimplex Nordic AS is, ennek Dimplex és a NOBO márkájú gyártmányai hazánkban is kaphatóak.
                A Nobo fűtőtesteket hazánkban 1991 óta árusítják, az Adax panelek pedig nagyjából 10 éve vannak jelen a hazai piacon. A két cég gyártmányai Magyarországon több tízezres tételben keltek el azóta, az Adax 10 év alatt már 15000 háztartásba eljutott, és az évek során ez a szám feltehetően egyre csak növekedni fog.
            </p>
            <div className={classes.Photos}>
                <img className={classes.Photo} src={adax} alt="heatingpanel"/>
            </div>
            <p>
                <strong>
                    Hogyan működnek a norvég fűtőtestek?
                </strong>
            </p>
            <p>
                Az elektromos fűtőpanelek működése a konvekció, azaz a hőáramlás elvén alapul. Konvekció során a fűtőtestek alján beáramló hideg levegőt a panelben található fűtőbetét felmelegíti, ami a tetején található fűtőrácson keresztül kiáramolva felmelegíti a szobát. Ennek alapján az adott helyiségben a hideg és meleg levegő hőcseréje által létrejön a felmelegedés, amely az egész légtömeget átmozgatja és nem igényel külön ventilálást.
            </p>
            <p>
                A norvég panelek kerámia magos fűtőbetétje sokkal fejlettebb a régi típusú fűtőszálas, cekászos fűtőtestekkel szemben, hiszen alacsonyabb hőmérsékletben is nagyobb hőleadásra képes, azaz sokkal hatékonyabb és gazdaságosabb.
                A Nobo fűtőteste X-Type fűtő betéttel felszerelt, ami az izzószálakkal szemben alacsonyabb hőmérsékleten működik, nem égeti a port és igen magas, 5+5 garanciát ad hozzá a cég.
            </p>
            <p>
                Az szintén norvég Adax és a Beha típusokban hasonló fűtőbetét adja az elektromos konvektorok lelkét, a különbség csupán a betétek keresztmetszetének alakjában rejtőzik, ami az X mellett U, vagy akár H alakú is lehet.
            </p>
            <div className={classes.Photos}>
                <img className={classes.Photo} src={cloudyblue} alt="heatingpanel"/>
            </div>
            <p>
                <strong>
                    A norvég fűtés evolúciója
                </strong>
            </p>
            <p>
                1962 – világossá vált, hogy a fűtéstechnika új irányt kell hogy vegyen ahhoz, hogy lépést tudjon tartani az emberek igényeivel. Az Adax rájött, hogy a háztartási gépek gyártásán kívül igen nagy hasznot hozhat nekik a kis méretű, hordozható fűtőtestek gyártása, amelyeket mobilisan bárhová el lehetett helyezni az adott helyiségben. A következő fejlesztés a falra szerelhető, termosztáttal működő fűtőpanel volt, ami szintén nagy sikert aratott a vásárlók körében.
            </p>
            <p>
                1984 – Az Adax cég képviseletébe hamarosan bekerültek a Glamox Heating és Norel márkái. A Glamox 1984-ben indított fűtőpanele akkora sikert aratott, hogy több százezres darabszámban keltek el a gyártmányok. A Glamox népszerűségének kiemelkedő tényezői a megújuló műszaki tartalmak, mint pl.: a programozható termosztát és időtállóságukat mi sem bizonyítja, mint hogy termékeik mind a mai napig jelen vannak a piacon. 
            </p>
            <p>
                2008 – Ebben az évben az Adax NEO készüléke egyértelműen a márka csúcskategóriás fűtőpaneljévé lépett elő és megjelenésének, valamint innovatív műszaki tartalmának köszönhetően, a Norwegian Design Council “Award for Design Excellence” díjban is részesítette. A Hareide Design egy többszörös norvég design díjas ipari formatervezési és tervezési stratégiai cég, amely több iparágban is munkálkodik. Az ő közreműködésükkel született meg a Neo, ami egy felhasználóbarát, könnyen érthető kezelőpanelt kapott, digitális kijelzővel ellátva. 
                A Neo az Adax igazi modern design mesterművévé és forradalmává vált, immáron több mint félmillió eladott termékkel büszkélkedhet. Bebizonyította, hogy a kevesebb néha több, hiszen sikerét leginkább a minimalista kialakításának köszönheti. 
                A Neo fűtőpanel napjainkban az 5.1-es verziójánál tart.
            </p>
            <div className={classes.Photos}>
                <img className={classes.Photo} src={neo202} alt="heatingpanel"/>
            </div>
            <p>
                2016 őszén az Adax újabb innovatív lépést tett, amikor termékeit integrált és energiatakarékos Wi-Fi rendszerrel működő termosztáttal szerelte fel, ezzel ismét hatalmas előnyt szerezve a termékpiacon. A Wi-Fi kapcsolat elérhetővé tette a készülékek irányítását és ellenőrzését a világ bármely pontjáról, a távoli vezérlésnek köszönhetően pedig pénzt spórolhat meg, aki megvásárolja. Hazánkban 2017 óta kapható az Adax Wifi termosztáttal ellátott fűtőpanel. 
            </p>
            <p>
                Az Adax AS termékeinek gyártási és tervezési helye Svelvikben található, az európai logisztikát Litvániában, a panevezys-i központban végzik. A cég termékei közel 30 országban kaphatóak, Magyarországon 10 éve törtek be a piacra.
            </p>
            <div className={classes.Photos}>
                <img className={classes.Photo} src={beigered} alt="heatingpanel"/>
            </div>
            <p>
                <strong>
                    Elég-e önmagában a norvég fűtőpanel?
                </strong>
            </p>
            <p>
                A válasz természetesen igen. A házak és lakások hőszigetelése azonban nagyban befolyásolja a kiválasztott fűtési technikák sikerességét. A hőszigetelés és a terek elosztása kulcsfontosságú szerepet játszik, hiszen egy 150 négyzetméteres, 60 centiméteres falvastagsággal rendelkező, 4 méteres belmagasságú, szigeteletlen kőházban egy 20kW-os gázkazán is csak kiegészítő fűtésre alkalmas.
            </p>
            <p>
                Norvégiában, a hideg telek és a zord időjárási viszonyok hazájában teljes mértékben elvárt, hogy a fűtési technológia maximálisan kiszolgálja az igényeket, így hát a mai kor követelményeinek megfelelő célt szolgálnak fő-fűtésként is. Ha egy átlagos 20 m2-es szobát nem lehet négyzetméterenként 100 Wattal befűteni a hőszigetelés hiányosságai miatt, akkor jobb, ha elvetjük a modern fűtőpanelek használatát.
            </p>
            <div className={classes.Photos}>
                <img className={classes.Photo} src={neo101} alt="heatingpanel"/>
            </div>
            <p>
                <strong>
                    Az Adax fűtőpanelek a jövőben
                </strong>
            </p>
            <p>
                A márka erőssége eddig az innovatív, előretörő, újító, felhasználóbarát megoldásokban rejlett, 2017-től minőségi és fenntartható termékeket hoznak létre, amiket teljes mértékben vásárlóik igényeire szabnak. A formatervezés során a legmodernebb és leghatékonyabb kinézetre törekednek, minimalista kinézetük igencsak kedvelt a vevőik körében. 
            </p>
            <p>
                Innovációs fejlesztéseik, okos fűtőpaneleik illeszkednek a modern kor elvárásaihoz, hiszen az okos-otthon szabványokhoz igazodnak. A Wi-Fi és a távvezérlés is olyan újdonság, ami előremutat afelé, hogy hamarosan a megújuló erőforrásokat felhasználó és fenntartható fűtési rendszerek kialakítására kerüljön sor. 
            </p>
            <p>
                Az Adax törekszik a folyamatos korszerűsítésre, a modern design kialakítására, és a vásárlói igények figyelembe vételére. Fűtő berendezések használatára mindig szükség lesz, és elvárható, hogy a norvég fűtés a továbbiakban is uralkodó pozíciót fog betölteni a piacon.
            </p>
            <div className={classes.Photos}>
                <img className={classes.Photo} src={neo201} alt="heatingpanel"/>
            </div>
        </div>
    </div>
);

export default heatingPanel;
