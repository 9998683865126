import "./App.css";

import PageHeader from "./components/PageHeader/PageHeader";
import PageContent from "./components/PageContent/PageContent";
import PageFooter from "./components/PageFooter/PageFooter";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home/Home";
import Intro from "./pages/Intro/Intro";
import Contact from "./pages/Contact/Contact";
import GetInquery from "./pages/Getinquery/Getinquery";
import Providers from "./pages/Providers/Providers";
import Gallery from "./pages/Gallery/Gallery";
import Downloads from "./pages/Downloads/Downloads";
import Webshop from "./pages/Webshop/Webshop";
import PageNotFound from "./pages/NotFound/NotFound";

import Datausage from "./pages/DataUsage/DataUsage";

import Colleagues from "./pages/Colleagues/Colleagues";

import HomeRenovation from "./pages/Articles/HomeRenovation/HomeRenovation";
import LedTape from "./pages/Articles/LedTape/LedTape";
import HeatingPanel from "./pages/Articles/HeatingPanel/HeatingPanel";

function App() {
  return (
    <div className="App">
      <PageHeader></PageHeader>
      <PageContent>
        <Routes>
          <Route path="/intro" element={ <Intro/> }></Route>
          <Route path="/contact" element={ <Contact/> }></Route>
          <Route path="/getinquery" element={ <GetInquery/> }></Route>
          <Route path="/providers" element={ <Providers/> }></Route>
          <Route path="/gallery" element={ <Gallery/> }></Route>
          <Route path="/downloads" element={ <Downloads/> }></Route>
          <Route path="/webshop" element={ <Webshop/> }></Route>
          <Route path="/datausage" element={ <Datausage/> }></Route>
          <Route path="/colleagues/:place" element={ <Colleagues/> }></Route>

          <Route path="/ledszalagok" element={ <LedTape/> }></Route>
          <Route path="/otthonfelujitas" element={ <HomeRenovation/> }></Route>
          <Route path="/futopanel" element={ <HeatingPanel/> }></Route>
          <Route path="/" exact element={ <Home/> }></Route>
          <Route path="/" element={ <PageNotFound/> }></Route>
        </Routes>
      </PageContent>
      <PageFooter />
      <ScrollToTop />
    </div>
  );
}

export default App;
