import React, { Component } from "react";

import classes from "./PageHeader.module.css";
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import ContactMenu from "../ContactMenu/ContactMenu";
import ToggleMenu from "../ToggleMenu/ToggleMenu";
import MobileMenu from "../MobileMenu/MobileMenu";
import HomeButton from "../HomeButton/HomeButton";

class PageHeader extends Component {
  state = {
    showMobileMenu: false,
  };

  toggleMenuHandler = () => {
    this.setState((prevState) => {
      return { showMobileMenu: !prevState.showMobileMenu };
    });
  };

  render() {
    return (
      <header className={classes.PageHeader}>
        <div className={classes.Container}>
          <div className={classes.Logo}>
            <Logo />
          </div>
          <div className={classes.SideMenu}>
            <ContactMenu displayMode="inline"></ContactMenu>
            <nav className={classes.DesktopOnly}>
              <Menu />
            </nav>
            <ToggleMenu toggleMenu={this.toggleMenuHandler}></ToggleMenu>
            <HomeButton />
          </div>
        </div>
        <MobileMenu
          show={this.state.showMobileMenu}
          toggleMenu={this.toggleMenuHandler}
        ></MobileMenu>
      </header>
    );
  }
}

export default PageHeader;
