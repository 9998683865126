import shop1 from '../assets/images/shops/centrum.jpg';
import shop2 from '../assets/images/shops/village.jpg';

const offices = [
    {
        id: 'downtown',
        title: 'Villamossági Szaküzlet',
        building: shop1,
        alt: 'Üzlet a centrumban',
        maps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2306.9964834282814!2d20.666155027555593!3d46.55764348512461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47443ef399a6c94b%3A0x53bccbb883783ed7!2sAmper%20Villamoss%C3%A1gi%20Szak%C3%BCzlet!5e0!3m2!1shu!2shu!4v1611784702665!5m2!1shu!2shu',
        phone: '+36 68 412-202',
        mail: 'info@elektroamper.hu',
        address: '5900 Orosháza, Széchenyi tér 1.',
        monday: '08:00 - 17:00',
        saturday: '08:00 - 12:00',
        sunday: 'Zárva' 
    },
    {
        id: 'uptown',
        title: 'Nagykereskedés és Lámpa üzlet',
        building: shop2,
        alt: 'Üzlet a város szélén',
        maps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10973.965796898197!2d20.6331855078125!3d46.557746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47443ef30c51da7b%3A0xf69dad576e67d44c!2sAmper%20Villamoss%C3%A1gi%20Keresked%C3%A9s!5e0!3m2!1shu!2shu!4v1678206519031!5m2!1shu!2shu',
        phone: '+36 30 138-7699',
        mail: 'info@elektroamper.hu',
        address: '5900 Orosháza, Szentesi út 31.',
        monday: '07:30 - 16:30',
        saturday: '08:00 - 12:00',
        sunday: 'Zárva' }
];

export default offices;