import React from "react";
import classes from "./ContactMenu.module.css";

import { FaRegEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";

import ContactMenuItem from "./ContantMenuItem/ContactMenuItem";

const contactMenu = (props) => (
  <div
    className={
      props.displayMode === "column"
        ? classes.ContactMenuColumn
        : classes.ContactMenu
    }
  ><p>Üzlet elérhetőségei</p>
    <ContactMenuItem>
      <FaRegEnvelope />
      info@elektroamper.hu
    </ContactMenuItem>
    <ContactMenuItem>
      <FaMapMarkerAlt />
      5900 Orosháza, Széchenyi tér 1.
    </ContactMenuItem>
    <ContactMenuItem>
      <FaMapMarkerAlt />
      5900 Orosháza, Szentesi út 31.
    </ContactMenuItem>
  </div>
);

export default contactMenu;
