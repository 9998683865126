import React from "react";
import { FaFacebook, FaEnvelope } from "react-icons/fa";
import classes from "./SideBar.module.css";

const sideBar = () => (
  <div className={classes.SideBar}>
    <a href="https://www.facebook.com/ampervillamossagi" className={classes.Fb}>
      <FaFacebook />
    </a>
    <a href="mailto:info@elektroamper.hu" className={classes.Envelope}>
      <FaEnvelope></FaEnvelope>
    </a>
  </div>
);

export default sideBar;
