import React from "react";
import PageContentHeader from "../../../components/PageContentHeader/PageContentHeader";
import classes from "../Articles.module.css";

import bedroom from '../../../assets/images/news/bedroom.jpg';
import office from '../../../assets/images/news/office.jpg';
import ledtape from '../../../assets/images/news/ledtape2.jpg';
import luxuryled from '../../../assets/images/news/luxuryled.jpg';

const ledTape = () => (
  <div>
    <PageContentHeader>Amit a LED hangulatvilágításról tudni érdemes</PageContentHeader>
    <div className={classes.Articles}>
        <p>
            Annak ellenére, hogy az otthonainkban egyre elterjedtebb a LED világítások különféle célú használata – hiszen fényei meghittséget, nyugalmat, letisztultságot sugároznak, de hangulatunkhoz könnyen igazíthatók és ezáltal több funciót is betölthetnek–, az emberekben még mindig sok kérdés merül fel az megfelelő fényforrás kiválasztását illetően. A mai modern LED hangulatvilágítási technikák legnépszerűbb és legkényelmesebb megoldásait kétség kívül a <strong>LED szalagok és a LED spotlámpák</strong> nyújtják. Az alábbiakban néhány hasznos információval segítjük Önöket az ideális LED világítás kiválasztásának érdekében. 
        </p>
        <div className={classes.Photos}>
            <img className={classes.Photo} src={ledtape} alt="ledtape"/>
        </div>
        <p>
            A <strong>LED szalag</strong> mindenki számára ismert találmány és mindamellett hogy hasznos, egyszerűen alkalmazható és multifunkcionális; felhelyezhető bármilyen helyiségben, legyen szó a konyháról, hálószobáról, fürdőszobáról, nappaliról, de egy gardróbnak is elegáns és elegendő megvilágítást teremt. Színeiben nincsen határ, a fehér, piros, zöld, kék, sárga alapszínek mellett bármi egyéb elképzelés megvalósítható általa, hiszen az egyes fajtákban megtalálható RGB LED chip a 3 alapszínből bármilyen színt képes „előállítani”. 
        </p>
        <div className={classes.Photos}>
            <img className={classes.Photo} src={luxuryled} alt="luxuryled"/>
        </div>  
        <p>
            Mivel általában ragasztószalaggal vannak ellátva, felhelyezésük rendkívül egyszerű és sokrétű, legtöbbjük vágható és hajlítható, mennyezeten, plafonon, szekrényekben, polcok alján, konyhapulton, lépcsőkön és folyosókon, tükrök mögött, ágy kerete mellett, láthatóan vagy rejtett fényként gyakorta előfordul, de a felhasználáshoz benne rejlő lehetőségek szinte végtelenek.
        </p>
        <div className={classes.Photos}>
            <img className={classes.Photo} src={office} alt="office"/>
        </div>        
        <p>
            Hasonlóan elterjedt és hasznos megoldást kínál a <strong>LED spotlámpa</strong>, ami irányított fényt bocsájt ki és praktikus, hiszen akár a polisztirol díszlécbe, vagy a gipszkarton mennyezetbe is el lehet rejteni, ráadásul változatos kereteinek köszönhetően minden környezetbe tökéletesen beleillik. Telepítésének sűrűségétől függően kevesebbel akár lágyabb, kellemesebb hatás is elérhető velük, de ha több lámpatestet alkalmazunk, ragyogó és erős fényt képes kibocsájtani, ezért a felhasználás céljától függően szintén alkalmazhatóak a legtöbb helyiségben.
        </p>
        <p>
            A LED spotlámpa előnyeihez sorolható, hogy kinézete sokrétű, minden dizájnhoz illeszkedő, kapható kör vagy négyzet alakban, különféle színhőmérsékleteken is. Felválthatja a hagyományos halogénes hangulatvilágítást, ennek köszönhetően pedig energiatakarékosabb és gazdaságosabb megoldást kínál.
        </p>
        <p>
            Felhasználásának - hasonlóan a LED szalagokhoz -, csak a képzelet szabhat határt, gyakorta alkalmazzák elrejtve a mennyezetben, fürdőszobában a tükör fölé, nappali megvilágításához, gardróbokban, díszszekrényekhez, konyhaszekrényhez, folyosó, lépcső megvilágításához.
        </p>
        <div className={classes.Photos}>
            <img className={classes.Photo} src={bedroom} alt="bedroom"/>
        </div>
        <p className={classes.Firstparagraph}>
            Az előbbiekben felsorolt tulajdonságaik alapján kijelenthető, hogy a LED fényforrások - akár a szalagok, akár a spotlámpák -, hosszú távú, praktikus, energiatakarékos és gazdaságos megoldást jelenthetnek mindazok számára, akik modern, de egyszerű és tartós megoldásokkal szeretnék fenntarthatóvá varázsolni otthonukat.
        </p>
    </div>
  </div>
);

export default ledTape;
