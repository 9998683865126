import React from "react";
import classes from "./MobileMenu.module.css";
import Menu from "../Menu/Menu";

const mobileMenu = (props) => {
  let styles = classes.MobileMenu;

  if (props.show) {
    styles += " " + classes.Open;
  }

  return (
    <div className={styles} onClick={props.toggleMenu}>
      <Menu></Menu>
    </div>
  );
};

export default mobileMenu;
