import React from "react";
import classes from "./MenuItem.module.css";

import { NavLink } from "react-router-dom";

const menuItem = ({ href, link, exact, children }) => (
  <li className={classes.MenuItem}>
    {href ? (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    ) : (
      <NavLink to={link} className={({ isActive }) => (isActive ? classes.active : "")} end={exact}>
        {children}
      </NavLink>
    )}
  </li>
);

export default menuItem;
