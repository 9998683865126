import React from "react";
import AdvantageItem from "./AdvantageItem/AdvantageItem";

import classes from "./Advantages.module.css";

const adv = [
  {
    header: "Házhozszállítás",
    desc: "Orosházán belül ingyenes.",
    ico: "FaTruck",
  },
  {
    header: "Széles termék kínálat",
    desc: "Több, mint 20 000 termék raktáron.",
    ico: "MdShoppingCart",
  },
  {
    header: "Törzsvásárlói program",
    desc: "Törzsvásárlóinknak, szerződött partnereinknek akciókkal, kedvezményekkel háláljuk meg hűségét.",
    ico: "GiHumanPyramid",
  },
  {
    header: "Előrendelés",
    desc: "Kerüld el a sorbanállást, rendelj előre és mi elkészítjük a csomagod.",
    ico: "MdHeadsetMic",
  },
];

const advantages = (props) => (
  <div className={classes.Advantages}>
    {adv.map((item, key) => (
      <AdvantageItem
        header={item.header}
        desc={item.desc}
        ico={item.ico}
        key={key}
      />
    ))}
  </div>
);

export default advantages;
