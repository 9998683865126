import React from "react";

import classes from "./IntroCards.module.css";
import IntroCard from "./IntroCard/IntroCard";

import Amper1999 from '../../assets/images/intro/Introcards/Amper-1999.svg';
import Amper2017 from '../../assets/images/intro/Introcards/Amper-2017.svg';
import Amper2018 from '../../assets/images/intro/Introcards/Amper-2018.svg';
import Amper2022 from '../../assets/images/intro/Introcards/Amper-2022.svg';

const introCards = () => (
    <div className={classes.IntroCards}>
        <IntroCard icon={Amper1999} year="1999">Cégünk családi vállalkozásként alakult, bérelt üzlethelyiségben. Alapításkor 2 fős létszámmal, egyéni vállalkozásként működtünk.</IntroCard>
        <IntroCard icon={Amper2017} year="2017">Saját tulajdonú telephelyre költöztünk Orosházán a Széchenyi tér 1. alatt, ahol azóta is működünk. A cég létszáma is bővült.</IntroCard>
        <IntroCard icon={Amper2018} year="2018">Több éves kitartó munkánknak, és a folyamatos fejlődésünknek köszönhetően Kft-vé alakultunk át. Ekkortól Elektro-Amper Kft-ként működünk.</IntroCard>
        <IntroCard icon={Amper2022} year="2022">Új telephelyünk nyílt Orosházán a Szentesi út 31. szám alatt, raktáráruházból szolgáljuk ki a cégeket, kivitelezőket. Az Iparos Centrum épületében a lakosság számára nyitottunk széles lámpakínálattal egy új üzletet.</IntroCard>
    </div>
);

export default introCards;
