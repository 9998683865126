import React from "react";

import classes from "./NewsItem.module.css";
import { Link } from "react-router-dom";

const newsItem = (props) => (
  <Link className={classes.NewsItem} to={props.link}>
    <img src={props.src} alt={props.alt} />
    <div className={classes.NewsItemHeader}>{props.header}</div>
    <div className={classes.NewsItemDesc}>{props.desc}</div>
  </Link>
);

export default newsItem;
