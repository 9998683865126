import React from "react";
import classes from "./ContactShop.module.scss";

import { FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { GrFormClock } from "react-icons/gr";

import ContactMenuItem from "../ContactMenu/ContantMenuItem/ContactMenuItem";
import { Link } from "react-router-dom";

const contactShop = ({shop}) => (
  <div className={classes.ContactShop}>
    <div className={classes.ContactShoptitle}>{shop.title}</div>
    <img alt={shop.alt} width={'100%'} height={'350px'} src={shop.building}></img>
    
    <div className={classes.ContactShopDetails}>
      <div className={classes.ContactMenuColumn}>
        <p>Üzlet elérhetőségei</p>
        <ContactMenuItem>
          <FaPhoneAlt />
          {shop.phone}
        </ContactMenuItem>          
        <ContactMenuItem>
          <FaMapMarkerAlt />
          {shop.address}
        </ContactMenuItem>
      </div>
      <div className={classes.OpenText}>
        <p>
          <GrFormClock /> Nyitvatartás
        </p>
        <table>
          <tbody>
            <tr>
              <td>Hétfő-Péntek:</td>
              <td>{shop.monday}</td>
            </tr>
            <tr>
              <td>Szombat:</td>
              <td>{shop.saturday}</td>
            </tr>
            <tr>
              <td>Vasárnap:</td>
              <td>{shop.sunday}</td>
            </tr>
          </tbody>
        </table>
        </div>
    </div>
    <div className={classes.ContactsBtn}><Link  to={'/colleagues/' + shop.id}>Munkatársak</Link></div>

    <iframe
        src={shop.maps}
        width="100%"
        height="550"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
        title="map"
      ></iframe>
  </div>
);

export default contactShop;
