import React from "react";

import classes from "./ToggleMenu.module.css";

const toggleMenu = (props) => (
  <div onClick={props.toggleMenu} className={classes.ToggleMenu}>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default toggleMenu;
