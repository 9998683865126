import React from "react";

import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";

const downloads = () => (
  <div>
    <PageContentHeader>Letöltések</PageContentHeader>

    <iframe
      src="https://drive.google.com/embeddedfolderview?id=1kEk5aTVgDOdXd72Z6ASQlHoX1ZaOw2lk#list"
      title="Downloads"
      style={{ width: "100%", minHeight: "310px", border: "none" }}
    ></iframe>
  </div>
);

export default downloads;
