import React from "react";
import PageContentHeader from "../../../components/PageContentHeader/PageContentHeader";
import classes from "../Articles.module.css";

const homerenovation = () => (
  <div>
    <PageContentHeader>Minden, ami a villamos hálózat felújításához kell</PageContentHeader>
    <div className={classes.Articles}>
        <p className={classes.Firstparagraph}>
            Az Amper Villamossági Szaküzletben minden megtalálható, amire az otthonfelújítási programban
            résztvevő családoknak szüksége lehet a villamos hálózat felújítása során. Az alapszereléshez
            szükséges dobozok, védőcsövek, kábelcsatornák, kábelek, vezetékek azonnal elérhető nagy
            választékától egészen a kapcsolók, dugaljak, világítótestek széles kínálatáig.
        </p>
        <p>
            – Üzletünk 1999 óta van a tulajdonunkban, de az Amper Villamossági Szaküzlet története még az 1980-as
            években kezdődött. Kezdetben a lakásvillamosság terén nyújtottunk segítséget a vásárlóinknak, majd
            fokozatosan bővült a tevékenységi kör. 2017-ben költöztünk a Széchenyi tér 1. alá, ez pedig profilváltást
            is eredményezett.
        </p>
        <p>
            Ekkor még új profilnak számított részükről a lámpák vagy a korábban Orosházán hiányosabb cikknek
            számító csillárok eladása. Belvárosi üzlet révén az egyszerűbb dolgok közül is rengeteg elemet, valamint
            fényforrást értékesítenek. Nagy sikere volt a tavalyi év végén széles körű ünnepi termékpalettájuknak, a
            klasszikus fehér izzósorok mellett a színes fényeket kedvelőkre is gondoltak, de az égősorok mellett is
            nagy választék várta az ügyfeleket.
        </p>
        <div className={classes.Photos}>
            <img className={classes.Photo} src="https://oroscafe.hu/wp-content/uploads/2021/02/14-1024x484.jpg" alt="ledlight"/>
            <img className={classes.Photo} src="https://oroscafe.hu/wp-content/uploads/2021/02/12-1024x484.jpg" alt="ledlight"/>
            <img className={classes.Photo} src="https://oroscafe.hu/wp-content/uploads/2021/02/30-2-1024x910.jpg" alt="ledlight"/>
            <img className={classes.Photo} src="https://oroscafe.hu/wp-content/uploads/2021/02/210203_Amper-1024x768.jpg" alt="ledlight"/> 
        </div>        
        <p>
            A szerelvényeket tekintve a legnagyobb gyártók körülbelül 15 szerelvénycsaládjának több, mint 250
            színvariációja érhető el, közülük a gyakoriak készletről is. Lámpákat, világítótesteket, csillárokat, LED
            paneleket, LED szalagokat több, mint 300 gyártó kínálatából tudnak elérni a vásárlók. A készleten közel
            500 darab világítótest található, de az extra igényeket vagy épp a nem raktári termékeket megrendelésre a
            lehető legrövidebb időn belül teljesítik.
        </p>
        <p>
            A nem hagyományos megoldásokat kereső vásárlóink akár előre, egyedileg gyártott LED csillagos égbolt
            mennyezetet is kérhetnek, melyet a gyártó az egyedi igényeknek megfelelően előre legyárt, így a
            helyszínen akár 1-2 óra alatt felszerelik a gyártó szakember csapata által. Napelemek forgalmazásával is
            foglalkozik a cég, ennek kivitelezése egy partner vállalkozón keresztül valósítható meg.
        </p>
            <div className={classes.Photos}>
                <img className={classes.Photo} src="https://oroscafe.hu/wp-content/uploads/2021/02/210203_Amper_kisokos_-1024x967.jpg" alt="ledlight"/>
                <img className={classes.Photo} src="https://oroscafe.hu/wp-content/uploads/2021/02/image_67190017-2-1024x890.jpg" alt="ledlight"/>
                <img className={classes.Photo} src="https://oroscafe.hu/wp-content/uploads/2021/02/image_6487327_mod-2-1024x729.jpg" alt="ledlight"/>
            </div>  
        <p>
            A széles partnerkínálatunknak köszönhetően, akár csak egy villanyszerelőre van szüksége, akár
            generálkivitelezésre, mindenben tudunk segítséget nyújtani és szakembert ajánlani. A megvásárolt
            termékeket, igény esetén a felújítás helyszínére ingyen kiszállítjuk. Összefoglalva a tervezéstől a
            kivitelezésig mindenre van megoldásunk.
        </p>
        <p>
            Folyamatos céljuk továbbra is az, hogy a vásárlóknak kedves kiszolgálásban legyen része, közvetlenül és
            sokat beszélgetve az eladókkal a szakmai képzettség és a részletes tájékoztatás mellett.
        </p>
        <div>
            <p>Médiapartner: </p>
            <a href="https://oroscafe.hu" target="_blank" rel="noreferrer">
                <img className={classes.Logo} src="https://oroscafe.hu/wp-content/uploads/2020/08/oroscafe.png" alt="oroscafe"/>
            </a>
        </div>
    </div>
  </div>
);

export default homerenovation;
