import React from "react";
import { FaRegEnvelope, FaPhoneAlt } from "react-icons/fa";

import classes from "./Person.module.css";

const person = (props) => (
  <div className={classes.Person}>
    <div className={classes.Person_img}>
      <img width="300px" height="400px" src={props.person.img} alt={props.person.name}></img>
    </div>
    <div className={classes.Person_details}>
      { props.person.phone &&
        <span><FaPhoneAlt />{props.person.phone}</span>
      }
      <span><FaRegEnvelope />{props.person.email}</span>
    </div>
    <div className={classes.Person_name}>
    {props.person.name} <span className={classes.Person_role}>{props.person.role}</span>    
    </div>
  </div>
);

export default person;
