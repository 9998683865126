import React from "react";

import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";
import ProvidersItems from "../../components/ProdiversItems/ProvidersItems";

const providers = () => (
  <div>
    <PageContentHeader>Beszállítóink</PageContentHeader>
    <ProvidersItems></ProvidersItems>
  </div>
);

export default providers;
