import React from "react";
import shadow from "../../../assets/images/intro/single-shadow.svg";
import shadowMobil from "../../../assets/images/intro/single-shadow-horizontal.svg";
import classes from "./AimCard.module.css"

const aimCard = (props) => (
  <div className={classes.AimCard}>
    <img className={classes.Shadow} src={shadow} alt="shadow"/>
    <div className={classes.Cardcontainer}>
      <img className={classes.ShadowMobile} src={shadowMobil} alt="shadow"/>
      <div className={classes.CardHeaderContainer}>
          <img className={classes.Icon} src={props.icon} alt="aim-icon"/>
          <div className={classes.CardHeader}>{props.year}</div>
      </div>
      <div className={classes.Text}>{props.children}</div>
    </div>
  </div>
);

export default aimCard;
