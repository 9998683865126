import React from "react";
import classes from "./ProvidersItems.module.css";
import ProvidersItem from "../ProdiversItems/ProvidersItem/ProviderItem";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
  return images;
}

const imagess = importAll(require.context('../../assets/images/providers', false, /\.(png|jpe?g|svg|gif)$/));

const brands = [
  { name: "Abb", src: "https://new.abb.com/cdn/img/abb-logo-33px.svg?1513348063" },
  { name: "Omron", src: "https://omron.hu/images/omron-logo.svg" },
  { name: "Legrand", src: "https://legrand.hu/images/logo.png" },
  { name: "Eti", src: "https://www.eti-hu.hu/images_sys/en-GB/eti-logo-70.png"},
  { name: "Kontaset", src: "https://kontaset.cdn.shoprenter.hu/custom/kontaset/image/cache/w223h49m00/Logo/KONTASET%20LOGO_1000%C3%97_H%C3%81TT%C3%89R%20N%C3%89LK%C3%9CL.png?v=1550483219", url: "https://www.kontaset.hu/"},
  { name: "Somogyi", src: "https://www.somogyi.hu/img/logo.png", url: "https://www.somogyi.hu/"},
  { name: "Tracon", src: "https://shop.traconelectric.com/tracon-liferay-theme/images/logo.svg", url: "http://www.ganzkk.hu/", color: "#232323"},
  { name: "brennenstuhl", src: "https://www.brennenstuhl.com/skins/website/en/images/logo_bren_nav.svg", url: "https://www.brennenstuhl.com/"},
  { name: "Schrack", src: "https://www.schrack.hu/typo3conf/ext/p2n_schrack_template/Resources/Public/Images/rwd/schrack-logo.png", url: "https://www.schrack.hu/"},
/*   {
    name: "Philips",
    src:
      "https://www.assets.lighting.philips.com/is/image/PhilipsLighting/Philips_brand_logo_all_BR?$pnglarge$",
  }, */
];

for (const property in imagess) {
  brands.push({src: imagess[property].default})
}

const providersItems = () => (
  <div className={classes.ProvidersItems}>
    {brands.map((brand, index) => (
      <ProvidersItem src={brand.src} key={index} color={brand.color}></ProvidersItem>
    ))}
  </div>
);

export default providersItems;
