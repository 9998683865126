import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import axios from '../../services/EmailAxios';

import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";
import Dialog from "../../components/Dialog/Dialog";
import classes from "./Getinquery.module.css";

import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";

import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

//import EmailSender from "../../services/EmailSender";
//import emailjs from "emailjs-com";

const fields = [
  { label: "Név", name: "name", required: true },
  { label: "Cégnév", name: "companyname", required: false },
  {
    label: "E-mail cím",
    type: "email",
    name: "user_email",
    required: true,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Nem helyes e-mail cím, kérem ellenőrizze!",
    },
  },
  {
    label: "Telefonszám",
    name: "phone",
    required: true,
    pattern: {
      value: /^[0-9]*$/i,
      message: "Kérem csak számokat írjon!",
    },
  },
  { label: "Üzenet tárgya", name: "topic", required: true },
  {
    label: "Üzenet szövege:",
    name: "message",
    id: "outlined-multiline-static",
    multiline: true,
    rows: 8,
    required: true,
    variant: "outlined",
  },
];

const useStyles = makeStyles({
  root: {
    margin: "10px",
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#f44336",
    },
    "& .MuiOutlinedInput-root": {
      margin: "20px",
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
    "& .MuiInputLabel-outlined": {
      margin: "20px",
    },
  },
});

const Getinquery = () => {
  const defaultFormValues = {
    defaultValues: {
      name: "",
      companyname: "",
      user_email: "",
      phone: "",
      topic: "",
      message: "",
      gdpr: false,
    },
  };
  const [open, setOpen] = React.useState(false);
  const [isHuman, setIsHuman] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    reset();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { control, handleSubmit, errors, reset } = useForm(defaultFormValues);
  const classok = useStyles();

  const GreenCheckbox = withStyles({
    root: {
      "&$checked": {
        color: "var(--yellow)",
      },
    },
    checked: {},
  })((props) => <Checkbox {...props} />);

/*   const sendEmail = () => {
    //e.preventDefault();
    emailjs
      .sendForm(
        "default_service",
        "template_gx472dj",
        "#inquery",
        "user_jSrt6rEAFr7D10UWOIXSM"
      )
      .then(
        (result) => {
          handleClickOpen();
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }; */

  useEffect(() => {
    const getToken = async () => {
      await recaptchaRef.current.execute();
    };

    getToken();
  }, []);

  const onChange = (value) => {
    setIsHuman(value);
  };

  const onSubmit = (data) => {
       //const recaptchaValue = recaptchaRef.current.getValue()
      
      data = Object.assign({isHuman}, data);  

      //sendEmail(data);
      if ( isHuman ) {
        /*     EmailSender.send({
              SecureToken : "97a0f375-b622-4058-b011-068b7cb5714d",
              To : 'villampervill@gmail.com',
              From : "Amper@ampervill.nhely.hu",
              Subject : "Üzenet valami fasztól",
              Body : "And this is the body fászom"
          }).then(
              message => console.log(message)
          );    */
        axios.post('sendMail', data)
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          });  
        console.log('elküldve')
        handleClickOpen();
      } else {
        console.log('nincs captca')
      }      
    //});
  };

  return (
    <div>
      <PageContentHeader>Kapcsolatfelvétel</PageContentHeader>
      <Dialog handleClose={handleClose} open={open} />
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        id="inquery"
        className={classes.GetinqueryForm}
      >
        {fields.map((field) => (
          <Controller
            key={field.name}
            as={
              <TextField
                {...field}
                classes={{ root: classok.root }}
                className={classes.GetinqueryFormField}
              />
            }
            name={field.name}
            control={control}
            rules={{
              required: {
                value: field.required,
                message: "Kérem töltse ki!"
              }, 
              pattern: field.pattern
            }}
            error={!!errors[field.name]}
            helperText={!!errors[field.name] ? errors[field.name].message : ""}
          />
        ))}
        Fájlokat kérem e-mailben csatoljon!
        <div className={classes.DataUsage}>
          <Controller
            control={control}
            name="gdpr"
            rules={{
              required: {
                value: true,
                message: "Adatkezelés elfogadása nélkül, nem küldhet üzenetet!"
              }
            }}
            render={({ onChange: onCheckChange, value, name, ref }) => (
              <GreenCheckbox
                name={name}
                onChange={(e) => onCheckChange(e.target.checked)}
                checked={value}
                inputRef={ref}
                error={errors.gdpr}
              />
            )}
          />
          <div>
            Az <Link to="/datausage">adatkezelési tájékoztatóban</Link>{" "}
            foglaltakat megértettem és elfogadom, ennek megfelelően jelen négyzet
            kitöltésével önkéntesen, minden külső befolyás nélkül kifejezetten
            hozzájárulok megadott személyes adataim kezeléséhez.          
          </div>
        </div>
          {errors.gdpr && (
              <p className={classes.ErrosMessage}>
                {errors.gdpr.message}
              </p>
            )}
        <button type="submit" className={classes.Button}>
          Küldés
        </button>

        <ReCAPTCHA
          size="invisible"
          sitekey="6Le9eW4aAAAAAOn3-B1bl1R_EJq8bqigxJPkw7Ji"
          /* sitekey="6LeTKlQaAAAAAAdITldv6m78OtGRtx4jT3kwrKYh" v2*/
          ref={recaptchaRef}
          onChange={onChange}
        />

      </form>
    </div>
  );
};

export default Getinquery;
