import React from "react";

import PageContentHeader from "../../components/PageContentHeader/PageContentHeader";
import IntroCards from "../../components/IntroCards/IntroCards";
import AimCards from "../../components/AimCards/AimCards";

import classes from "./Intro.module.css";

const intro = () => (
  <div className={classes.Intro}>
    <PageContentHeader>Bemutatkozás</PageContentHeader>
    
    <IntroCards/>
    <AimCards/>
    
    <div className={classes.Text}>
      <p>Térj be hozzánk, várunk szeretettel! </p>

      <p>
        <i>Elektro-Amper Kft - Villamossági Kereskedés csapata </i>
      </p>
    </div>
  </div>
);

export default intro;
