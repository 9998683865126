import React from "react";
import ImageGallery from "react-image-gallery";
import classes from "./ImgGallery.module.css";

const imgGallery = (props) => {
  let galleryComp = <ImageGallery items={props.images} />;

  if (props.mode === "slider") {
    galleryComp = (
      <ImageGallery
        items={props.images}
        showThumbnails={false}
        showFullscreenButton={false}
        showBullets
        showPlayButton={false}
        autoPlay
        slideDuration={800}
        showNav={false}
        slideInterval={5000}
      />
    );
    //galleryComp = null;
  }

  return <div className={classes.ImageGallery}>{galleryComp}</div>;
};

export default imgGallery;
