import React from "react";
import NewsItem from "./NewsItem/NewsItem";

import HomeRen from "../../assets/images/news/Okosotthon.jpg";
import LedTape from "../../assets/images/news/ledtape.jpg";
import Heating from "../../assets/images/news/heating.jpg";

import classes from "./News.module.css";

const articles = [
  {
    header: "Otthonfelújítási kisokos",
    desc: "Ami a villamos hálózat felújításához kell",
    src: HomeRen,
    alt: "Okosotthonkisokos",
    link: "/otthonfelujitas",
  },
  {
    header: "LED szalagok és spotlámpák",
    desc: "Amit a LED hangulatvilágításról tudni érdemes",
    src: LedTape,
    alt: "ledtape",
    link: "/ledszalagok",
  },
  {
    header: "Norvég fűtőpanelek",
    desc: "Miért különlegesek?",
    src: Heating,
    alt: "leany",
    link: "/futopanel",
  },
];

const news = (props) => (
  <div className={classes.News}>
    {articles.map((item, key) => (
      <NewsItem
        header={item.header}
        desc={item.desc}
        src={item.src}
        alt={item.alt}
        link={item.link}
        key={key}
      />
    ))}
  </div>
);

export default news;
