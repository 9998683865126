import React from "react";
import classes from "./HomeButton.module.css";
import { NavLink } from "react-router-dom";

import { FaHome } from "react-icons/fa";

const homeButton = () => (
  <NavLink to="/" className={({ isActive }) => (isActive ? classes.active + " " + classes.HomeButton : classes.HomeButton)} end>
    <FaHome />
  </NavLink>
);

export default homeButton;
